import React, { useState, useEffect, useRef } from 'react';
import { ref, push, onValue, off, serverTimestamp, set, remove, update } from 'firebase/database';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { database, storage } from '../lib/firebase';
import DOMPurify from 'dompurify';
import { useAuthStore } from '../store/auth';
import debounce from 'lodash/debounce';

interface Message {
  id: string;
  text: string;
  userId: string;
  userName: string;
  timestamp: number;
  attachmentUrl?: string;
  attachmentType?: 'image' | 'video' | 'link';
  status?: 'sent' | 'delivered';
}

interface GroupChatProps {
  groupId: string;
}

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes
const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png', 'image/gif', 'video/mp4'];

export const GroupChat: React.FC<GroupChatProps> = ({ groupId }) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [typingUsers, setTypingUsers] = useState<{[key: string]: boolean}>({});
  const [unreadCount, setUnreadCount] = useState(0);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const { user } = useAuthStore();
  const lastReadTimestampRef = useRef<number>(Date.now());

  useEffect(() => {
    if (!user?.id || !groupId) return;

    console.log('Setting up chat for user:', user.id, 'in group:', groupId);

    const setupChat = async () => {
      try {
        // Add user to group members in Realtime Database
        const groupMemberRef = ref(database, `groupMembers/${groupId}/${user.id}`);
        await set(groupMemberRef, {
          userId: user.id,
          userName: user.name || 'Anonymous',
          joinedAt: serverTimestamp(),
          lastSeen: serverTimestamp()
        });

        console.log('Successfully set group member');

        // Subscribe to messages
        const messagesRef = ref(database, `chats/${groupId}/messages`);
        onValue(messagesRef, (snapshot) => {
          const data = snapshot.val();
          if (data) {
            const messageList = Object.entries(data).map(([id, msg]: [string, any]) => ({
              id,
              ...msg,
            }));
            
            // Sort messages and update unread count
            const sortedMessages = messageList.sort((a, b) => {
              const timeA = a.timestamp || 0;
              const timeB = b.timestamp || 0;
              return timeA - timeB;
            });
            
            setMessages(sortedMessages);
            
            // Update unread count
            const newMessages = sortedMessages.filter(
              msg => msg.timestamp > lastReadTimestampRef.current && msg.userId !== user.id
            );
            setUnreadCount(newMessages.length);
            
            scrollToBottom();
          } else {
            setMessages([]);
          }
        });

        // Subscribe to typing indicators
        const typingRef = ref(database, `typing/${groupId}`);
        onValue(typingRef, (snapshot) => {
          const data = snapshot.val() || {};
          setTypingUsers(data);
        });

      } catch (error) {
        console.error('Error setting up chat:', error);
      }
    };

    setupChat();

    // Update last seen timestamp periodically
    const updateLastSeen = () => {
      const groupMemberRef = ref(database, `groupMembers/${groupId}/${user.id}`);
      update(groupMemberRef, {
        lastSeen: serverTimestamp()
      });
    };
    
    const lastSeenInterval = setInterval(updateLastSeen, 60000); // Update every minute

    return () => {
      console.log('Cleaning up chat subscriptions');
      clearInterval(lastSeenInterval);
      off(ref(database, `chats/${groupId}/messages`));
      off(ref(database, `typing/${groupId}`));
      const groupMemberRef = ref(database, `groupMembers/${groupId}/${user.id}`);
      remove(groupMemberRef).catch(error => {
        console.error('Error removing group member:', error);
      });
    };
  }, [groupId, user]);

  // Update typing status
  const updateTypingStatus = debounce((isTyping: boolean) => {
    if (!user?.id || !groupId) return;
    const typingRef = ref(database, `typing/${groupId}/${user.id}`);
    if (isTyping) {
      set(typingRef, {
        userName: user.name || 'Anonymous',
        timestamp: serverTimestamp()
      });
    } else {
      remove(typingRef);
    }
  }, 500);

  const handleMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewMessage(e.target.value);
    updateTypingStatus(e.target.value.length > 0);
  };

  const deleteMessage = async (messageId: string) => {
    if (!user?.id || !groupId) return;
    try {
      const messageRef = ref(database, `chats/${groupId}/messages/${messageId}`);
      await remove(messageRef);
    } catch (error) {
      console.error('Error deleting message:', error);
      alert('Failed to delete message. Please try again.');
    }
  };

  // Mark messages as read when user views them
  const markMessagesAsRead = () => {
    lastReadTimestampRef.current = Date.now();
    setUnreadCount(0);
  };

  useEffect(() => {
    if (document.visibilityState === 'visible') {
      markMessagesAsRead();
    }
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (!selectedFile) return;

    if (selectedFile.size > MAX_FILE_SIZE) {
      alert('File size must be less than 10MB');
      return;
    }

    if (!ALLOWED_FILE_TYPES.includes(selectedFile.type)) {
      alert('Only images (JPEG, PNG, GIF) and MP4 videos are allowed');
      return;
    }

    setFile(selectedFile);
  };

  const uploadFile = async (file: File): Promise<string> => {
    const fileRef = storageRef(storage, `chat-attachments/${groupId}/${Date.now()}-${file.name}`);
    await uploadBytes(fileRef, file);
    return getDownloadURL(fileRef);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user?.id || (!newMessage.trim() && !file)) return;

    try {
      setIsUploading(true);
      let attachmentUrl = '';
      let attachmentType: 'image' | 'video' | 'link' | undefined;

      if (file) {
        attachmentUrl = await uploadFile(file);
        attachmentType = file.type.startsWith('image/') ? 'image' : 'video';
      }

      // Sanitize the message text
      const sanitizedMessage = DOMPurify.sanitize(newMessage);

      // Create message object
      const messageData = {
        text: sanitizedMessage,
        userId: user.id,
        userName: user.name || 'Anonymous',
        timestamp: serverTimestamp(),
        ...(attachmentUrl && { attachmentUrl, attachmentType }),
      };

      console.log('Sending message:', messageData);

      // Push to Firebase
      const chatRef = ref(database, `chats/${groupId}/messages`);
      await push(chatRef, messageData);

      // Clear form
      setNewMessage('');
      setFile(null);
      if (e.target instanceof HTMLFormElement) {
        e.target.reset();
      }
    } catch (error) {
      console.error('Error sending message:', error);
      alert('Failed to send message. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  const renderAttachment = (message: Message) => {
    if (!message.attachmentUrl) return null;

    switch (message.attachmentType) {
      case 'image':
        return (
          <img
            src={message.attachmentUrl}
            alt="Attachment"
            className="max-w-xs max-h-48 rounded-lg"
          />
        );
      case 'video':
        return (
          <video
            controls
            className="max-w-xs max-h-48 rounded-lg"
          >
            <source src={message.attachmentUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        );
      case 'link':
        return (
          <a
            href={message.attachmentUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:underline"
          >
            {message.attachmentUrl}
          </a>
        );
      default:
        return null;
    }
  };

  if (!user?.id) {
    return (
      <div className="flex items-center justify-center h-[500px] bg-white rounded-lg shadow-lg">
        <p className="text-gray-500">Please log in to participate in the chat.</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-[500px] bg-white rounded-lg shadow-lg">
      <div className="p-4 border-b flex justify-between items-center">
        <h2 className="text-lg font-semibold">Group Chat</h2>
        {unreadCount > 0 && (
          <span className="bg-blue-500 text-white px-2 py-1 rounded-full text-sm">
            {unreadCount} new
          </span>
        )}
      </div>

      <div className="flex-1 overflow-y-auto p-4 space-y-4">
        {messages.map((message) => (
          <div
            key={message.id}
            className={`flex flex-col ${
              message.userId === user?.id ? 'items-end' : 'items-start'
            }`}
          >
            <div
              className={`max-w-[70%] rounded-lg p-3 ${
                message.userId === user?.id
                  ? 'bg-blue-500 text-white'
                  : 'bg-gray-100 text-gray-900'
              }`}
            >
              <div className="flex justify-between items-center mb-1">
                <span className="text-sm font-semibold">{message.userName}</span>
                {message.userId === user?.id && (
                  <button
                    onClick={() => deleteMessage(message.id)}
                    className="ml-2 text-xs opacity-60 hover:opacity-100"
                  >
                    Delete
                  </button>
                )}
              </div>
              {message.text && (
                <div className="break-words">{message.text}</div>
              )}
              {renderAttachment(message)}
              <div className="text-xs mt-1 opacity-75 flex items-center justify-between">
                <span>{new Date(message.timestamp).toLocaleTimeString()}</span>
                {message.userId === user?.id && (
                  <span className="ml-2">
                    {message.status === 'delivered' ? '✓✓' : '✓'}
                  </span>
                )}
              </div>
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      {Object.keys(typingUsers).length > 0 && (
        <div className="px-4 py-2 text-sm text-gray-500 italic">
          {Object.values(typingUsers)
            .map((user: any) => user.userName)
            .join(', ')}{' '}
          {Object.keys(typingUsers).length === 1 ? 'is' : 'are'} typing...
        </div>
      )}

      <form onSubmit={handleSubmit} className="p-4 border-t">
        <div className="flex items-center space-x-2">
          <input
            type="text"
            value={newMessage}
            onChange={handleMessageChange}
            placeholder="Type a message..."
            className="flex-1 p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <label className="cursor-pointer">
            <input
              type="file"
              onChange={handleFileChange}
              accept={ALLOWED_FILE_TYPES.join(',')}
              className="hidden"
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-gray-500 hover:text-blue-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
              />
            </svg>
          </label>
          <button
            type="submit"
            disabled={isUploading || (!newMessage.trim() && !file)}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 disabled:opacity-50"
          >
            {isUploading ? 'Sending...' : 'Send'}
          </button>
        </div>
        {file && (
          <div className="mt-2 text-sm text-gray-600">
            Selected file: {file.name}
            <button
              type="button"
              onClick={() => setFile(null)}
              className="ml-2 text-red-500 hover:text-red-600"
            >
              Remove
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default GroupChat;
