import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, browserLocalPersistence, setPersistence } from 'firebase/auth';
import { initializeFirestore, persistentLocalCache, persistentMultipleTabManager } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getDatabase } from 'firebase/database';
import { useAuthStore } from '../store/auth';
import { getCurrentUser } from '../services/auth';

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Auth with persistence
const auth = getAuth(app);
setPersistence(auth, browserLocalPersistence);

// Initialize Firestore with persistence
const db = initializeFirestore(app, {
  localCache: persistentLocalCache({
    tabManager: persistentMultipleTabManager()
  })
});

// Get Storage instance
const storage = getStorage(app);

// Get Realtime Database instance
const rtdb = getDatabase(app);
const database = rtdb; // For backward compatibility

// Track auth initialization
let authInitialized = false;
let authInitializePromise: Promise<void> | null = null;

// Initialize auth state listener
onAuthStateChanged(auth, async (firebaseUser) => {
  // Set initial state
  const store = useAuthStore.getState();
  store.setIsAuthenticated(false); // Reset auth state while loading

  try {
    if (!firebaseUser) {
      store.setUser(null);
      store.setIsAuthenticated(false);
      store.setIsEmailVerified(false);
      return;
    }

    // Force refresh to get latest email verification status
    await firebaseUser.reload();
    await firebaseUser.getIdToken(true);
    
    const user = await getCurrentUser(firebaseUser);
    if (!user) {
      store.setUser(null);
      store.setIsAuthenticated(false);
      store.setIsEmailVerified(false);
      return;
    }

    store.setUser(user);
    store.setIsAuthenticated(true);
    store.setIsEmailVerified(firebaseUser.emailVerified);
  } catch (error) {
    console.error('Error updating auth state:', error);
    store.setUser(null);
    store.setIsAuthenticated(false);
    store.setIsEmailVerified(false);
  } finally {
    if (!authInitialized) {
      authInitialized = true;
    }
  }
});

// Helper function to wait for auth initialization and get current user
export const waitForAuth = async () => {
  if (!authInitializePromise) {
    authInitializePromise = new Promise<void>((resolve) => {
      if (authInitialized) {
        resolve();
        return;
      }

      const unsubscribe = onAuthStateChanged(auth, () => {
        unsubscribe();
        authInitialized = true;
        resolve();
      });
    });
  }

  await authInitializePromise;

  // After auth is initialized, force token refresh
  const currentUser = auth.currentUser;
  if (currentUser) {
    await currentUser.getIdToken(true);
  }

  return currentUser;
};

export { app, auth, db, storage, rtdb, database };