import { Github, Twitter } from 'lucide-react';

export function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="w-full bg-background-secondary/50 backdrop-blur-sm border-t border-border">
      <div className="flex flex-col md:flex-row justify-between items-center px-4 py-3 text-sm text-text-secondary">
        <div className="flex items-center space-x-2">
          <img src="/logo.svg" alt="SVIZ.live" className="h-6" />
          <span className="hidden md:inline">•</span>
          <span className="hidden md:inline">Medical Education Platform</span>
        </div>
        
        <div className="flex items-center mt-2 md:mt-0 space-x-4">
          <a
            href="#"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-text transition-colors"
          >
            <Github className="h-4 w-4" />
          </a>
          <a
            href="#"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-text transition-colors"
          >
            <Twitter className="h-4 w-4" />
          </a>
        </div>
      </div>
    </footer>
  );
}
