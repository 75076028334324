import React, { useEffect } from 'react';
import { Users, Play, TrendingUp, UserCircle, Globe, Eye, Clock, Trophy, BarChart2 } from 'lucide-react';
import { useAuthStore } from '../../store/auth';
import { useGroupStore } from '../../store/group';
import { useStreamStore } from '../../store/stream';
import { useNotifications } from '../../hooks/useNotifications';
import { NotificationsPanel } from '../notifications/NotificationsPanel';
import { Card } from '../ui/Card';
import { Link } from 'react-router-dom';
import styles from './Dashboard.module.css';

interface KanbanCardProps {
  title: string;
  children: React.ReactNode;
  icon: React.ElementType;
  loading?: boolean;
  loadingDelay?: number;
}

function KanbanCard({ title, children, icon: Icon, loading = false, loadingDelay = 0 }: KanbanCardProps) {
  return (
    <div 
      className={`transform transition-all duration-500 ease-out ${
        loading ? 'opacity-0 translate-y-4' : 'opacity-100 translate-y-0'
      }`}
      style={{ transitionDelay: `${loadingDelay}ms` }}
    >
      <div className="h-full rounded-xl bg-gradient-to-br from-gray-900 to-gray-800 p-[1px] backdrop-blur-xl">
        <div className="h-full rounded-xl bg-gradient-to-br from-blue-600/20 via-purple-600/20 to-transparent backdrop-blur-xl
          shadow-[inset_0_1px_1px_rgba(255,255,255,0.1)] hover:shadow-[inset_0_1px_1px_rgba(255,255,255,0.2)]
          transition-all duration-300">
          <div className="relative p-6 space-y-4 overflow-hidden">
            {/* Gradient orbs */}
            <div className="absolute top-0 right-0 w-48 h-48 bg-blue-500/10 rounded-full blur-3xl -mr-24 -mt-24" />
            <div className="absolute bottom-0 left-0 w-48 h-48 bg-purple-500/10 rounded-full blur-3xl -ml-24 -mb-24" />
            
            <div className="relative">
              <div className="flex items-center space-x-3 mb-4">
                <div className="p-2 rounded-lg bg-gradient-to-br from-blue-500/20 to-purple-500/20
                  shadow-[inset_0_1px_1px_rgba(255,255,255,0.1)]">
                  <Icon className="h-5 w-5 text-blue-400" />
                </div>
                <h3 className="text-lg font-medium tracking-tight text-white/90">{title}</h3>
              </div>
              <div className="space-y-3">
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

interface ItemProps {
  title: string;
  subtitle?: string;
  href?: string;
  metadata?: {
    label: string;
    value: string | number;
  }[];
}

function Item({ title, subtitle, href, metadata = [] }: ItemProps) {
  return (
    <Link
      to={href || '#'}
      className="block p-4 rounded-lg bg-gradient-to-br from-gray-800/50 to-gray-900/50 border border-gray-700/50
        hover:bg-gradient-to-br hover:from-gray-700/50 hover:to-gray-800/50
        transition-all duration-300 backdrop-blur-lg
        shadow-[inset_0_1px_1px_rgba(255,255,255,0.05)]
        hover:shadow-[inset_0_1px_1px_rgba(255,255,255,0.1)]"
    >
      <h3 className="font-medium text-white/90">{title}</h3>
      {subtitle && <p className="text-sm text-gray-400 mt-1">{subtitle}</p>}
      {metadata.length > 0 && (
        <div className="flex items-center space-x-4 mt-2">
          {metadata.map((item, index) => (
            <div key={index} className="flex items-center space-x-1">
              <span className="text-xs text-gray-400">{item.label}:</span>
              <span className="text-xs font-medium text-white/80">{item.value}</span>
            </div>
          ))}
        </div>
      )}
    </Link>
  );
}

export function Dashboard() {
  const { user } = useAuthStore();
  const { groups, fetchGroups } = useGroupStore();
  const { streams, fetchStreams } = useStreamStore();
  useNotifications(); // Initialize notifications hook

  useEffect(() => {
    fetchStreams();
    fetchGroups();
  }, [fetchStreams, fetchGroups]);

  return (
    <div className={`p-6 space-y-8 ${styles.gradientBackground}`}>
      {/* Welcome Section */}
      <div className="bg-gradient-to-r from-blue-500/10 to-blue-900/10 backdrop-blur-2xl border border-white/10 rounded-2xl p-8
        shadow-[0_0_20px_rgba(0,0,0,0.25)] hover:shadow-[0_0_30px_rgba(0,0,0,0.35)] transition-all duration-300
        before:absolute before:inset-0 before:bg-gradient-to-br before:from-blue-500/5 before:to-transparent before:rounded-2xl before:-z-10
        after:absolute after:inset-0 after:bg-blue-900/5 after:backdrop-blur-xl after:rounded-2xl after:-z-20">
        <h2 className="text-2xl font-semibold text-white mb-2">
          Welcome back, {user?.name}
        </h2>
        <p className="text-blue-200">
          Here's what's happening in your medical community
        </p>
      </div>

      {/* Stats Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <Link to="/groups" className="group">
          <div className="bg-gray-800/30 rounded-xl p-6 border border-white/10 backdrop-blur-2xl
            shadow-[0_0_15px_rgba(0,0,0,0.2)] hover:shadow-[0_0_25px_rgba(0,0,0,0.3)] transition-all duration-300
            before:absolute before:inset-0 before:bg-gradient-to-br before:from-blue-500/10 before:to-transparent before:rounded-xl before:-z-10
            after:absolute after:inset-0 after:bg-blue-900/10 after:backdrop-blur-xl after:rounded-xl after:-z-20"
            style={{ 
              transitionDelay: `100ms`,
            }}>
            <div className="flex items-center space-x-4">
              <div className="p-3 rounded-lg bg-blue-500/10">
                <Users className="w-6 h-6 text-blue-400" />
              </div>
              <div>
                <h3 className="text-lg font-medium text-white">Groups</h3>
                <p className="text-sm text-blue-200">{groups.length} Active Groups</p>
              </div>
            </div>
          </div>
        </Link>

        <Link to="/streams" className="group">
          <div className="bg-gray-800/30 rounded-xl p-6 border border-white/10 backdrop-blur-2xl
            shadow-[0_0_15px_rgba(0,0,0,0.2)] hover:shadow-[0_0_25px_rgba(0,0,0,0.3)] transition-all duration-300
            before:absolute before:inset-0 before:bg-gradient-to-br before:from-purple-500/10 before:to-transparent before:rounded-xl before:-z-10
            after:absolute after:inset-0 after:bg-blue-900/10 after:backdrop-blur-xl after:rounded-xl after:-z-20"
            style={{ 
              transitionDelay: `200ms`,
            }}>
            <div className="flex items-center space-x-4">
              <div className="p-3 rounded-lg bg-purple-500/10">
                <Play className="w-6 h-6 text-purple-400" />
              </div>
              <div>
                <h3 className="text-lg font-medium text-white">Streams</h3>
                <p className="text-sm text-purple-200">{streams.length} Active Streams</p>
              </div>
            </div>
          </div>
        </Link>

        <Link to="/analytics" className="group">
          <div className="bg-gray-800/30 rounded-xl p-6 border border-white/10 backdrop-blur-2xl
            shadow-[0_0_15px_rgba(0,0,0,0.2)] hover:shadow-[0_0_25px_rgba(0,0,0,0.3)] transition-all duration-300
            before:absolute before:inset-0 before:bg-gradient-to-br before:from-indigo-500/10 before:to-transparent before:rounded-xl before:-z-10
            after:absolute after:inset-0 after:bg-blue-900/10 after:backdrop-blur-xl after:rounded-xl after:-z-20"
            style={{ 
              transitionDelay: `300ms`,
            }}>
            <div className="flex items-center space-x-4">
              <div className="p-3 rounded-lg bg-indigo-500/10">
                <BarChart2 className="w-6 h-6 text-indigo-400" />
              </div>
              <div>
                <h3 className="text-lg font-medium text-white">Analytics</h3>
                <p className="text-sm text-indigo-200">View Insights</p>
              </div>
            </div>
          </div>
        </Link>

        <Link to="/profile" className="group">
          <div className="bg-gray-800/30 rounded-xl p-6 border border-white/10 backdrop-blur-2xl
            shadow-[0_0_15px_rgba(0,0,0,0.2)] hover:shadow-[0_0_25px_rgba(0,0,0,0.3)] transition-all duration-300
            before:absolute before:inset-0 before:bg-gradient-to-br before:from-cyan-500/10 before:to-transparent before:rounded-xl before:-z-10
            after:absolute after:inset-0 after:bg-blue-900/10 after:backdrop-blur-xl after:rounded-xl after:-z-20"
            style={{ 
              transitionDelay: `400ms`,
            }}>
            <div className="flex items-center space-x-4">
              <div className="p-3 rounded-lg bg-cyan-500/10">
                <UserCircle className="w-6 h-6 text-cyan-400" />
              </div>
              <div>
                <h3 className="text-lg font-medium text-white">Profile</h3>
                <p className="text-sm text-cyan-200">View Profile</p>
              </div>
            </div>
          </div>
        </Link>
      </div>

      {/* Recent Activity */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="space-y-6">
          <KanbanCard
            title="Your Groups"
            icon={Users}
            loading={false}
          >
            {groups.slice(0, 3).map(group => (
              <Link key={group.id} to={`/groups/${group.id}`} className="block group">
                <div className="p-4 rounded-lg bg-white/5 backdrop-blur-md border border-white/5 
                  transition-all duration-300 hover:bg-white/10 hover:border-white/10">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-3">
                      <Users className="w-5 h-5 text-blue-400" />
                      <h4 className="font-medium text-white">{group.name}</h4>
                    </div>
                    <span className="text-sm text-blue-200">{group.members?.length || 0} members</span>
                  </div>
                </div>
              </Link>
            ))}
          </KanbanCard>
        </div>

        <div className="space-y-6">
          <KanbanCard
            title="Your Streams"
            icon={Play}
            loading={false}
            loadingDelay={100}
          >
            {streams.slice(0, 3).map(stream => (
              <Link key={stream.id} to={`/streams/${stream.id}`} className="block group">
                <div className="p-4 rounded-lg bg-white/5 backdrop-blur-md border border-white/5 
                  transition-all duration-300 hover:bg-white/10 hover:border-white/10">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-3">
                      <Play className="w-5 h-5 text-purple-400" />
                      <h4 className="font-medium text-white">{stream.title}</h4>
                    </div>
                    <span className="text-sm text-purple-200">{stream.viewers || 0} viewers</span>
                  </div>
                </div>
              </Link>
            ))}
          </KanbanCard>
        </div>

        <div className="space-y-6">
          <NotificationsPanel />
        </div>
      </div>
    </div>
  );
}