import { Users } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Card } from '../ui/Card';
import { Button } from '../ui/Button';
import { useGroupStore } from '../../store/group';
import { useEffect } from 'react';
import { useAuthStore } from '../../store/auth';

interface Group {
  id: string;
  name: string;
  description: string;
  isPrivate: boolean;
  members?: {
    id: string;
    name: string;
  }[];
}

interface GroupListProps {
  private?: boolean;
}

export function GroupList({ private: isPrivate }: GroupListProps) {
  const { groups, fetchGroups, isLoading, error } = useGroupStore();
  const { user } = useAuthStore();

  useEffect(() => {
    if (user) {
      fetchGroups();
    }
  }, [user, fetchGroups]);

  if (isLoading) {
    return <div className="text-gray-400">Loading groups...</div>;
  }

  if (error) {
    return (
      <div className="text-center py-12 bg-gray-800/50 rounded-xl border border-gray-700">
        <h3 className="text-xl font-light text-red-500 mb-2">Error Loading Groups</h3>
        <p className="text-gray-400 mb-4">{error}</p>
        <Button
          variant="outline"
          size="sm"
          onClick={() => fetchGroups()}
          className="text-gray-400 hover:text-white border-gray-700 hover:border-gray-600"
        >
          Retry
        </Button>
      </div>
    );
  }

  if (groups.length === 0) {
    return (
      <div className="text-center py-12 bg-gray-800/50 rounded-xl border border-gray-700">
        <h3 className="text-xl font-light text-white mb-2">No Groups Found</h3>
        <p className="text-gray-400">
          {isPrivate ? 'No private groups available' : 'Be the first to create a group!'}
        </p>
      </div>
    );
  }

  const filteredGroups = isPrivate
    ? groups.filter(group => group.isPrivate)
    : groups.filter(group => !group.isPrivate);

  if (filteredGroups.length === 0) {
    return (
      <div className="text-center py-12 bg-gray-800/50 rounded-xl border border-gray-700">
        <h3 className="text-xl font-light text-white mb-2">No {isPrivate ? 'Private' : 'Public'} Groups</h3>
        <p className="text-gray-400">
          {isPrivate ? 'No private groups available' : 'No public groups found'}
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {filteredGroups.map((group) => (
        <Link
          key={group.id}
          to={`/groups/${group.id}`}
          className="block group"
        >
          <Card className="h-full p-6 bg-gray-800/50 border border-gray-700 hover:border-gray-600 transition-colors">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-2">
                <Users className="w-5 h-5 text-gray-400" />
                <h3 className="text-white font-medium">{group.name}</h3>
              </div>
              <Button
                variant="outline"
                size="sm"
                className="text-gray-400 hover:text-white border-gray-700 hover:border-gray-600"
              >
                View Group
              </Button>
            </div>
            <p className="mt-2 text-sm text-gray-400">{group.description}</p>
            <div className="mt-4 flex items-center text-xs text-gray-400">
              <Users className="w-4 h-4 mr-1" />
              {group.members?.length || 0} member{(group.members?.length || 0) !== 1 ? 's' : ''}
            </div>
          </Card>
        </Link>
      ))}
    </div>
  );
}