import React from 'react';
import { usePermissions } from '../../hooks/usePermissions';
import type { Permission } from '../../types/roles';

interface RoleGuardProps {
  children: React.ReactNode;
  requiredPermission?: string;
  fallback?: React.ReactNode;
}

export function RoleGuard({ children, requiredPermission, fallback = null }: RoleGuardProps) {
  const { hasPermission } = usePermissions();

  if (requiredPermission && !hasPermission(requiredPermission)) {
    return <>{fallback}</>;
  }

  return <>{children}</>;
}