import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import DeviceStreamPlayer from '../components/VideoStream/DeviceStreamPlayer';
import { Share2, Users } from 'lucide-react';
import { useGroupStore } from '../store/group';
import { doc, getDoc, runTransaction } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuthStore } from '../store/auth';
import { useDeviceStreamStore } from '../store/deviceStream';

interface StreamInfo {
  streamKey: string;
  userId: string;
  addedAt: Date;
  updatedAt: Date;
}

export function DeviceStreamPage() {
  const [searchParams] = useSearchParams();
  const streamKey = searchParams.get('streamKey');
  const [copied, setCopied] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const { groups, fetchGroups } = useGroupStore();
  const { user } = useAuthStore();
  const { fetchStreamKey } = useDeviceStreamStore();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchGroups();
    redirectToStreamKey();
  }, [fetchGroups]);

  const redirectToStreamKey = async () => {
    if (!streamKey) {
      try {
        setLoading(true);
        const existingStreamKey = await fetchStreamKey();
        if (existingStreamKey) {
          window.location.href = `/device-stream?streamKey=${existingStreamKey}`;
        }
      } catch (error) {
        console.error('Error fetching stream key:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const loadGroupsWithAccess = async () => {
      if (!streamKey || !user?.id) return;
      
      try {
        // Get all groups that have this stream in their streams array
        const groupsWithAccess = groups.filter(group => 
          group.streams?.some(stream => stream.streamKey === streamKey)
        );
        setSelectedGroups(groupsWithAccess.map(group => group.id));
      } catch (error) {
        console.error('Error loading groups with access:', error);
      }
    };

    loadGroupsWithAccess();
  }, [streamKey, user?.id, groups]);

  const handleCopyLink = () => {
    const streamUrl = `${window.location.origin}/device-stream?streamKey=${streamKey}`;
    navigator.clipboard.writeText(streamUrl).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  const handleGroupToggle = async (groupId: string) => {
    if (!streamKey || !user?.id) return;

    setLoading(true);
    try {
      await runTransaction(db, async (transaction) => {
        const groupRef = doc(db, 'groups', groupId);
        const groupDoc = await transaction.get(groupRef);
        
        if (!groupDoc.exists()) {
          throw new Error('Group not found');
        }

        const groupData = groupDoc.data();
        const streams = groupData.streams || [];
        const now = new Date();

        if (selectedGroups.includes(groupId)) {
          // Remove stream from group
          const updatedStreams = streams.filter(
            (stream: StreamInfo) => stream.streamKey !== streamKey
          );
          transaction.update(groupRef, { 
            streams: updatedStreams,
            updatedAt: now
          });
          setSelectedGroups(prev => prev.filter(id => id !== groupId));
        } else {
          // Add stream to group
          const streamInfo: StreamInfo = {
            streamKey,
            userId: user.id,
            addedAt: now,
            updatedAt: now
          };
          transaction.update(groupRef, { 
            streams: [...streams, streamInfo],
            updatedAt: now
          });
          setSelectedGroups(prev => [...prev, groupId]);
        }
      });
    } catch (error) {
      console.error('Error updating group access:', error);
    } finally {
      setLoading(false);
    }
  };

  if (!streamKey) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="max-w-4xl mx-auto">
          <div className="bg-gradient-to-br from-gray-800/50 to-gray-900/50 rounded-xl border border-gray-700/50 backdrop-blur-sm p-8">
            <h2 className="text-2xl font-light text-white mb-4">Device Stream</h2>
            {loading ? (
              <div className="flex justify-center">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
              </div>
            ) : (
              <p className="text-gray-400">Redirecting to your device stream...</p>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-4xl mx-auto space-y-8">
        <DeviceStreamPlayer streamKey={streamKey} />
        
        {/* Group Access Section */}
        <div className="bg-white shadow rounded-lg p-6">
          <div className="flex items-center space-x-2 mb-4">
            <Users className="w-5 h-5 text-gray-600" />
            <h3 className="text-lg font-semibold text-gray-900">Group Access</h3>
          </div>
          <p className="text-sm text-gray-500 mb-4">
            Select which groups can access this device stream
          </p>
          
          <div className="space-y-2">
            {groups.map(group => {
              const hasAccess = selectedGroups.includes(group.id);
              return (
                <div
                  key={group.id}
                  className="flex items-center justify-between p-3 bg-gray-50 rounded-md"
                >
                  <div className="flex-1">
                    <h4 className="font-medium text-gray-900">{group.name}</h4>
                    <div className="flex items-center space-x-2">
                      <p className="text-sm text-gray-500">{group.memberCount} members</p>
                      <span className="text-sm">•</span>
                      <p className={`text-sm font-medium ${hasAccess ? 'text-green-600' : 'text-red-600'}`}>
                        {hasAccess ? 'Access Enabled' : 'Access Disabled'}
                      </p>
                    </div>
                  </div>
                  <button
                    onClick={() => handleGroupToggle(group.id)}
                    disabled={loading}
                    className={`
                      px-4 py-2 rounded-md text-sm font-medium
                      ${hasAccess 
                        ? 'bg-red-100 text-red-800 hover:bg-red-200' 
                        : 'bg-green-100 text-green-800 hover:bg-green-200'
                      }
                      transition-colors duration-200
                      disabled:opacity-50
                    `}
                  >
                    {hasAccess ? 'Disable Access' : 'Enable Access'}
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeviceStreamPage;
