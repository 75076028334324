import React, { useState } from 'react';
import { FiMaximize2, FiVideo, FiMusic, FiFileText, FiFile, FiTrash2 } from 'react-icons/fi';
import PreviewModal from './PreviewModal';

interface FilePreviewProps {
  fileName: string;
  url: string;
  onDelete: (fileName: string) => void;
}

const FilePreview: React.FC<FilePreviewProps> = ({ fileName, url, onDelete }) => {
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const getFileType = (fileName: string): 'image' | 'video' | 'audio' | 'pdf' | 'text' | 'other' => {
    const ext = fileName.split('.').pop()?.toLowerCase() || '';
    const imageTypes = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'bmp', 'svg'];
    const videoTypes = ['mp4', 'webm', 'mov', 'avi', 'mkv'];
    const audioTypes = ['mp3', 'wav', 'ogg', 'aac', 'm4a'];
    const textTypes = ['txt', 'md', 'json', 'csv', 'xml', 'html', 'css', 'js', 'ts'];
    const pdfType = ['pdf'];

    if (imageTypes.includes(ext)) return 'image';
    if (videoTypes.includes(ext)) return 'video';
    if (audioTypes.includes(ext)) return 'audio';
    if (pdfType.includes(ext)) return 'pdf';
    if (textTypes.includes(ext)) return 'text';
    return 'other';
  };

  const handleDelete = () => {
    if (window.confirm('Do you want to delete this file? Please confirm.')) {
      onDelete(fileName);
    }
  };

  const renderPreview = () => {
    const fileType = getFileType(fileName);
    const iconClass = "w-8 h-8 text-gray-400";

    if (fileType === 'image' && url) {
      return (
        <div className="relative w-full pt-[100%] bg-gray-800 rounded-lg overflow-hidden">
          <img
            src={url}
            alt={fileName}
            className="absolute inset-0 w-full h-full object-cover"
          />
          <div className="absolute top-2 right-2 flex gap-2">
            <button
              onClick={handleDelete}
              className="p-1.5 bg-red-500/70 backdrop-blur-sm rounded-full shadow hover:bg-red-600/80 transition-colors"
              title="Delete file"
            >
              <FiTrash2 className="w-4 h-4 text-white" />
            </button>
            <button
              onClick={() => setIsPreviewOpen(true)}
              className="p-1.5 bg-black/50 backdrop-blur-sm rounded-full shadow hover:bg-black/70 transition-colors"
              title="View larger"
            >
              <FiMaximize2 className="w-4 h-4 text-white" />
            </button>
          </div>
        </div>
      );
    }

    if (fileType === 'video' && url) {
      return (
        <div className="relative w-full pt-[100%] bg-gray-800 rounded-lg overflow-hidden">
          <video
            src={url}
            className="absolute inset-0 w-full h-full object-cover"
            muted
            playsInline
            onMouseOver={e => (e.target as HTMLVideoElement).play()}
            onMouseOut={e => {
              const video = e.target as HTMLVideoElement;
              video.pause();
              video.currentTime = 0;
            }}
          />
          <div className="absolute top-2 right-2 flex gap-2">
            <button
              onClick={handleDelete}
              className="p-1.5 bg-red-500/70 backdrop-blur-sm rounded-full shadow hover:bg-red-600/80 transition-colors"
              title="Delete file"
            >
              <FiTrash2 className="w-4 h-4 text-white" />
            </button>
            <button
              onClick={() => setIsPreviewOpen(true)}
              className="p-1.5 bg-black/50 backdrop-blur-sm rounded-full shadow hover:bg-black/70 transition-colors"
              title="View larger"
            >
              <FiMaximize2 className="w-4 h-4 text-white" />
            </button>
          </div>
        </div>
      );
    }

    // Icon fallbacks for different file types
    switch (fileType) {
      case 'video':
        return <FiVideo className={iconClass} />;
      case 'audio':
        return <FiMusic className={iconClass} />;
      case 'text':
      case 'pdf':
        return <FiFileText className={iconClass} />;
      default:
        return <FiFile className={iconClass} />;
    }
  };

  const fileType = getFileType(fileName);
  const canPreview = ['image', 'video', 'audio', 'pdf', 'text'].includes(fileType) && url;

  return (
    <>
      <div className="flex flex-col items-center justify-center p-2 bg-gray-900/50 backdrop-blur-sm rounded-lg hover:bg-gray-800/50 transition-colors border border-gray-700/50">
        <div className="w-24 h-24 flex items-center justify-center">
          {renderPreview()}
        </div>
        <div className="mt-2 text-xs text-gray-300 truncate w-full text-center">
          {fileName.split('/').pop()}
        </div>
      </div>

      {canPreview && (
        <PreviewModal
          isOpen={isPreviewOpen}
          onClose={() => setIsPreviewOpen(false)}
          fileName={fileName}
          url={url}
          fileType={fileType}
        />
      )}
    </>
  );
};

export default FilePreview;
