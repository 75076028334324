import React, { useState } from 'react';
import { useStreamStore } from '../../store/stream';
import { RoleGuard } from '../../components/auth/RoleGuard';
import { PERMISSIONS } from '../../config/roles';
import { Search, Play, Pause, AlertCircle } from 'lucide-react';
import { Select } from '../../components/ui/Select';

interface StreamFilters {
  status?: 'active' | 'inactive' | 'all';
  search: string;
}

export function AdminStreamsPage() {
  const { streams } = useStreamStore();
  const [filters, setFilters] = useState<StreamFilters>({
    status: 'all',
    search: '',
  });

  const filteredStreams = streams.filter((stream) => {
    if (filters.search && !stream.title?.toLowerCase().includes(filters.search.toLowerCase())) {
      return false;
    }
    if (filters.status === 'active' && !stream.isActive) {
      return false;
    }
    if (filters.status === 'inactive' && stream.isActive) {
      return false;
    }
    return true;
  });

  const getStreamMetrics = (streamId: string) => {
    // This would be implemented with real metrics in production
    return {
      viewers: Math.floor(Math.random() * 100),
      quality: '720p',
      bandwidth: `${Math.floor(Math.random() * 5000)}kbps`,
    };
  };

  return (
    <RoleGuard
      requiredPermission={PERMISSIONS.MANAGE_SYSTEM.id}
      fallback={
        <div className="text-center py-12">
          <h2 className="text-2xl font-semibold text-white">Access Denied</h2>
          <p className="text-gray-400 mt-2">
            You don't have permission to monitor streams.
          </p>
        </div>
      }
    >
      <div className="space-y-8">
        <div>
          <h1 className="text-3xl font-bold text-white">Stream Monitoring</h1>
          <p className="text-gray-400 mt-2">Monitor and manage active streams</p>
        </div>

        {/* Filters */}
        <div className="flex flex-col sm:flex-row gap-4">
          <div className="relative flex-1">
            <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400" />
            <input
              type="text"
              placeholder="Search streams..."
              className="w-full pl-10 pr-4 py-2 bg-white/5 border border-gray-800 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
              value={filters.search}
              onChange={(e) => setFilters({ ...filters, search: e.target.value })}
            />
          </div>
          <Select
            value={filters.status || 'all'}
            onChange={(e) => setFilters({ ...filters, status: e.target.value as 'active' | 'inactive' | 'all' })}
            className="w-full sm:w-48"
            options={[
              { value: 'all', label: 'All Streams' },
              { value: 'active', label: 'Active' },
              { value: 'inactive', label: 'Inactive' }
            ]}
          />
        </div>

        {/* Streams Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredStreams.map((stream) => {
            const metrics = getStreamMetrics(stream.id);
            return (
              <div
                key={stream.id}
                className="bg-white/5 backdrop-blur-lg rounded-lg border border-gray-800 p-6 space-y-4"
              >
                <div className="flex items-center justify-between">
                  <h3 className="text-lg font-medium text-white">{stream.title}</h3>
                  {stream.isActive ? (
                    <span className="flex items-center text-green-400">
                      <Play className="h-4 w-4 mr-1" />
                      Live
                    </span>
                  ) : (
                    <span className="flex items-center text-gray-400">
                      <Pause className="h-4 w-4 mr-1" />
                      Offline
                    </span>
                  )}
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div className="bg-white/5 rounded p-3">
                    <p className="text-sm text-gray-400">Viewers</p>
                    <p className="text-lg font-medium text-white">{metrics.viewers}</p>
                  </div>
                  <div className="bg-white/5 rounded p-3">
                    <p className="text-sm text-gray-400">Quality</p>
                    <p className="text-lg font-medium text-white">{metrics.quality}</p>
                  </div>
                  <div className="bg-white/5 rounded p-3">
                    <p className="text-sm text-gray-400">Bandwidth</p>
                    <p className="text-lg font-medium text-white">{metrics.bandwidth}</p>
                  </div>
                  <div className="bg-white/5 rounded p-3">
                    <p className="text-sm text-gray-400">Status</p>
                    <p className="text-lg font-medium text-white">Healthy</p>
                  </div>
                </div>

                <div className="flex justify-between items-center pt-4 border-t border-gray-800">
                  <button
                    onClick={() => window.location.href = `/stream/${stream.id}`}
                    className="text-sm text-blue-400 hover:text-blue-300"
                  >
                    View Details
                  </button>
                  {stream.isActive && (
                    <button className="flex items-center text-red-400 hover:text-red-300">
                      <AlertCircle className="h-4 w-4 mr-1" />
                      End Stream
                    </button>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </RoleGuard>
  );
}
