import { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../lib/firebase';
import { useAuthStore } from '../store/auth';
import { getCurrentUser } from '../services/auth';

export function useAuth() {
  const [isLoading, setIsLoading] = useState(true);
  const setUser = useAuthStore(state => state.setUser);
  const setIsAuthenticated = useAuthStore(state => state.setIsAuthenticated);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      try {
        if (firebaseUser) {
          const user = await getCurrentUser(firebaseUser);
          if (user) {
            setUser(user);
            setIsAuthenticated(true);
          } else {
            setUser(null);
            setIsAuthenticated(false);
          }
        } else {
          setUser(null);
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error('Auth state change error:', error);
        setUser(null);
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false);
      }
    });

    return () => unsubscribe();
  }, [setUser, setIsAuthenticated]);

  return { isLoading };
}