import { collection, doc, getDoc, getDocs, query, where, orderBy, Timestamp, runTransaction } from 'firebase/firestore';
import { db } from '../firebase';
import { getMemberDocId } from './utils';
import { GROUP_ERRORS, GROUP_ROLES } from './constants';
import type { GroupMemberData, GroupData } from './types';
import type { GroupMember } from '../../types/group';
import { logger } from '../utils/logger';

export async function getGroupDoc(groupId: string) {
  const groupRef = doc(db, 'groups', groupId);
  const groupDoc = await getDoc(groupRef);
  
  if (!groupDoc.exists()) {
    throw new Error(GROUP_ERRORS.NOT_FOUND);
  }
  
  return {
    ref: groupRef,
    doc: groupDoc,
    data: groupDoc.data() as GroupData,
  };
}

export async function getMemberDoc(groupId: string, userId: string) {
  const memberRef = doc(db, 'groupMembers', getMemberDocId(userId, groupId));
  const memberDoc = await getDoc(memberRef);
  
  return {
    ref: memberRef,
    doc: memberDoc,
    data: memberDoc.exists() ? memberDoc.data() as GroupMemberData : null,
  };
}

export async function createMemberDoc(
  groupId: string,
  userId: string,
  userName: string,
  role: GroupMemberData['role'],
  invitedBy?: string
) {
  const memberRef = doc(db, 'groupMembers', getMemberDocId(userId, groupId));
  const groupRef = doc(db, 'groups', groupId);

  await runTransaction(db, async (transaction) => {
    // Check if member already exists
    const memberDoc = await transaction.get(memberRef);
    if (memberDoc.exists()) {
      throw new Error(GROUP_ERRORS.ALREADY_MEMBER);
    }

    // Check if group exists and get owner info
    const groupDoc = await transaction.get(groupRef);
    if (!groupDoc.exists()) {
      throw new Error(GROUP_ERRORS.NOT_FOUND);
    }

    const timestamp = Timestamp.now();
    const memberData: GroupMemberData = {
      userId,
      groupId,
      userName,
      role,
      joinedAt: timestamp,
      ...(invitedBy && { invitedBy }),
    };

    // Log the member creation for debugging
    logger.debug('Creating group member', { 
      groupId, 
      userId, 
      role: memberData.role 
    });

    transaction.set(memberRef, memberData);
    
    // Update group member count
    const currentData = groupDoc.data();
    transaction.update(groupRef, {
      memberCount: (currentData.memberCount || 0) + 1,
      updatedAt: timestamp,
    });
  });

  logger.info('Group member created successfully', { 
    groupId, 
    userId, 
    role 
  });
}

export async function deleteMemberDoc(groupId: string, userId: string) {
  const memberRef = doc(db, 'groupMembers', getMemberDocId(userId, groupId));
  const groupRef = doc(db, 'groups', groupId);

  await runTransaction(db, async (transaction) => {
    const [memberDoc, groupDoc] = await Promise.all([
      transaction.get(memberRef),
      transaction.get(groupRef),
    ]);

    if (!memberDoc.exists()) {
      throw new Error(GROUP_ERRORS.NOT_MEMBER);
    }

    if (!groupDoc.exists()) {
      throw new Error(GROUP_ERRORS.NOT_FOUND);
    }

    transaction.delete(memberRef);
    transaction.update(groupRef, {
      memberCount: Math.max(0, (groupDoc.data().memberCount || 1) - 1),
      updatedAt: Timestamp.now(),
    });
  });
}

export async function getGroupMembers(groupId: string): Promise<GroupMember[]> {
  try {
    const q = query(
      collection(db, 'groupMembers'),
      where('groupId', '==', groupId),
      orderBy('joinedAt', 'asc')
    );
    
    const querySnapshot = await getDocs(q);
    const memberPromises = querySnapshot.docs.map(async (memberDoc) => {
      const memberData = memberDoc.data();
      const userDoc = await getDoc(doc(db, 'users', memberData.userId));
      const userData = userDoc.data();
      
      return {
        id: memberDoc.id,
        userId: memberData.userId,
        userName: userData?.name || memberData.userName || 'Unknown User',
        role: memberData.role,
        joinedAt: memberData.joinedAt?.toDate?.()?.toISOString() || new Date().toISOString(),
      } as GroupMember;
    });

    return Promise.all(memberPromises);
  } catch (error) {
    logger.error('Failed to fetch group members', error as Error);
    throw new Error(GROUP_ERRORS.FETCH_FAILED);
  }
}