import { RoleDefinition, RolePermissions } from '../types/roles';

export const PERMISSIONS = {
  VIEW_CONTENT: {
    id: 'view_content',
    name: 'View Content',
    description: 'Can view streaming content',
  },
  CREATE_CONTENT: {
    id: 'create_content',
    name: 'Create Content',
    description: 'Can create and manage streams',
  },
  MANAGE_GROUPS: {
    id: 'manage_groups',
    name: 'Manage Groups',
    description: 'Can create and manage groups',
  },
  MANAGE_USERS: {
    id: 'manage_users',
    name: 'Manage Users',
    description: 'Can manage user accounts and roles',
  },
  MANAGE_SYSTEM: {
    id: 'manage_system',
    name: 'Manage System',
    description: 'Can manage system settings and configurations',
  },
  VIEW_ANALYTICS: {
    id: 'view_analytics',
    name: 'View Analytics',
    description: 'Can view system analytics and metrics',
  },
  MANAGE_CONTENT: {
    id: 'manage_content',
    name: 'Manage Content',
    description: 'Can manage content moderation rules',
  },
  VIEW_AUDIT_LOGS: {
    id: 'view_audit_logs',
    name: 'View Audit Logs',
    description: 'Can view system audit logs',
  },
  VIEW_REPORTS: {
    id: 'view_reports',
    name: 'View Reports',
    description: 'Can view and generate system reports',
  },
} as const;

export const ROLE_PERMISSIONS: RolePermissions = {
  user: [PERMISSIONS.VIEW_CONTENT],
  creator: [PERMISSIONS.VIEW_CONTENT, PERMISSIONS.CREATE_CONTENT, PERMISSIONS.MANAGE_GROUPS],
  admin: [
    PERMISSIONS.VIEW_CONTENT,
    PERMISSIONS.CREATE_CONTENT,
    PERMISSIONS.MANAGE_GROUPS,
    PERMISSIONS.MANAGE_USERS,
    PERMISSIONS.MANAGE_SYSTEM,
    PERMISSIONS.VIEW_ANALYTICS,
    PERMISSIONS.MANAGE_CONTENT,
    PERMISSIONS.VIEW_AUDIT_LOGS,
    PERMISSIONS.VIEW_REPORTS,
  ],
};

export const ROLES: RoleDefinition[] = [
  {
    name: 'user',
    label: 'User',
    description: 'Standard user with basic viewing permissions',
    permissions: ROLE_PERMISSIONS.user,
  },
  {
    name: 'creator',
    label: 'Content Creator',
    description: 'Can create and manage educational content',
    permissions: ROLE_PERMISSIONS.creator,
  },
  {
    name: 'admin',
    label: 'Administrator',
    description: 'Full system access and management capabilities',
    permissions: ROLE_PERMISSIONS.admin,
  },
];