import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
  sendEmailVerification,
  sendPasswordResetEmail as firebaseSendPasswordResetEmail,
  User as FirebaseUser
} from 'firebase/auth';
import { doc, setDoc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { auth, db } from '../lib/firebase';
import type { User } from '../types/auth';
import { initializeUserStorage, StorageError } from './storage';

export class UserCreationError extends Error {
  constructor(
    message: string,
    public readonly code: string,
    public readonly originalError?: any
  ) {
    super(message);
    this.name = 'UserCreationError';
  }
}

export const createUser = async (
  email: string, 
  password: string, 
  name: string
): Promise<void> => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const { user: firebaseUser } = userCredential;

    await updateProfile(firebaseUser, { displayName: name });

    // Send verification email
    await sendEmailVerification(firebaseUser);

    // Generate random string for RTMP URL
    const streamKey = Math.random().toString(36).substring(2, 15);
    const rtmpUrl = `rtmp://live.surgi-cloud.com/live/${streamKey}`;

    const userData: Omit<User, 'id'> = {
      email: firebaseUser.email!,
      name,
      role: 'creator',
      deviceStreamKey: streamKey,
      rtmpUrl,
    };

    // Add storage configuration (non-sensitive information only)
    const storage = {
      bucket: 'sviz',
      rtmpUrl,
      streamKey,
      folder: firebaseUser.uid
    };

    // Create user document in Firestore
    await setDoc(doc(db, 'users', firebaseUser.uid), {
      ...userData,
      storage,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    });

    // Initialize user storage
    try {
      await initializeUserStorage(firebaseUser.uid);
    } catch (error) {
      if (error instanceof StorageError) {
        console.error('Failed to initialize user storage:', error);
        // Continue with user creation even if storage initialization fails
      } else {
        throw error;
      }
    }

    // Sign out the user after registration - they need to verify email first
    await signOut(auth);

  } catch (error: any) {
    throw new UserCreationError(
      error.message || 'Failed to create user',
      error.code || 'unknown',
      error
    );
  }
};

export const createAdminUser = async (
  email: string, 
  password: string, 
  name: string
): Promise<void> => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const { user: firebaseUser } = userCredential;

    await updateProfile(firebaseUser, { displayName: name });

    // Send verification email
    await sendEmailVerification(firebaseUser);

    const userData: Omit<User, 'id'> = {
      email: firebaseUser.email!,
      name,
      role: 'admin', // Set role as admin
    };

    // Create user document in Firestore with admin role
    await setDoc(doc(db, 'users', firebaseUser.uid), {
      ...userData,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    });

    // Set email as verified for admin users
    await manuallyVerifyEmail(firebaseUser.uid);

    // Sign out the user after registration
    await signOut(auth);

  } catch (error: any) {
    throw new UserCreationError(
      error.message || 'Failed to create admin user',
      error.code || 'unknown',
      error
    );
  }
};

export const loginUser = async (email: string, password: string): Promise<User> => {
  // First attempt to sign in to get the user object
  const userCredential = await signInWithEmailAndPassword(auth, email, password).catch((error) => {
    throw new UserCreationError(
      'Invalid email or password',
      'auth/invalid-credentials',
      error
    );
  });

  const { user: firebaseUser } = userCredential;

  try {
    // Force refresh the token to get the latest status
    await firebaseUser.reload();
    
    const userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
    if (!userDoc.exists()) {
      await signOut(auth);
      throw new Error('User data not found');
    }

    const userData = userDoc.data() as Omit<User, 'id'>;
    
    // Check email verification from Firestore for admin users
    if (userData.role === 'admin' && userData.emailVerified) {
      return {
        id: firebaseUser.uid,
        ...userData,
      };
    }
    
    // For non-admin users, check Firebase Auth email verification
    if (!firebaseUser.emailVerified) {
      await signOut(auth);
      throw new UserCreationError(
        'Please verify your email before logging in.',
        'email-not-verified'
      );
    }

    return {
      id: firebaseUser.uid,
      ...userData,
    };
  } catch (error) {
    // Ensure user is signed out in case of any error
    await signOut(auth);
    throw error;
  }
};

export const logoutUser = async (): Promise<void> => {
  await signOut(auth);
};

export const getCurrentUser = async (firebaseUser: FirebaseUser): Promise<User | null> => {
  try {
    // Get user data from Firestore
    const userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
    
    if (!userDoc.exists()) {
      return null;
    }

    const userData = userDoc.data() as Omit<User, 'id'>;
    
    return {
      id: firebaseUser.uid,
      ...userData,
    };
  } catch (error: any) {
    if (error.code === 'unavailable') {
      console.warn('Firestore is temporarily unavailable, retrying...');
      // Retry once after a short delay
      await new Promise(resolve => setTimeout(resolve, 1000));
      return getCurrentUser(firebaseUser);
    }
    throw error;
  }
};

export const resendVerificationEmail = async (email: string, password: string): Promise<void> => {
  let tempSignIn = false;
  try {
    // Sign in temporarily to get user object
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    tempSignIn = true;
    
    // Send verification email
    await sendEmailVerification(userCredential.user);
    
  } catch (error) {
    throw new UserCreationError(
      error instanceof Error ? error.message : 'Failed to send verification email',
      'auth/verification-failed',
      error
    );
  } finally {
    // Always sign out if we managed to sign in
    if (tempSignIn) {
      await signOut(auth);
    }
  }
};

export const sendPasswordResetEmail = async (email: string): Promise<void> => {
  try {
    await firebaseSendPasswordResetEmail(auth, email);
  } catch (error) {
    throw new UserCreationError(
      'Failed to send password reset email',
      'auth/reset-email-failed',
      error
    );
  }
};

// Function to get user's email verification status from Firebase Auth
export const getEmailVerificationStatus = async (userId: string): Promise<boolean> => {
  try {
    // Get user data from Firestore since we can't directly access Firebase Auth user data
    const userDoc = await getDoc(doc(db, 'users', userId));
    const userEmail = userDoc.data()?.email;
    
    // Check if this user exists in Firebase Auth
    const currentUser = auth.currentUser;
    if (currentUser && currentUser.email === userEmail) {
      return currentUser.emailVerified;
    }
    
    return false;
  } catch (error) {
    console.error('Error getting email verification status:', error);
    return false;
  }
};

// Function to manually verify email (Admin only)
export const manuallyVerifyEmail = async (userId: string): Promise<void> => {
  try {
    // Since we can't directly modify emailVerified in client-side Firebase Auth,
    // we'll store the verification status in Firestore
    await setDoc(doc(db, 'users', userId), {
      emailVerified: true,
      updatedAt: new Date().toISOString()
    }, { merge: true });
    
    // Force a refresh of the current user's token if it's the same user
    const currentUser = auth.currentUser;
    if (currentUser && currentUser.uid === userId) {
      await currentUser.reload();
    }
  } catch (error) {
    console.error('Error manually verifying email:', error);
    throw new Error('Failed to manually verify email');
  }
};

// Function to check if any admin users exist
export const checkIfAdminExists = async (): Promise<boolean> => {
  try {
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where('role', '==', 'admin'));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  } catch (error) {
    console.error('Error checking for admin users:', error);
    return false;
  }
};

// Function to create the first admin user (no auth required)
export const createFirstAdmin = async (
  email: string, 
  password: string, 
  name: string
): Promise<void> => {
  try {
    // Check if any admin exists
    const adminExists = await checkIfAdminExists();
    if (adminExists) {
      throw new Error('Admin user already exists. Use regular admin creation process.');
    }

    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const { user: firebaseUser } = userCredential;

    await updateProfile(firebaseUser, { displayName: name });

    const userData: Omit<User, 'id'> = {
      email: firebaseUser.email!,
      name,
      role: 'admin',
    };

    // Create user document in Firestore with admin role
    await setDoc(doc(db, 'users', firebaseUser.uid), {
      ...userData,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    });

    // Set email as verified for the first admin
    await manuallyVerifyEmail(firebaseUser.uid);

    // Sign out the user after registration
    await signOut(auth);

  } catch (error: any) {
    throw new UserCreationError(
      error.message || 'Failed to create first admin user',
      error.code || 'unknown',
      error
    );
  }
};