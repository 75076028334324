import { FirebaseError } from 'firebase/app';

const LOG_LEVELS = {
  DEBUG: 0,
  INFO: 1,
  WARN: 2,
  ERROR: 3,
} as const;

type LogLevel = keyof typeof LOG_LEVELS;

interface LogEntry {
  timestamp: string;
  level: LogLevel;
  message: string;
  data?: unknown;
  error?: Error;
}

class Logger {
  private static instance: Logger;
  private logs: LogEntry[] = [];
  private level: number = LOG_LEVELS.DEBUG;

  private constructor() {}

  static getInstance(): Logger {
    if (!Logger.instance) {
      Logger.instance = new Logger();
    }
    return Logger.instance;
  }

  setLevel(level: LogLevel) {
    this.level = LOG_LEVELS[level];
  }

  private log(level: LogLevel, message: string, data?: unknown, error?: Error) {
    if (LOG_LEVELS[level] >= this.level) {
      const entry: LogEntry = {
        timestamp: new Date().toISOString(),
        level,
        message,
        data,
        error,
      };
      this.logs.push(entry);
      
      const logFn = level === 'ERROR' ? console.error : 
                    level === 'WARN' ? console.warn : 
                    level === 'INFO' ? console.info : 
                    console.debug;

      if (error instanceof FirebaseError) {
        logFn(`[${level}] ${message}:`, {
          code: error.code,
          message: error.message,
          serverResponse: error.customData,
          stack: error.stack,
        });
      } else if (error) {
        logFn(`[${level}] ${message}:`, error);
      } else if (data) {
        logFn(`[${level}] ${message}:`, data);
      } else {
        logFn(`[${level}] ${message}`);
      }
    }
  }

  debug(message: string, data?: unknown) {
    this.log('DEBUG', message, data);
  }

  info(message: string, data?: unknown) {
    this.log('INFO', message, data);
  }

  warn(message: string, data?: unknown, error?: Error) {
    this.log('WARN', message, data, error);
  }

  error(message: string, error?: Error, data?: unknown) {
    this.log('ERROR', message, data, error);
  }

  getLogs(): LogEntry[] {
    return [...this.logs];
  }

  clearLogs() {
    this.logs = [];
  }
}

export const logger = Logger.getInstance();