import { useState, useEffect, useCallback, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { cn } from '../../lib/utils';
import { Button } from '../ui/Button';
import { useAuthStore } from '../../store/auth';
import {
  Home,
  Users,
  Monitor,
  FolderOpen,
  UserCircle,
  LogOut,
  Menu,
  X,
  Video,
  LayoutDashboard,
} from 'lucide-react';

interface NavItem {
  label: string;
  icon: React.ElementType;
  href: string;
}

const navItems: NavItem[] = [
  { label: 'Home', icon: Home, href: '/home' },
  { label: 'Dashboard', icon: LayoutDashboard, href: '/dashboard' },
  { label: 'Streams', icon: Video, href: '/streams' },
  { label: 'Groups', icon: Users, href: '/groups' },
  { label: 'Device Stream', icon: Monitor, href: '/device-stream' },
  { label: 'File Manager', icon: FolderOpen, href: '/files' },
  { label: 'Profile', icon: UserCircle, href: '/profile' },
];

const AUTO_HIDE_DELAY = 2000; // 2 seconds

export function Sidebar() {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const location = useLocation();
  const { logout } = useAuthStore();
  const autoHideTimer = useRef<NodeJS.Timeout>();
  const sidebarRef = useRef<HTMLDivElement>(null);

  const startAutoHideTimer = useCallback(() => {
    if (autoHideTimer.current) {
      clearTimeout(autoHideTimer.current);
    }
    autoHideTimer.current = setTimeout(() => {
      if (!isHovered) {
        setIsCollapsed(true);
      }
    }, AUTO_HIDE_DELAY);
  }, [isHovered]);

  useEffect(() => {
    if (!isCollapsed) {
      startAutoHideTimer();
    }
    return () => {
      if (autoHideTimer.current) {
        clearTimeout(autoHideTimer.current);
      }
    };
  }, [isCollapsed, startAutoHideTimer]);

  const handleMouseEnter = () => {
    setIsHovered(true);
    if (autoHideTimer.current) {
      clearTimeout(autoHideTimer.current);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    if (!isCollapsed) {
      startAutoHideTimer();
    }
  };

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const isActive = (href: string) => location.pathname === href;

  return (
    <div 
      className="fixed left-0 top-0 h-screen z-50"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={sidebarRef}
    >
      {/* Menu Toggle Button */}
      <button
        onClick={toggleSidebar}
        className={cn(
          "absolute top-3 left-3 p-2 rounded-lg bg-background-secondary hover:bg-background/50 transition-colors z-50",
          isCollapsed ? "left-3" : "left-[13.5rem]"
        )}
      >
        {isCollapsed ? (
          <Menu className="h-4 w-4" />
        ) : (
          <X className="h-4 w-4" />
        )}
      </button>

      {/* Sidebar Content */}
      <aside className={cn(
        "h-full bg-background-secondary text-text transition-all duration-300 border-r border-border overflow-hidden",
        isCollapsed ? "w-0" : "w-56"
      )}>
        <div className="p-3 h-12" /> {/* Spacer for the toggle button */}

        <nav className="flex-1 space-y-0.5 p-2">
          {navItems.map((item) => (
            <Link
              key={item.href}
              to={item.href}
              className={cn(
                "flex items-center px-3 py-2 text-sm font-medium rounded-lg transition-colors whitespace-nowrap",
                isActive(item.href)
                  ? "bg-background text-text"
                  : "text-text-secondary hover:text-text hover:bg-background/50"
              )}
            >
              <item.icon className="h-4 w-4 flex-shrink-0" />
              <span className="ml-3">
                {item.label}
              </span>
            </Link>
          ))}
        </nav>

        <div className="p-2">
          <Button
            onClick={logout}
            variant="ghost"
            className="w-full justify-start text-red-400 hover:text-red-500 hover:bg-red-500/10 whitespace-nowrap"
          >
            <LogOut className="h-4 w-4 flex-shrink-0" />
            <span className="ml-3">
              Logout
            </span>
          </Button>
        </div>
      </aside>
    </div>
  );
}
