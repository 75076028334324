import { 
  Firestore, 
  writeBatch, 
  doc, 
  getDoc, 
  collection,
  query, 
  where, 
  getDocs,
  serverTimestamp,
  Timestamp,
  runTransaction,
  updateDoc
} from 'firebase/firestore';
import { Auth } from 'firebase/auth';
import { getUserIdByEmail } from '../utils/users';
import { logger } from '../utils/logger';
import { EmailInvitation, InvitationResult, InvitationValidation } from './types';

export class InvitationManager {
  constructor(
    private readonly db: Firestore,
    private readonly auth: Auth
  ) {}

  /**
   * Send invitations to multiple email addresses
   * @returns Object containing arrays of successful and failed invitations
   */
  async sendBatchInvitations(
    groupId: string,
    emails: string[],
    invitedBy: string,
    invitedByName: string,
    expiresInDays = 7
  ): Promise<InvitationResult> {
    const batch = writeBatch(this.db);
    const results: InvitationResult = { success: [], failed: [] };

    // Validate current user
    const currentUser = this.auth.currentUser;
    if (!currentUser) {
      throw new Error('Must be authenticated to send invitations');
    }

    // Verify the invitedBy matches the current user
    if (currentUser.uid !== invitedBy) {
      throw new Error('Invalid invitedBy: must match the authenticated user');
    }

    // Get group data first
    const groupRef = doc(this.db, 'groups', groupId);
    const groupDoc = await getDoc(groupRef);
    if (!groupDoc.exists()) {
      throw new Error('Group not found');
    }
    const groupData = groupDoc.data();
    const groupName = groupData.name;

    logger.debug('Starting batch invitations', {
      groupId,
      groupName,
      invitedBy,
      invitedByName,
      emailCount: emails.length
    });

    for (const email of emails) {
      try {
        const normalizedEmail = email.toLowerCase().trim();
        if (!normalizedEmail) {
          results.failed.push({ email, errorMessage: 'Invalid email address' });
          continue;
        }

        const invitationId = `${normalizedEmail}_${groupId}`;
        const invitationRef = doc(this.db, 'groupInvitations', invitationId);

        // Check if user can be invited
        const canInvite = await this.canInviteToGroup(groupId, normalizedEmail);
        if (!canInvite.allowed) {
          results.failed.push({ email, errorMessage: canInvite.reason || 'Cannot invite user' });
          continue;
        }

        // Create invitation with all required fields
        const now = new Date();
        const timestamp = serverTimestamp();
        const expiresAt = Timestamp.fromDate(
          new Date(now.getTime() + expiresInDays * 24 * 60 * 60 * 1000)
        );
        
        const invitationData = {
          groupId,
          groupName,
          email: normalizedEmail,
          invitedBy,
          invitedByName,
          status: "pending",
          createdAt: timestamp,
          updatedAt: timestamp,
          expiresAt
        };

        logger.debug('Creating invitation', { 
          invitationId, 
          invitationData 
        });

        batch.set(invitationRef, invitationData);
        results.success.push(email);
      } catch (error) {
        const err = error instanceof Error ? error : new Error(String(error));
        logger.error('Failed to create invitation', err, { 
          email,
          groupId,
          invitedBy 
        });
        results.failed.push({ 
          email, 
          errorMessage: err.message
        });
      }
    }

    try {
      await batch.commit();
      logger.info('Successfully sent batch invitations', {
        successCount: results.success.length,
        failedCount: results.failed.length,
        groupId
      });
    } catch (error) {
      const err = error instanceof Error ? error : new Error(String(error));
      logger.error('Failed to commit invitation batch', err, {
        groupId,
        invitedBy,
        emailCount: emails.length
      });
      throw err;
    }

    return results;
  }

  /**
   * Accept an invitation and join the group
   */
  async acceptInvitation(invitationId: string): Promise<void> {
    return runTransaction(this.db, async (transaction) => {
      // Perform all reads first
      const invitationRef = doc(this.db, 'groupInvitations', invitationId);
      const invitationDoc = await transaction.get(invitationRef);

      if (!invitationDoc.exists()) {
        throw new Error('Invitation not found');
      }

      const invitation = invitationDoc.data() as EmailInvitation;
      const currentUser = this.auth.currentUser;
      if (!currentUser) {
        throw new Error('Must be authenticated');
      }

      // Verify email matches
      if (currentUser.email?.toLowerCase() !== invitation.email) {
        throw new Error('Invitation email does not match');
      }

      // Check if invitation has expired
      if (invitation.expiresAt.toDate() < new Date()) {
        throw new Error('Invitation has expired');
      }

      // Read group data first
      const groupRef = doc(this.db, 'groups', invitation.groupId);
      const groupDoc = await transaction.get(groupRef);

      // Check if member already exists
      const memberId = `${currentUser.uid}_${invitation.groupId}`;
      const memberRef = doc(this.db, 'groupMembers', memberId);
      const memberDoc = await transaction.get(memberRef);

      if (memberDoc.exists()) {
        throw new Error('Already a member of this group');
      }

      // Now perform all writes
      // 1. Create member document
      transaction.set(memberRef, {
        userId: currentUser.uid,
        groupId: invitation.groupId,
        role: 'member',
        joinedAt: serverTimestamp()
      });

      // 2. Update invitation status
      transaction.update(invitationRef, {
        status: "accepted",
        userId: currentUser.uid,
        updatedAt: serverTimestamp()
      });

      // 3. Update group member count if group exists
      if (groupDoc.exists()) {
        transaction.update(groupRef, {
          memberCount: (groupDoc.data().memberCount || 0) + 1,
          updatedAt: serverTimestamp()
        });
      }
    });
  }

  /**
   * Decline an invitation
   */
  async declineInvitation(invitationId: string): Promise<void> {
    const invitationRef = doc(this.db, 'groupInvitations', invitationId);
    const invitationDoc = await getDoc(invitationRef);

    if (!invitationDoc.exists()) {
      throw new Error('Invitation not found');
    }

    const invitation = invitationDoc.data() as EmailInvitation;
    const currentUser = this.auth.currentUser;
    if (!currentUser) {
      throw new Error('Must be authenticated');
    }

    // Verify email matches
    if (currentUser.email?.toLowerCase() !== invitation.email) {
      throw new Error('Invitation email does not match');
    }

    await updateDoc(invitationRef, {
      status: "declined",
      updatedAt: serverTimestamp()
    });
  }

  /**
   * Get all pending invitations for an email
   */
  async getInvitationsByEmail(email: string): Promise<EmailInvitation[]> {
    const currentUser = this.auth.currentUser;
    if (!currentUser || currentUser.email?.toLowerCase() !== email.toLowerCase()) {
      throw new Error('Can only view your own invitations');
    }

    const q = query(
      collection(this.db, 'groupInvitations'),
      where('email', '==', email.toLowerCase()),
      where('status', '==', "pending"),
      where('expiresAt', '>', Timestamp.now())
    );

    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as EmailInvitation[];
  }

  /**
   * Get all invitations sent by a user
   */
  async getInvitationsBySender(userId: string): Promise<EmailInvitation[]> {
    const currentUser = this.auth.currentUser;
    if (!currentUser || currentUser.uid !== userId) {
      throw new Error('Can only view your own sent invitations');
    }

    const q = query(
      collection(this.db, 'groupInvitations'),
      where('invitedBy', '==', userId)
    );

    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as EmailInvitation[];
  }

  /**
   * Check if a user can be invited to a group
   */
  private async canInviteToGroup(
    groupId: string, 
    email: string
  ): Promise<InvitationValidation> {
    // Check if group exists and user has permission
    const groupRef = doc(this.db, 'groups', groupId);
    const groupDoc = await getDoc(groupRef);

    if (!groupDoc.exists()) {
      return { allowed: false, reason: 'Group not found' };
    }

    // Check if user is already a member
    const userId = await getUserIdByEmail(email);
    if (userId) {
      const memberRef = doc(this.db, 'groupMembers', `${userId}_${groupId}`);
      const memberDoc = await getDoc(memberRef);
      if (memberDoc.exists()) {
        return { allowed: false, reason: 'User is already a member' };
      }
    }

    // Check for pending invitations
    const pendingInviteQuery = query(
      collection(this.db, 'groupInvitations'),
      where('groupId', '==', groupId),
      where('email', '==', email.toLowerCase()),
      where('status', '==', "pending"),
      where('expiresAt', '>', Timestamp.now())
    );
    
    const pendingInvites = await getDocs(pendingInviteQuery);
    if (!pendingInvites.empty) {
      return { allowed: false, reason: 'User already has a pending invitation' };
    }

    return { allowed: true };
  }
}
