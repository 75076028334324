import React, { useEffect, useState, useCallback } from 'react';
import { useAuthStore } from '../../store/auth';
import { StorageFile, listUserFiles, uploadFile, deleteUserFile, getFileUrl } from '../../services/storage';
import { formatBytes, formatDate } from '../../utils/formatters';
import { FiUpload, FiDownload, FiTrash2, FiFile, FiGrid, FiList, FiChevronUp, FiChevronDown } from 'react-icons/fi';
import FilePreview from './FilePreview';

type SortField = 'name' | 'size' | 'lastModified';
type SortDirection = 'asc' | 'desc';
type ViewMode = 'grid' | 'list';

interface UploadProgress {
  fileName: string;
  progress: number;
  startTime: number;
  loaded: number;
  total: number;
}

const ITEMS_PER_PAGE = 12;

const FileManager: React.FC = () => {
  const { user } = useAuthStore();
  const [files, setFiles] = useState<StorageFile[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState<SortField>('name');
  const [sortDirection, setSortDirection] = useState<SortDirection>('asc');
  const [viewMode, setViewMode] = useState<ViewMode>('grid');
  const [fileUrls, setFileUrls] = useState<Record<string, string>>({});
  const [uploadProgress, setUploadProgress] = useState<Record<string, UploadProgress>>({});

  const loadFiles = useCallback(async () => {
    if (!user) return;
    
    try {
      setIsLoading(true);
      const userFiles = await listUserFiles(user.id);
      setFiles(userFiles);
      
      // Load URLs for all files
      const urls: Record<string, string> = {};
      const errors: Record<string, string> = {};
      
      for (const file of userFiles) {
        try {
          const fileName = file.key.split('/').pop()!;
          console.log('Loading URL for file:', fileName);
          urls[file.key] = await getFileUrl(user.id, fileName);
        } catch (err: any) {
          console.error('Failed to load preview for:', file.key, err);
          errors[file.key] = err.message;
          // Don't set global error for individual file failures
        }
      }
      
      setFileUrls(urls);
      
      // Only set error if no files could be loaded at all
      if (Object.keys(urls).length === 0 && userFiles.length > 0) {
        setError('Failed to load any file previews. Please try refreshing the page.');
      } else {
        setError(null);
      }
    } catch (err: any) {
      setError(err.message || 'Failed to load files');
    } finally {
      setIsLoading(false);
    }
  }, [user]);

  useEffect(() => {
    loadFiles();
  }, [loadFiles]);

  const calculateTimeRemaining = (progress: UploadProgress): number => {
    const elapsedTime = (Date.now() - progress.startTime) / 1000; // in seconds
    const uploadSpeed = progress.loaded / elapsedTime; // bytes per second
    const remainingBytes = progress.total - progress.loaded;
    return remainingBytes / uploadSpeed;
  };

  const handleFileUpload = async (uploadedFiles: FileList | null) => {
    if (!user || !uploadedFiles?.length) return;

    try {
      setIsLoading(true);
      const uploadPromises = Array.from(uploadedFiles).map(file => {
        // Create initial progress state for this file
        setUploadProgress(prev => ({
          ...prev,
          [file.name]: {
            fileName: file.name,
            progress: 0,
            startTime: Date.now(),
            loaded: 0,
            total: file.size
          }
        }));

        // Upload with progress tracking
        return uploadFile(user.id, file, (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          setUploadProgress(prev => ({
            ...prev,
            [file.name]: {
              fileName: file.name,
              progress,
              startTime: prev[file.name]?.startTime || Date.now(),
              loaded: progressEvent.loaded,
              total: progressEvent.total
            }
          }));
        });
      });
      
      await Promise.all(uploadPromises);
      await loadFiles();
      setError(null);
    } catch (err: any) {
      setError(err.message || 'Failed to upload files');
    } finally {
      setIsLoading(false);
      setUploadProgress({}); // Clear progress state
    }
  };

  const handleDelete = async (fileName: string) => {
    if (!user) return;
    
    try {
      await deleteUserFile(user.id, fileName);
      // Refresh the file list
      loadFiles();
    } catch (error) {
      setError('Failed to delete file');
      console.error('Error deleting file:', error);
    }
  };

  const handleDownload = async (fileName: string) => {
    if (!user) return;

    try {
      const url = await getFileUrl(user.id, fileName);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err: any) {
      setError(err.message || 'Failed to download file');
    }
  };

  const handleSort = (field: SortField) => {
    if (field === sortField) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const sortedFiles = [...files].sort((a, b) => {
    const direction = sortDirection === 'asc' ? 1 : -1;

    switch (sortField) {
      case 'name':
        return direction * a.key.localeCompare(b.key);
      case 'size':
        return direction * (a.size - b.size);
      case 'lastModified':
        return direction * (a.lastModified.getTime() - b.lastModified.getTime());
      default:
        return 0;
    }
  });

  const totalPages = Math.ceil(sortedFiles.length / ITEMS_PER_PAGE);
  const paginatedFiles = sortedFiles.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const SortIcon = ({ field }: { field: SortField }) => {
    if (field !== sortField) return null;
    return sortDirection === 'asc' ? <FiChevronUp className="inline" /> : <FiChevronDown className="inline" />;
  };

  if (!user) {
    return <div className="p-4">Please log in to access your files.</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">File Manager</h1>
        <div className="flex gap-2">
          <button
            onClick={() => setViewMode('grid')}
            className={`p-2 rounded ${viewMode === 'grid' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          >
            <FiGrid />
          </button>
          <button
            onClick={() => setViewMode('list')}
            className={`p-2 rounded ${viewMode === 'list' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          >
            <FiList />
          </button>
        </div>
      </div>
      
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {error}
        </div>
      )}

      <div
        className={`border-2 border-dashed rounded-lg p-8 mb-6 text-center transition-colors
          ${isDragging ? 'border-blue-500 bg-blue-50' : 'border-gray-300'}
          hover:border-blue-500 hover:bg-blue-50`}
        onDragOver={(e) => { e.preventDefault(); setIsDragging(true); }}
        onDragLeave={(e) => { e.preventDefault(); setIsDragging(false); }}
        onDrop={(e) => {
          e.preventDefault();
          setIsDragging(false);
          handleFileUpload(e.dataTransfer.files);
        }}
      >
        <FiUpload className="mx-auto text-4xl mb-2 text-gray-400" />
        <p className="text-gray-600">Drag and drop files here, or</p>
        <input
          type="file"
          multiple
          className="hidden"
          onChange={(e) => handleFileUpload(e.target.files)}
          id="fileInput"
        />
        <label
          htmlFor="fileInput"
          className="inline-block mt-2 px-4 py-2 bg-blue-500 text-white rounded cursor-pointer hover:bg-blue-600"
        >
          Select Files
        </label>

        {/* Upload Progress Indicators */}
        {Object.values(uploadProgress).length > 0 && (
          <div className="mt-4 space-y-2">
            {Object.values(uploadProgress).map((progress) => (
              <div key={progress.fileName} className="max-w-md mx-auto">
                <div className="flex justify-between text-sm text-gray-600 mb-1">
                  <span className="truncate">{progress.fileName}</span>
                  <span className="ml-2">
                    {Math.round(progress.progress)}% ({Math.round(calculateTimeRemaining(progress))}s remaining)
                  </span>
                </div>
                <div className="w-full h-2 bg-gray-200 rounded-full overflow-hidden">
                  <div
                    className="h-full bg-blue-500 transition-all duration-300"
                    style={{ width: `${progress.progress}%` }}
                  />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {isLoading ? (
        <div className="text-center py-4">Loading...</div>
      ) : paginatedFiles.length === 0 ? (
        <div className="text-center py-4 text-gray-500">No files uploaded yet</div>
      ) : viewMode === 'grid' ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {paginatedFiles.map((file) => {
            const fileName = file.key.split('/').pop()!;
            return (
              <div key={file.key} className="relative group">
                <FilePreview
                  fileName={fileName}
                  url={fileUrls[file.key] || ''}
                  onDelete={handleDelete}
                />
              </div>
            );
          })}
        </div>
      ) : (
        <div className="bg-white rounded-lg shadow overflow-hidden">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                  onClick={() => handleSort('name')}
                >
                  Name <SortIcon field="name" />
                </th>
                <th
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                  onClick={() => handleSort('size')}
                >
                  Size <SortIcon field="size" />
                </th>
                <th
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                  onClick={() => handleSort('lastModified')}
                >
                  Last Modified <SortIcon field="lastModified" />
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {paginatedFiles.map((file) => {
                const fileName = file.key.split('/').pop() || '';
                return (
                  <tr key={file.key} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <FiFile className="flex-shrink-0 mr-2" />
                        <span className="truncate max-w-xs">{fileName}</span>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {formatBytes(file.size)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {formatDate(file.lastModified)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <button
                        onClick={() => handleDownload(fileName)}
                        className="text-blue-600 hover:text-blue-900 mx-2"
                        title="Download"
                      >
                        <FiDownload />
                      </button>
                      <button
                        onClick={() => handleDelete(fileName)}
                        className="text-red-600 hover:text-red-900 mx-2"
                        title="Delete"
                      >
                        <FiTrash2 />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      {/* Pagination */}
      {totalPages > 1 && (
        <div className="mt-4 flex justify-center gap-2">
          <button
            onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
            disabled={currentPage === 1}
            className="px-3 py-1 rounded bg-gray-200 disabled:opacity-50"
          >
            Previous
          </button>
          {Array.from({ length: totalPages }, (_, i) => i + 1).map(page => (
            <button
              key={page}
              onClick={() => setCurrentPage(page)}
              className={`px-3 py-1 rounded ${
                currentPage === page ? 'bg-blue-500 text-white' : 'bg-gray-200'
              }`}
            >
              {page}
            </button>
          ))}
          <button
            onClick={() => setCurrentPage(p => Math.min(totalPages, p + 1))}
            disabled={currentPage === totalPages}
            className="px-3 py-1 rounded bg-gray-200 disabled:opacity-50"
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default FileManager;
