import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { logger } from './logger';

export async function getUserIdByEmail(email: string): Promise<string | null> {
  try {
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where('email', '==', email));
    const querySnapshot = await getDocs(q);
    
    if (querySnapshot.empty) {
      logger.warn('No user found with email:', email);
      return null;
    }

    return querySnapshot.docs[0].id;
  } catch (error) {
    logger.error('Error getting user by email:', error instanceof Error ? error : new Error(String(error)));
    return null;
  }
}
