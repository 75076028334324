import React, { useState, useEffect } from 'react';
import { ref, uploadBytes, getDownloadURL, listAll, deleteObject, getMetadata } from 'firebase/storage';
import { storage } from '../lib/firebase';
import { useAuthStore } from '../store/auth';
import { useGroupStore } from '../store/group';
import { FaFile, FaFilePdf, FaFileImage, FaFilePowerpoint, FaTrash, FaPencilAlt, FaDownload, FaEye } from 'react-icons/fa';
import * as DialogPrimitive from '@radix-ui/react-dialog';

interface GroupResourcesProps {
  groupId: string;
  isAdmin: boolean;
}

interface ResourceItem {
  name: string;
  url: string;
  type: string;
  fullPath: string;
  size: number;
}

const GroupResources: React.FC<GroupResourcesProps> = ({ groupId, isAdmin }) => {
  const [resources, setResources] = useState<ResourceItem[]>([]);
  const [uploading, setUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isRenaming, setIsRenaming] = useState(false);
  const [selectedResource, setSelectedResource] = useState<ResourceItem | null>(null);
  const [newName, setNewName] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const { user, isAuthenticated } = useAuthStore();
  const { currentGroup } = useGroupStore();

  // Check if the current user can manage resources (admin or owner)
  const canManageResources = isAdmin || (currentGroup?.ownerId === user?.id);

  const loadResources = async () => {
    if (!isAuthenticated || !user) {
      setError('Please sign in to view resources');
      return;
    }

    const resourcesRef = ref(storage, `group-resources/${groupId}`);
    try {
      const result = await listAll(resourcesRef);
      const resourcesList = await Promise.all(
        result.items.map(async (item) => {
          const url = await getDownloadURL(item);
          const type = item.name.split('.').pop()?.toLowerCase() || '';
          const size = (await getMetadata(item)).size;
          return {
            name: item.name,
            url,
            type,
            fullPath: item.fullPath,
            size,
          };
        })
      );
      setResources(resourcesList);
      setError(null);
    } catch (error) {
      console.error('Error loading resources:', error);
      setError('Error loading resources. Please try again later.');
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      loadResources();
    }
  }, [groupId, isAuthenticated]);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
      setUploading(true);
      try {
        const file = e.target.files[0];
        const fileRef = ref(storage, `group-resources/${groupId}/${file.name}`);
        await uploadBytes(fileRef, file);
        await loadResources();
        setSelectedFile(null);
        e.target.value = ''; // Reset the input
      } catch (error) {
        console.error('Error uploading file:', error);
        setError('Error uploading file. Please try again.');
      } finally {
        setUploading(false);
      }
    }
  };

  const handleDelete = async (resource: ResourceItem) => {
    if (!window.confirm('Are you sure you want to delete this resource?')) return;

    try {
      const fileRef = ref(storage, resource.fullPath);
      await deleteObject(fileRef);
      await loadResources();
    } catch (error) {
      console.error('Error deleting resource:', error);
    }
  };

  const handleRename = async () => {
    if (!selectedResource || !newName) return;

    try {
      const oldRef = ref(storage, selectedResource.fullPath);
      const newRef = ref(storage, `group-resources/${groupId}/${newName}`);
      
      // Download the file data
      const response = await fetch(selectedResource.url);
      const blob = await response.blob();
      
      // Upload to new location
      await uploadBytes(newRef, blob);
      
      // Delete old file
      await deleteObject(oldRef);
      
      await loadResources();
      setIsRenaming(false);
      setSelectedResource(null);
      setNewName('');
    } catch (error) {
      console.error('Error renaming resource:', error);
    }
  };

  const getFileIcon = (type: string) => {
    const iconClass = "w-8 h-8";
    
    if (type.includes('pdf')) {
      return <FaFilePdf className={`${iconClass} text-red-400`} />;
    } else if (type.includes('image')) {
      return <FaFileImage className={`${iconClass} text-blue-400`} />;
    } else if (type.includes('presentation') || type.includes('powerpoint')) {
      return <FaFilePowerpoint className={`${iconClass} text-orange-400`} />;
    } else {
      return <FaFile className={`${iconClass} text-gray-400`} />;
    }
  };

  const isPreviewable = (type: string) => {
    return ['pdf', 'jpg', 'jpeg', 'png', 'gif'].includes(type.toLowerCase());
  };

  const truncateFileName = (name: string, maxLength: number = 20) => {
    if (name.length <= maxLength) return name;
    const extension = name.split('.').pop();
    const nameWithoutExt = name.substring(0, name.lastIndexOf('.'));
    const truncatedName = nameWithoutExt.substring(0, maxLength - 3) + '...';
    return `${truncatedName}.${extension}`;
  };

  const formatFileSize = (bytes: number) => {
    if (!bytes) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(1))} ${sizes[i]}`;
  };

  return (
    <div className="bg-gray-800/50 rounded-xl border border-gray-700/50 p-6 flex flex-col h-[600px]">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl font-light tracking-wide text-white/90">Learning Resources</h2>
        {canManageResources && (
          <label className={`inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 cursor-pointer transition-colors ${uploading ? 'opacity-50 cursor-not-allowed' : ''}`}>
            <span>{uploading ? 'Uploading...' : 'Choose File'}</span>
            <input
              type="file"
              className="hidden"
              onChange={handleFileChange}
              multiple
              disabled={uploading}
            />
          </label>
        )}
      </div>

      <div className="overflow-hidden flex-1 flex flex-col">
        <div className="overflow-x-auto flex-1">
          <div className="min-w-full h-full overflow-y-auto">
            <table className="w-full table-fixed">
              <thead className="sticky top-0 bg-gray-800/50 z-10">
                <tr className="text-left text-gray-400 text-sm border-b border-gray-700/50">
                  <th className="pb-3 font-medium w-[50%]">Name</th>
                  <th className="pb-3 font-medium w-[20%]">Size</th>
                  <th className="pb-3 font-medium text-right w-[30%]">Actions</th>
                </tr>
              </thead>
              <tbody className="overflow-y-auto">
                {resources.map((resource) => (
                  <tr key={resource.name} className="border-b border-gray-700/30 hover:bg-gray-700/20">
                    <td className="py-3">
                      <div className="flex items-center space-x-3">
                        <div className="flex-shrink-0">
                          {getFileIcon(resource.type)}
                        </div>
                        <span className="text-gray-200">{resource.name}</span>
                      </div>
                    </td>
                    <td className="py-3 text-gray-400 text-sm">
                      {formatFileSize(resource.size)}
                    </td>
                    <td className="py-3">
                      <div className="flex items-center justify-end space-x-1">
                        {isPreviewable(resource.type) && (
                          <button
                            onClick={() => {
                              setSelectedResource(resource);
                              setIsPreviewOpen(true);
                            }}
                            className="p-1.5 text-gray-400 hover:text-white rounded-md hover:bg-gray-700/50 transition-colors"
                            title="Preview"
                          >
                            <FaEye className="w-3.5 h-3.5" />
                          </button>
                        )}
                        <a
                          href={resource.url}
                          download={resource.name}
                          className="p-1.5 text-gray-400 hover:text-white rounded-md hover:bg-gray-700/50 transition-colors"
                          title="Download"
                        >
                          <FaDownload className="w-3.5 h-3.5" />
                        </a>
                        {canManageResources && (
                          <>
                            <button
                              onClick={() => {
                                setSelectedResource(resource);
                                setNewName(resource.name);
                                setIsRenaming(true);
                              }}
                              className="p-1.5 text-gray-400 hover:text-white rounded-md hover:bg-gray-700/50 transition-colors"
                              title="Rename"
                            >
                              <FaPencilAlt className="w-3.5 h-3.5" />
                            </button>
                            <button
                              onClick={() => handleDelete(resource)}
                              className="p-1.5 text-gray-400 hover:text-red-400 rounded-md hover:bg-gray-700/50 transition-colors"
                              title="Delete"
                            >
                              <FaTrash className="w-3.5 h-3.5" />
                            </button>
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Preview Dialog */}
      <DialogPrimitive.Root open={isPreviewOpen} onOpenChange={() => setIsPreviewOpen(false)}>
        <DialogPrimitive.Portal>
          <DialogPrimitive.Overlay className="fixed inset-0 bg-black/30 backdrop-blur-sm" />
          <div className="fixed inset-0 flex items-center justify-center">
            <DialogPrimitive.Content className="relative bg-gray-800 rounded-lg p-6 max-w-4xl mx-auto">
              <DialogPrimitive.Title className="text-lg font-medium text-gray-200 mb-4">
                {selectedResource?.name}
              </DialogPrimitive.Title>
              <DialogPrimitive.Description className="sr-only">
                Preview of {selectedResource?.name}
              </DialogPrimitive.Description>
              
              <div className="relative w-full aspect-video bg-gray-900 rounded overflow-hidden">
                {selectedResource?.type.includes('image') ? (
                  <img
                    src={selectedResource.url}
                    alt={selectedResource.name}
                    className="w-full h-full object-contain"
                  />
                ) : selectedResource?.type.includes('pdf') ? (
                  <iframe
                    src={selectedResource.url}
                    className="w-full h-full"
                    title={selectedResource.name}
                  />
                ) : (
                  <div className="flex items-center justify-center h-full text-gray-400">
                    Preview not available for this file type
                  </div>
                )}
              </div>

              <div className="mt-4 flex justify-end">
                <button
                  onClick={() => setIsPreviewOpen(false)}
                  className="px-4 py-2 text-sm font-medium text-gray-200 hover:text-white"
                >
                  Close
                </button>
              </div>
            </DialogPrimitive.Content>
          </div>
        </DialogPrimitive.Portal>
      </DialogPrimitive.Root>

      {/* Rename Dialog */}
      <DialogPrimitive.Root open={isRenaming} onOpenChange={() => setIsRenaming(false)}>
        <DialogPrimitive.Portal>
          <DialogPrimitive.Overlay className="fixed inset-0 bg-black/30 backdrop-blur-sm" />
          <div className="fixed inset-0 flex items-center justify-center">
            <DialogPrimitive.Content className="relative bg-gray-800 rounded-lg p-6 max-w-sm mx-auto">
              <DialogPrimitive.Title className="text-lg font-medium text-white mb-4">
                Rename Resource
              </DialogPrimitive.Title>
              <DialogPrimitive.Description className="text-sm text-gray-400 mb-4">
                Enter a new name for your resource
              </DialogPrimitive.Description>
              <input
                type="text"
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
                className="w-full px-3 py-2 bg-gray-700 text-white border border-gray-600 rounded-md mb-4"
              />
              <div className="flex justify-end space-x-2">
                <button
                  onClick={() => setIsRenaming(false)}
                  className="px-4 py-2 text-gray-300 hover:text-white"
                >
                  Cancel
                </button>
                <button
                  onClick={handleRename}
                  className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                >
                  Rename
                </button>
              </div>
            </DialogPrimitive.Content>
          </div>
        </DialogPrimitive.Portal>
      </DialogPrimitive.Root>
    </div>
  );
};

export default GroupResources;
