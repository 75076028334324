import { FirebaseError } from 'firebase/app';
import { logger } from './logger';

export class AppError extends Error {
  constructor(
    message: string,
    public code?: string,
    public status: number = 400
  ) {
    super(message);
    this.name = 'AppError';
  }
}

export function handleFirebaseError(error: unknown): never {
  if (error instanceof FirebaseError) {
    logger.error('Firebase operation failed', error, {
      code: error.code,
      operation: error.operation,
      customData: error.customData,
    });

    switch (error.code) {
      case 'permission-denied':
        throw new AppError('You do not have permission to perform this action', error.code, 403);
      case 'not-found':
        throw new AppError('The requested resource was not found', error.code, 404);
      case 'already-exists':
        throw new AppError('This resource already exists', error.code, 409);
      case 'resource-exhausted':
        throw new AppError('Too many requests. Please try again later', error.code, 429);
      case 'failed-precondition':
        throw new AppError('Operation cannot be performed in the current state', error.code, 400);
      case 'invalid-argument':
        throw new AppError('Invalid data provided for the operation', error.code, 400);
      default:
        logger.error('Unhandled Firebase error', error);
        throw new AppError('An unexpected error occurred', error.code, 500);
    }
  }
  
  if (error instanceof Error) {
    logger.error('Non-Firebase error occurred', error);
    throw new AppError(error.message);
  }
  
  logger.error('Unknown error type', new Error('Unknown error'), { error });
  throw new AppError('An unexpected error occurred');
}