import { GROUP_ROLES } from './constants';
import { getMemberDoc } from './db';
import { logger } from '../utils/logger';

export async function checkGroupPermissions(groupId: string, userId: string) {
  try {
    const { data: memberData } = await getMemberDoc(groupId, userId);
    
    const isMember = !!memberData;
    const isAdmin = memberData?.role === GROUP_ROLES.ADMIN;
    const isOwner = memberData?.role === GROUP_ROLES.OWNER;
    const canManageGroup = isAdmin || isOwner;

    logger.debug('Group permissions checked', { 
      groupId, 
      userId, 
      isMember, 
      isAdmin, 
      isOwner,
      canManageGroup
    });

    return {
      isMember,
      isAdmin,
      isOwner,
      canManageGroup,
      role: memberData?.role,
    };
  } catch (error) {
    const err = error instanceof Error ? error : new Error(String(error));
    logger.error('Failed to check group permissions', err, { groupId, userId });
    throw err;
  }
}