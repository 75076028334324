import React, { useState, useEffect } from 'react';
import { RoleGuard } from '../../components/auth/RoleGuard';
import { PERMISSIONS } from '../../config/roles';
import { ContentModerationRule } from '../../types/admin';
import { createModerationRule, getModerationRules, updateModerationRule } from '../../services/admin';
import { Shield, Plus, AlertTriangle, Check, X } from 'lucide-react';

export function ContentModerationPage() {
  const [rules, setRules] = useState<ContentModerationRule[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [editingRule, setEditingRule] = useState<ContentModerationRule | null>(null);

  useEffect(() => {
    loadRules();
  }, []);

  const loadRules = async () => {
    try {
      const fetchedRules = await getModerationRules();
      setRules(fetchedRules);
    } catch (error) {
      setError('Failed to load moderation rules');
      console.error('Error loading moderation rules:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateRule = async () => {
    try {
      const newRule = {
        type: 'keyword' as const,
        name: 'New Rule',
        description: '',
        enabled: true,
        severity: 'medium' as const,
        action: 'flag' as const,
        keywords: []
      };
      
      await createModerationRule(newRule);
      loadRules();
    } catch (error) {
      console.error('Error creating rule:', error);
    }
  };

  const handleUpdateRule = async (ruleId: string, updates: Partial<ContentModerationRule>) => {
    try {
      await updateModerationRule(ruleId, updates);
      loadRules();
    } catch (error) {
      console.error('Error updating rule:', error);
    }
  };

  const getSeverityColor = (severity: string) => {
    switch (severity) {
      case 'high':
        return 'text-red-500';
      case 'medium':
        return 'text-yellow-500';
      case 'low':
        return 'text-green-500';
      default:
        return 'text-gray-500';
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <RoleGuard
      requiredPermission={PERMISSIONS.MANAGE_CONTENT.id}
      fallback={
        <div className="text-center py-12">
          <h2 className="text-2xl font-semibold text-white">Access Denied</h2>
          <p className="text-gray-400 mt-2">
            You don't have permission to manage content moderation.
          </p>
        </div>
      }
    >
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold text-white">Content Moderation</h1>
          <button
            onClick={handleCreateRule}
            className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            <Plus className="w-4 h-4 mr-2" />
            Create Rule
          </button>
        </div>

        {error && (
          <div className="mb-4 p-4 bg-red-500/10 border border-red-500 rounded text-red-500">
            {error}
          </div>
        )}

        <div className="grid gap-6">
          {rules.map((rule) => (
            <div
              key={rule.id}
              className="bg-gray-800 rounded-lg p-6"
            >
              <div className="flex items-start justify-between mb-4">
                <div className="flex items-center">
                  <Shield className={`w-5 h-5 mr-3 ${getSeverityColor(rule.severity)}`} />
                  <div>
                    <h3 className="text-lg font-semibold text-white">{rule.name}</h3>
                    <p className="text-gray-400 text-sm">{rule.description}</p>
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  <button
                    onClick={() => handleUpdateRule(rule.id, { enabled: !rule.enabled })}
                    className={`p-2 rounded-md ${
                      rule.enabled ? 'bg-green-500/10 text-green-500' : 'bg-gray-700 text-gray-400'
                    }`}
                  >
                    {rule.enabled ? <Check className="w-4 h-4" /> : <X className="w-4 h-4" />}
                  </button>
                  <button
                    onClick={() => setEditingRule(rule)}
                    className="p-2 text-gray-400 hover:text-white"
                  >
                    Edit
                  </button>
                </div>
              </div>

              <div className="grid grid-cols-3 gap-4 text-sm">
                <div>
                  <span className="text-gray-400">Type:</span>
                  <span className="ml-2 text-white capitalize">{rule.type}</span>
                </div>
                <div>
                  <span className="text-gray-400">Severity:</span>
                  <span className={`ml-2 capitalize ${getSeverityColor(rule.severity)}`}>
                    {rule.severity}
                  </span>
                </div>
                <div>
                  <span className="text-gray-400">Action:</span>
                  <span className="ml-2 text-white capitalize">{rule.action}</span>
                </div>
              </div>

              {rule.type === 'keyword' && rule.keywords && (
                <div className="mt-4">
                  <span className="text-gray-400 text-sm">Keywords:</span>
                  <div className="flex flex-wrap gap-2 mt-2">
                    {rule.keywords.map((keyword, index) => (
                      <span
                        key={index}
                        className="px-2 py-1 bg-gray-700 text-gray-300 rounded text-sm"
                      >
                        {keyword}
                      </span>
                    ))}
                  </div>
                </div>
              )}

              {rule.type === 'pattern' && rule.pattern && (
                <div className="mt-4">
                  <span className="text-gray-400 text-sm">Pattern:</span>
                  <code className="block mt-2 p-2 bg-gray-900 rounded text-gray-300 font-mono text-sm">
                    {rule.pattern}
                  </code>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </RoleGuard>
  );
}
