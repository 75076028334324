import { create } from 'zustand';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuthStore } from './auth';

interface DeviceStreamState {
  streamKey: string | null;
  isLoading: boolean;
  error: string | null;
  fetchStreamKey: () => Promise<string | null>;
  createStreamKey: () => Promise<string>;
}

// Function to generate a simple 8-character random string
const generateStreamKey = () => {
  const chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
  const letters = chars.slice(0, 26); // first 26 characters are letters
  let result = letters.charAt(Math.floor(Math.random() * letters.length)); // First character is always a letter
  for (let i = 1; i < 8; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
};

// Function to check if a stream key is in the old format
const isOldStreamKeyFormat = (key: string) => {
  return key.includes('_');
};

export const useDeviceStreamStore = create<DeviceStreamState>((set, get) => ({
  streamKey: null,
  isLoading: false,
  error: null,

  fetchStreamKey: async () => {
    const user = useAuthStore.getState().user;
    if (!user?.id) return null;

    set({ isLoading: true, error: null });
    try {
      const userDoc = await getDoc(doc(db, 'users', user.id));
      const userData = userDoc.data();
      const streamKey = userData?.deviceStreamKey || null;

      set({ streamKey });
      return streamKey;
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Failed to fetch stream key';
      set({ error: message });
      return null;
    } finally {
      set({ isLoading: false });
    }
  },

  createStreamKey: async () => {
    const user = useAuthStore.getState().user;
    if (!user?.id) throw new Error('User not authenticated');

    set({ isLoading: true, error: null });
    try {
      // Get the existing stream key from the rtmpUrl
      const userDoc = await getDoc(doc(db, 'users', user.id));
      const userData = userDoc.data();
      const rtmpUrl = userData?.rtmpUrl;
      
      if (!rtmpUrl) {
        throw new Error('No RTMP URL found for user');
      }

      // Extract stream key from rtmpUrl (format: rtmp://live.surgi-cloud.com/live/[key])
      const urlParts = rtmpUrl.split('/');
      const streamKey = urlParts[urlParts.length - 1];

      // Save the stream key to the user's document
      await setDoc(doc(db, 'users', user.id), {
        deviceStreamKey: streamKey
      }, { merge: true });

      // Create a stream document
      await setDoc(doc(db, 'streams', streamKey), {
        userId: user.id,
        streamKey,
        isActive: false,
        createdAt: new Date(),
        updatedAt: new Date(),
        type: 'device'
      });

      set({ streamKey });
      return streamKey;
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Failed to create stream key';
      set({ error: message });
      throw error;
    } finally {
      set({ isLoading: false });
    }
  }
}));
