import { 
  collection, 
  doc, 
  getDoc, 
  getDocs, 
  query, 
  where, 
  runTransaction,
  Transaction,
  addDoc,
  setDoc
} from 'firebase/firestore';
import { db } from '../lib/firebase';
import { 
  joinGroup as joinGroupOp,
  leaveGroup as leaveGroupOp,
  removeGroupMember as removeGroupMemberOp,
  getGroupMembers,
  updateGroupSettings,
  updateMemberRole as updateMemberRoleOp,
  inviteMember as inviteMemberOp,
  checkGroupPermissions
} from '../lib/group';
import { invitationManager } from '../lib/invitations/index';
import type { GroupState, Group, GroupJoinRequest, GroupMember, GroupInvitation } from '../types/group';
import { create } from 'zustand';
import { useAuthStore } from './auth';
import { useNotificationStore } from './notification';
import { logger } from '../lib/utils/logger';
import { Timestamp } from 'firebase/firestore';

// Helper function to convert Timestamp to Date
function timestampToDate(timestamp: Timestamp): Date {
  return timestamp.toDate();
}

// Helper function to handle errors
const handleError = (error: unknown): Error => {
  if (error instanceof Error) return error;
  return new Error(String(error));
};

// Helper function to handle Firebase errors
const handleFirebaseError = (error: Error): void => {
  logger.error('Firebase error:', error);
  // Add specific Firebase error handling if needed
};

// Helper function to get user ID from AuthStore user
const getUserId = (user: any): string => user.id;

// Helper function to get user display name from AuthStore user
const getUserName = (user: any): string => user.name || 'Unknown User';

// Helper function to create default group settings
const getDefaultGroupSettings = (isPrivate: boolean) => ({
  isPrivate,
  joinRequiresApproval: isPrivate,
});

// Helper function to handle errors in catch blocks
const handleCatchError = (error: unknown, message: string): Error => {
  const err = handleError(error);
  logger.error(message, err);
  handleFirebaseError(err);
  return err;
};

export const useGroupStore = create<GroupState>((set, get) => ({
  groups: [],
  currentGroup: null,
  isLoading: false,
  error: null,

  async createGroup(groupData: Partial<Group>) {
    set({ isLoading: true, error: null });
    try {
      const currentUser = useAuthStore.getState().user;
      if (!currentUser?.id) {
        throw new Error('User not authenticated');
      }

      const timestamp = new Date();
      const isPrivate = groupData.isPrivate ?? false;

      // Create the group document first to get the ID
      const groupsRef = collection(db, 'groups');
      const groupDocRef = await addDoc(groupsRef, {
        name: groupData.name || 'New Group',
        description: groupData.description || '',
        ownerId: currentUser.id,
        memberCount: 1,
        isPrivate,
        createdAt: timestamp,
        updatedAt: timestamp,
        settings: getDefaultGroupSettings(isPrivate),
        streams: [],
      });

      // Create the member document
      const memberDocId = `${currentUser.id}_${groupDocRef.id}`;
      const memberRef = doc(db, 'groupMembers', memberDocId);
      const memberData: GroupMember = {
        id: memberDocId,
        userId: currentUser.id,
        userName: currentUser.name || 'Unknown User',
        role: 'owner',
        joinedAt: timestamp.toISOString(),
      };

      await setDoc(memberRef, {
        ...memberData,
        groupId: groupDocRef.id,
      });

      // Now create the complete group object with the member
      const newGroup: Group = {
        id: groupDocRef.id,
        name: groupData.name || 'New Group',
        description: groupData.description || '',
        ownerId: currentUser.id,
        memberCount: 1,
        isPrivate,
        createdAt: timestamp,
        updatedAt: timestamp,
        settings: getDefaultGroupSettings(isPrivate),
        streams: [],
        members: [memberData],
      };

      set((state) => ({ 
        groups: [newGroup, ...state.groups],
        currentGroup: newGroup,
      }));

      logger.info('Successfully created group', { groupId: groupDocRef.id });
      return newGroup;
    } catch (error) {
      const err = handleCatchError(error, 'Failed to create group');
      set({ isLoading: false, error: 'Failed to create group' });
      throw err;
    } finally {
      set({ isLoading: false });
    }
  },

  async fetchGroups() {
    set({ isLoading: true, error: null });
    try {
      const authUser = useAuthStore.getState().user;
      if (!authUser?.id) {
        set({ groups: [], error: 'User not authenticated' });
        return;
      }

      // Get user's group memberships
      const membershipQuery = query(
        collection(db, 'groupMembers'),
        where('userId', '==', authUser.id)
      );
      const memberships = await getDocs(membershipQuery);
      const groupIds = memberships.docs.map(doc => doc.data().groupId);
      
      // Get all groups
      const groupsSnapshot = await getDocs(collection(db, 'groups'));
      
      // Process groups respecting privacy
      const groups: Group[] = [];
      const seenGroupIds = new Set<string>();

      // Add member groups first
      for (const groupId of groupIds) {
        const groupDoc = groupsSnapshot.docs.find(doc => doc.id === groupId);
        if (groupDoc) {
          groups.push({
            id: groupDoc.id,
            ...groupDoc.data(),
          } as Group);
          seenGroupIds.add(groupDoc.id);
        }
      }

      // Add public groups that user is not a member of
      groupsSnapshot.forEach(doc => {
        if (!seenGroupIds.has(doc.id)) {
          const data = doc.data();
          const isPrivate = data.isPrivate || data.settings?.isPrivate;
          
          // Only add if group is public
          if (!isPrivate) {
            groups.push({
              id: doc.id,
              ...data,
            } as Group);
          }
        }
      });

      set({ groups });
    } catch (error) {
      const err = handleCatchError(error, 'Failed to fetch groups');
      set({ error: 'Failed to fetch groups' });
      throw err;
    } finally {
      set({ isLoading: false });
    }
  },

  async fetchGroup(groupId: string) {
    set({ isLoading: true, error: null });
    try {
      const groupDoc = await getDoc(doc(db, 'groups', groupId));
      if (!groupDoc.exists()) {
        throw new Error('Group not found');
      }

      const groupData = groupDoc.data();
      const members = await getGroupMembers(groupId);

      const group: Group = {
        id: groupDoc.id,
        ...groupData,
        members,
      } as Group;

      set({ currentGroup: group });
      return group;
    } catch (error) {
      const err = handleCatchError(error, 'Failed to fetch group');
      set({ error: 'Failed to fetch group' });
      throw err;
    } finally {
      set({ isLoading: false });
    }
  },

  async joinGroup(groupId: string) {
    set({ isLoading: true, error: null });
    try {
      const currentUser = useAuthStore.getState().user;
      if (!currentUser?.id) {
        throw new Error('User not authenticated');
      }

      await joinGroupOp(groupId, currentUser.id, currentUser.name);
      await get().fetchGroup(groupId);
    } catch (error) {
      const err = handleCatchError(error, 'Failed to join group');
      set({ isLoading: false, error: 'Failed to join group' });
      throw err;
    } finally {
      set({ isLoading: false });
    }
  },

  async leaveGroup(groupId: string) {
    set({ isLoading: true, error: null });
    try {
      const currentUser = useAuthStore.getState().user;
      if (!currentUser?.id) {
        throw new Error('User not authenticated');
      }

      await leaveGroupOp(groupId, currentUser.id);
      set(state => ({
        groups: state.groups.filter(g => g.id !== groupId),
        currentGroup: state.currentGroup?.id === groupId ? null : state.currentGroup,
      }));
    } catch (error) {
      const err = handleCatchError(error, 'Failed to leave group');
      set({ isLoading: false, error: 'Failed to leave group' });
      throw err;
    } finally {
      set({ isLoading: false });
    }
  },

  async updateGroup(groupId: string, groupData: Partial<Group>) {
    set({ isLoading: true, error: null });
    try {
      await updateGroupSettings(groupId, groupData);
      await get().fetchGroup(groupId);
    } catch (error) {
      const err = handleCatchError(error, 'Failed to update group');
      set({ error: 'Failed to update group' });
      throw err;
    } finally {
      set({ isLoading: false });
    }
  },

  async addMember(groupId: string, userId: string, role: GroupMember['role'] = 'member') {
    set({ isLoading: true, error: null });
    try {
      await runTransaction(db, async (transaction: Transaction) => {
        // Add member to group
        const memberDocId = `${userId}_${groupId}`;
        const memberRef = doc(db, 'groupMembers', memberDocId);
        const memberData = {
          userId,
          groupId,
          role,
          joinedAt: new Date(),
        };
        transaction.set(memberRef, memberData);

        // Update group member count
        const groupRef = doc(db, 'groups', groupId);
        const groupDoc = await transaction.get(groupRef);
        if (!groupDoc.exists()) {
          throw new Error('Group not found');
        }
        const currentCount = groupDoc.data().memberCount || 0;
        transaction.update(groupRef, { memberCount: currentCount + 1 });
      });

      // Add notification for new member
      useNotificationStore.getState().addNotification({
        id: `member_added_${userId}_${groupId}_${Date.now()}`,
        type: 'memberAdded',
        title: 'New Member Added',
        message: `A new member has been added to the group`,
        read: false,
        createdAt: new Date(),
      });

      await get().fetchGroup(groupId);
    } catch (error) {
      const err = handleCatchError(error, 'Failed to add member');
      set({ error: 'Failed to add member' });
      throw err;
    } finally {
      set({ isLoading: false });
    }
  },

  async removeMember(groupId: string, userId: string) {
    set({ isLoading: true, error: null });
    try {
      const adminId = useAuthStore.getState().user?.id;
      if (!adminId) throw new Error('User not authenticated');

      await removeGroupMemberOp(groupId, userId, adminId);
      await get().fetchGroup(groupId);
    } catch (error) {
      const err = handleCatchError(error, 'Failed to remove member');
      set({ error: 'Failed to remove member' });
      throw err;
    } finally {
      set({ isLoading: false });
    }
  },

  async updateMemberRole(groupId: string, userId: string, newRole: string) {
    set({ isLoading: true, error: null });
    try {
      await updateMemberRoleOp(groupId, userId, newRole);
      await get().fetchGroup(groupId);
    } catch (error) {
      const err = handleCatchError(error, 'Failed to update member role');
      set({ error: 'Failed to update member role' });
      throw err;
    } finally {
      set({ isLoading: false });
    }
  },

  async inviteMember(groupId: string, email: string) {
    set({ isLoading: true, error: null });
    try {
      const user = useAuthStore.getState().user;
      if (!user?.id) {
        throw new Error('User not authenticated');
      }

      await inviteMemberOp(groupId, email, user.id);
    } catch (error) {
      const err = handleCatchError(error, 'Failed to invite member');
      set({ error: 'Failed to invite member' });
      throw err;
    } finally {
      set({ isLoading: false });
    }
  },

  async sendInvitation(groupId: string, email: string) {
    set({ isLoading: true, error: null });
    try {
      const currentUser = useAuthStore.getState().user;
      if (!currentUser?.id) {
        throw new Error('User not authenticated');
      }

      // Check if user has permission to send invitations
      const permissions = await checkGroupPermissions(groupId, currentUser.id);
      if (!permissions.canManageGroup) {
        throw new Error('You do not have permission to send invitations in this group');
      }

      // Get group data to verify it exists
      const groupDoc = await getDoc(doc(db, 'groups', groupId));
      if (!groupDoc.exists()) {
        throw new Error('Group not found');
      }

      logger.debug('Sending invitation', {
        groupId,
        email,
        currentUser: currentUser.id,
        permissions
      });

      await invitationManager.sendBatchInvitations(
        groupId,
        [email],
        currentUser.id,
        currentUser.name || 'Unknown User'
      );

      useNotificationStore.getState().addNotification({
        id: `invitation_sent_${groupId}_${Date.now()}`,
        type: 'info',
        title: 'Invitation Sent',
        message: `Invitation sent to ${email}`,
        read: false,
        createdAt: new Date(),
      });

      set({ isLoading: false });
    } catch (error) {
      const err = error instanceof Error ? error : new Error(String(error));
      logger.error('Failed to send invitation', err, {
        groupId,
        email,
        currentUser: useAuthStore.getState().user?.id
      });
      set({ isLoading: false, error: err.message });
      throw err;
    }
  },

  async acceptInvitation(invitationId: string) {
    set({ isLoading: true, error: null });
    try {
      await invitationManager.acceptInvitation(invitationId);

      useNotificationStore.getState().addNotification({
        id: `invitation_accepted_${invitationId}_${Date.now()}`,
        type: 'info',
        title: 'Invitation Accepted',
        message: 'You have successfully joined the group',
        read: false,
        createdAt: new Date(),
      });

      set({ isLoading: false });
    } catch (error) {
      const err = handleCatchError(error, 'Failed to accept invitation');
      set({ isLoading: false, error: 'Failed to accept invitation' });
      throw err;
    }
  },

  async declineInvitation(invitationId: string) {
    set({ isLoading: true, error: null });
    try {
      await invitationManager.declineInvitation(invitationId);

      useNotificationStore.getState().addNotification({
        id: `invitation_declined_${invitationId}_${Date.now()}`,
        type: 'info',
        title: 'Invitation Declined',
        message: 'You have declined the group invitation',
        read: false,
        createdAt: new Date(),
      });

      set({ isLoading: false });
    } catch (error) {
      const err = handleCatchError(error, 'Failed to decline invitation');
      set({ isLoading: false, error: 'Failed to decline invitation' });
      throw err;
    }
  },

  async getUserInvitations() {
    set({ isLoading: true, error: null });
    try {
      const currentUser = useAuthStore.getState().user;
      if (!currentUser?.id) {
        throw new Error('User not authenticated');
      }

      const invitations = await invitationManager.getInvitationsByEmail(currentUser.email);
      const groupInvitations: GroupInvitation[] = invitations.map(inv => ({
        id: inv.id,
        groupId: inv.groupId,
        invitedEmail: inv.email,
        invitedBy: inv.invitedBy,
        status: inv.status,
        createdAt: timestampToDate(inv.createdAt),
        updatedAt: timestampToDate(inv.updatedAt),
      }));

      set({ isLoading: false });
      return groupInvitations;
    } catch (error) {
      const err = handleCatchError(error, 'Failed to get user invitations');
      set({ isLoading: false, error: 'Failed to get user invitations' });
      throw err;
    }
  },

  async getGroupInvitations(groupId: string) {
    set({ isLoading: true, error: null });
    try {
      const invitations = await invitationManager.getInvitationsBySender(groupId);
      const groupInvitations: GroupInvitation[] = invitations.map(inv => ({
        id: inv.id,
        groupId: inv.groupId,
        invitedEmail: inv.email,
        invitedBy: inv.invitedBy,
        status: inv.status,
        createdAt: timestampToDate(inv.createdAt),
        updatedAt: timestampToDate(inv.updatedAt),
      }));

      set({ isLoading: false });
      return groupInvitations;
    } catch (error) {
      const err = handleCatchError(error, 'Failed to get group invitations');
      set({ isLoading: false, error: 'Failed to get group invitations' });
      throw err;
    }
  },

  async requestToJoin(groupId: string, message?: string) {
    set({ isLoading: true, error: null });
    try {
      const currentUser = useAuthStore.getState().user;
      if (!currentUser?.id) {
        throw new Error('User not authenticated');
      }

      const joinRequestId = `${currentUser.id}_${groupId}`;
      const requestRef = doc(db, 'groupJoinRequests', joinRequestId);
      const requestDoc = await getDoc(requestRef);

      if (requestDoc.exists()) {
        throw new Error('Join request already exists');
      }

      await runTransaction(db, async (transaction) => {
        const groupRef = doc(db, 'groups', groupId);
        const groupDoc = await transaction.get(groupRef);
        if (!groupDoc.exists()) throw new Error('Group not found');

        transaction.set(requestRef, {
          groupId,
          userId: currentUser.id,
          userName: currentUser.name,
          status: 'pending',
          message: message || '',
          createdAt: new Date(),
          updatedAt: new Date(),
        });
      });

      useNotificationStore.getState().addNotification({
        id: `join_request_sent_${groupId}_${Date.now()}`,
        type: 'info',
        title: 'Join Request Sent',
        message: 'Your request to join the group has been sent',
        read: false,
        createdAt: new Date(),
      });

      set({ isLoading: false });
    } catch (error) {
      const err = handleCatchError(error, 'Failed to send join request');
      set({ isLoading: false, error: 'Failed to send join request' });
      throw err;
    }
  },

  async acceptJoinRequest(requestId: string) {
    set({ isLoading: true, error: null });
    try {
      await runTransaction(db, async (transaction) => {
        const requestRef = doc(db, 'groupJoinRequests', requestId);
        const requestDoc = await transaction.get(requestRef);
        if (!requestDoc.exists()) throw new Error('Join request not found');

        const request = requestDoc.data() as GroupJoinRequest;
        await get().addMember(request.groupId, request.userId, 'member');

        transaction.update(requestRef, {
          status: 'accepted',
          updatedAt: new Date(),
        });
      });

      useNotificationStore.getState().addNotification({
        id: `join_request_accepted_${requestId}_${Date.now()}`,
        type: 'info',
        title: 'Join Request Accepted',
        message: 'The join request has been accepted',
        read: false,
        createdAt: new Date(),
      });

      set({ isLoading: false });
    } catch (error) {
      const err = handleCatchError(error, 'Failed to accept join request');
      set({ isLoading: false, error: 'Failed to accept join request' });
      throw err;
    }
  },

  async declineJoinRequest(requestId: string) {
    set({ isLoading: true, error: null });
    try {
      await runTransaction(db, async (transaction) => {
        const requestRef = doc(db, 'groupJoinRequests', requestId);
        const requestDoc = await transaction.get(requestRef);
        if (!requestDoc.exists()) throw new Error('Join request not found');

        transaction.update(requestRef, {
          status: 'declined',
          updatedAt: new Date(),
        });
      });

      useNotificationStore.getState().addNotification({
        id: `join_request_declined_${requestId}_${Date.now()}`,
        type: 'info',
        title: 'Join Request Declined',
        message: 'The join request has been declined',
        read: false,
        createdAt: new Date(),
      });

      set({ isLoading: false });
    } catch (error) {
      const err = handleCatchError(error, 'Failed to decline join request');
      set({ isLoading: false, error: 'Failed to decline join request' });
      throw err;
    }
  },

  async getGroupJoinRequests(groupId: string) {
    set({ isLoading: true, error: null });
    try {
      const requestsRef = collection(db, 'groupJoinRequests');
      const q = query(requestsRef, where('groupId', '==', groupId));
      const querySnapshot = await getDocs(q);
      
      const requests: GroupJoinRequest[] = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        requests.push({
          id: doc.id,
          groupId: data.groupId,
          userId: data.userId,
          userName: data.userName,
          status: data.status,
          message: data.message,
          createdAt: timestampToDate(data.createdAt),
          updatedAt: timestampToDate(data.updatedAt),
        });
      });

      set({ isLoading: false });
      return requests;
    } catch (error) {
      const err = handleCatchError(error, 'Failed to get group join requests');
      set({ isLoading: false, error: 'Failed to get group join requests' });
      throw err;
    }
  },

  async getUserJoinRequests() {
    set({ isLoading: true, error: null });
    try {
      const currentUser = useAuthStore.getState().user;
      if (!currentUser?.id) {
        throw new Error('User not authenticated');
      }

      const requestsRef = collection(db, 'groupJoinRequests');
      const q = query(requestsRef, where('userId', '==', currentUser.id));
      const querySnapshot = await getDocs(q);
      
      const requests: GroupJoinRequest[] = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        requests.push({
          id: doc.id,
          groupId: data.groupId,
          userId: data.userId,
          userName: data.userName,
          status: data.status,
          message: data.message,
          createdAt: timestampToDate(data.createdAt),
          updatedAt: timestampToDate(data.updatedAt),
        });
      });

      set({ isLoading: false });
      return requests;
    } catch (error) {
      const err = handleCatchError(error, 'Failed to get user join requests');
      set({ isLoading: false, error: 'Failed to get user join requests' });
      throw err;
    }
  },
}));