import React from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Button } from '../components/ui/Button';
import {
  Shield,
  Lock,
  Key,
  UserCheck,
  FileCheck,
  Server,
  RefreshCcw,
  AlertTriangle,
  Database,
  Eye,
  Fingerprint,
  History
} from 'lucide-react';

const securityFeatures = [
  {
    title: 'End-to-End Encryption',
    description: 'All data is encrypted in transit and at rest using industry-standard AES-256 encryption.',
    icon: Lock
  },
  {
    title: 'HIPAA Compliance',
    description: 'Our platform is fully HIPAA-compliant, ensuring the security and privacy of patient information.',
    icon: Shield
  },
  {
    title: 'Access Control',
    description: 'Role-based access control with granular permissions and multi-factor authentication.',
    icon: Key
  },
  {
    title: 'User Authentication',
    description: 'Secure user authentication with optional SSO integration and password policies.',
    icon: UserCheck
  },
  {
    title: 'Data Integrity',
    description: 'Checksum verification and file integrity monitoring for all stored content.',
    icon: FileCheck
  },
  {
    title: 'Secure Infrastructure',
    description: 'SOC 2 Type II certified infrastructure with regular security audits.',
    icon: Server
  },
  {
    title: 'Automatic Backups',
    description: 'Automated backup systems with geographic redundancy for data protection.',
    icon: RefreshCcw
  },
  {
    title: 'Incident Response',
    description: '24/7 security monitoring with automated threat detection and response.',
    icon: AlertTriangle
  },
  {
    title: 'Data Protection',
    description: 'Advanced data protection measures including encryption at rest and in transit.',
    icon: Database
  },
  {
    title: 'Activity Monitoring',
    description: 'Comprehensive audit logging and activity monitoring for all system actions.',
    icon: Eye
  },
  {
    title: 'Access Policies',
    description: 'Configurable access policies and IP whitelisting capabilities.',
    icon: Fingerprint
  },
  {
    title: 'Audit Trails',
    description: 'Detailed audit trails for all user actions and system changes.',
    icon: History
  }
];

const SecurityPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Security | SVIZ.live - Advanced Surgical Video Platform</title>
        <meta name="description" content="Learn about SVIZ.live's comprehensive security measures including HIPAA compliance, end-to-end encryption, and advanced access controls." />
        <meta name="keywords" content="HIPAA compliant video platform, secure medical streaming, healthcare data security, encrypted video streaming" />
        <meta property="og:title" content="Security | SVIZ.live - Advanced Surgical Video Platform" />
        <meta property="og:description" content="Discover how SVIZ.live ensures the security and privacy of your medical video content with industry-leading security measures." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://sviz.live/security" />
      </Helmet>

      <div className="min-h-screen bg-background">
        {/* Navigation */}
        <nav className="fixed top-0 left-0 right-0 z-50 bg-background/80 backdrop-blur-lg border-b border-border">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between items-center h-16">
              <Link to="/" className="text-2xl font-bold bg-gradient-to-r from-primary to-blue-600 bg-clip-text text-transparent">
                SVIZ.live
              </Link>
              <Button variant="outline" size="sm" asChild>
                <Link to="/">Back to Home</Link>
              </Button>
            </div>
          </div>
        </nav>

        {/* Hero Section */}
        <motion.section
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="pt-20 pb-16 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto"
        >
          <div className="text-center">
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="text-4xl sm:text-5xl lg:text-6xl font-bold bg-gradient-to-r from-primary to-blue-600 bg-clip-text text-transparent"
            >
              Enterprise-Grade Security
              <br />
              for Healthcare
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
              className="mt-6 text-xl text-text-secondary max-w-3xl mx-auto"
            >
              Your security is our top priority. Our platform is built with multiple layers of protection to ensure your data remains safe and compliant.
            </motion.p>
          </div>
        </motion.section>

        {/* Security Features Grid */}
        <motion.section
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
          className="py-16 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto"
        >
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {securityFeatures.map((feature, index) => (
              <motion.div
                key={feature.title}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.1 * index }}
                className="p-6 rounded-2xl bg-background-secondary border border-border hover:border-primary/50 transition-colors"
              >
                <feature.icon className="w-10 h-10 text-primary mb-4" />
                <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                <p className="text-text-secondary">{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </motion.section>

        {/* Compliance Section */}
        <motion.section
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6 }}
          className="py-16 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto"
        >
          <div className="text-center max-w-3xl mx-auto">
            <h2 className="text-3xl font-bold mb-6">Compliance & Certifications</h2>
            <p className="text-text-secondary mb-8">
              SVIZ.live maintains the highest standards of security and compliance in the healthcare industry.
            </p>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
              <div className="p-4 rounded-lg bg-background-secondary border border-border">
                <h3 className="font-semibold">HIPAA</h3>
                <p className="text-sm text-text-secondary">Compliant</p>
              </div>
              <div className="p-4 rounded-lg bg-background-secondary border border-border">
                <h3 className="font-semibold">SOC 2</h3>
                <p className="text-sm text-text-secondary">Type II</p>
              </div>
              <div className="p-4 rounded-lg bg-background-secondary border border-border">
                <h3 className="font-semibold">GDPR</h3>
                <p className="text-sm text-text-secondary">Compliant</p>
              </div>
              <div className="p-4 rounded-lg bg-background-secondary border border-border">
                <h3 className="font-semibold">ISO 27001</h3>
                <p className="text-sm text-text-secondary">Certified</p>
              </div>
            </div>
          </div>
        </motion.section>

        {/* CTA Section */}
        <motion.section
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.8 }}
          className="py-16 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto text-center"
        >
          <h2 className="text-3xl sm:text-4xl font-bold mb-8">Ready to Secure Your Medical Content?</h2>
          <div className="flex flex-col sm:flex-row gap-4 justify-center mt-8">
            <Button size="lg">
              <Link to="/demo">Get Started</Link>
            </Button>
            <Button variant="outline" size="lg">
              <Link to="/contact">Contact Sales</Link>
            </Button>
          </div>
        </motion.section>

        {/* Footer */}
        <footer className="bg-background-secondary border-t border-border">
          <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
            <div className="text-center">
              <Link to="/" className="text-2xl font-bold bg-gradient-to-r from-primary to-blue-600 bg-clip-text text-transparent">
                SVIZ.live
              </Link>
              <p className="mt-4 text-text-secondary">
                Advanced surgical video platform for medical professionals.
              </p>
              <div className="mt-8">
                <Button variant="outline" size="sm" asChild>
                  <Link to="/">Back to Home</Link>
                </Button>
              </div>
            </div>
            <div className="mt-8 pt-8 border-t border-border">
              <p className="text-center text-text-secondary">
                {new Date().getFullYear()} SVIZ.live. All rights reserved.
              </p>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default SecurityPage;
