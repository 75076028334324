import { useCallback, useState } from 'react';
import { Users, Shield, Lock, Globe, LogOut } from 'lucide-react';
import { Button } from '../ui/Button';
import { useGroup } from '../../hooks/useGroup';
import { useGroupStore } from '../../store/group';
import { Input } from '../ui/Input';
import type { Group, GroupMember } from '../../types/group';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '../ui/Dialog';

interface GroupHeaderProps {
  group: Group;
}

export function GroupHeader({ group }: GroupHeaderProps) {
  const { 
    isLoading,
    error,
    isMember,
    isOwner,
    leaveGroup,
    joinGroup,
  } = useGroup(group.id);

  const { requestToJoin } = useGroupStore();
  const [joinMessage, setJoinMessage] = useState('');
  const [showJoinMessage, setShowJoinMessage] = useState(false);
  const [showLeaveDialog, setShowLeaveDialog] = useState(false);

  const handleMembership = useCallback(async () => {
    try {
      const ownerStatus = await isOwner();
      if (isMember() && !ownerStatus) {
        setShowLeaveDialog(true);
      } else if (group.settings?.joinRequiresApproval) {
        setShowJoinMessage(true);
      } else {
        await joinGroup();
      }
    } catch (error) {
      console.error('Failed to handle membership:', error);
    }
  }, [isMember, isOwner, joinGroup, group.settings?.joinRequiresApproval]);

  const handleLeaveGroup = async () => {
    try {
      await leaveGroup();
      setShowLeaveDialog(false);
    } catch (error) {
      console.error('Failed to leave group:', error);
    }
  };

  const handleJoinRequest = async () => {
    try {
      await requestToJoin(group.id, joinMessage);
      setShowJoinMessage(false);
      setJoinMessage('');
    } catch (error) {
      console.error('Failed to send join request:', error);
    }
  };

  return (
    <div className="bg-gradient-to-br from-gray-800/50 to-gray-900/50 rounded-xl border border-gray-700/50 backdrop-blur-sm p-8">
      <div className="flex items-start justify-between">
        <div className="flex-1">
          <div className="flex items-center space-x-3">
            <h1 className="text-3xl font-light tracking-wide text-white">{group.name}</h1>
            {group.settings?.isPrivate ? (
              <Lock className="h-5 w-5 text-gray-400" />
            ) : (
              <Globe className="h-5 w-5 text-gray-400" />
            )}
          </div>
          <p className="mt-4 text-gray-300 font-light">{group.description}</p>
          
          <div className="mt-6 flex items-center space-x-6">
            <div className="flex items-center text-gray-400">
              <Users className="h-5 w-5 mr-2" />
              <span className="font-light">{group.memberCount} members</span>
            </div>
            {group.members?.some((member: GroupMember) => member.role === 'admin') && (
              <div className="flex items-center text-gray-400">
                <Shield className="h-5 w-5 mr-2" />
                <span className="font-light">Moderated</span>
              </div>
            )}
          </div>

          {error && (
            <div className="mt-4 p-3 rounded-md bg-red-50/10 border border-red-200/20">
              <p className="text-sm text-red-400">{error}</p>
            </div>
          )}
        </div>
        
        <div className="flex flex-col items-end space-y-2">
          {showJoinMessage ? (
            <div className="w-64">
              <Input
                type="text"
                placeholder="Add a message (optional)"
                value={joinMessage}
                onChange={(e) => setJoinMessage(e.target.value)}
                className="mb-2"
              />
              <div className="flex justify-end space-x-2">
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => setShowJoinMessage(false)}
                  className="border-gray-600 text-gray-300"
                >
                  Cancel
                </Button>
                <Button
                  size="sm"
                  onClick={handleJoinRequest}
                  isLoading={isLoading}
                  disabled={isLoading}
                  className="bg-blue-600 hover:bg-blue-700"
                >
                  Send Request
                </Button>
              </div>
            </div>
          ) : (
            <>
              {isMember() ? (
                <Button
                  onClick={handleMembership}
                  variant="outline"
                  className="border-red-600/30 hover:border-red-600 text-red-400 hover:text-red-300 flex items-center space-x-2"
                  isLoading={isLoading}
                  disabled={isLoading}
                >
                  <LogOut className="h-4 w-4" />
                  <span>Leave Group</span>
                </Button>
              ) : (
                <Button
                  onClick={handleMembership}
                  variant="primary"
                  className="bg-blue-600 hover:bg-blue-700"
                  isLoading={isLoading}
                  disabled={isLoading}
                >
                  {group.settings?.joinRequiresApproval ? 'Request to Join' : 'Join Group'}
                </Button>
              )}
            </>
          )}
        </div>
      </div>

      <Dialog open={showLeaveDialog} onOpenChange={setShowLeaveDialog}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Leave Group</DialogTitle>
            <DialogDescription>
              Are you sure you want to leave {group.name}? You'll need to request to join again if you want to come back.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setShowLeaveDialog(false)}
              className="border-gray-600 text-gray-300"
            >
              Cancel
            </Button>
            <Button
              variant="destructive"
              onClick={handleLeaveGroup}
              isLoading={isLoading}
              disabled={isLoading}
              className="bg-red-600 hover:bg-red-700"
            >
              Leave Group
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}