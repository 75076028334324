import { collection, doc, getDoc, getDocs, query, where, addDoc, updateDoc, deleteDoc, orderBy, limit } from 'firebase/firestore';
import { db } from '../lib/firebase';
import type { Group, AuditLogEntry, ContentModerationRule, AnalyticsData, Report } from '../types/admin';

// Groups Management
export const createGroup = async (group: Omit<Group, 'id' | 'createdAt' | 'updatedAt'>): Promise<string> => {
  const now = new Date().toISOString();
  const groupData = {
    ...group,
    createdAt: now,
    updatedAt: now,
    members: group.members || [],
    admins: group.admins || [],
    settings: {
      isPrivate: group.settings?.isPrivate || false,
      joinRequiresApproval: group.settings?.joinRequiresApproval || false,
      contentModerationLevel: group.settings?.contentModerationLevel || 'low'
    }
  };
  
  const docRef = await addDoc(collection(db, 'groups'), groupData);
  return docRef.id;
};

export const updateGroup = async (groupId: string, updates: Partial<Group>): Promise<void> => {
  const groupRef = doc(db, 'groups', groupId);
  await updateDoc(groupRef, {
    ...updates,
    updatedAt: new Date().toISOString()
  });
};

export const deleteGroup = async (groupId: string): Promise<void> => {
  await deleteDoc(doc(db, 'groups', groupId));
};

export const getGroup = async (groupId: string): Promise<Group | null> => {
  const groupDoc = await getDoc(doc(db, 'groups', groupId));
  if (!groupDoc.exists()) return null;

  const data = groupDoc.data();
  const now = new Date().toISOString();
  
  // Helper function to validate and format date
  const validateDate = (dateStr: string | undefined) => {
    if (!dateStr) return now;
    try {
      const date = new Date(dateStr);
      return isNaN(date.getTime()) ? now : date.toISOString();
    } catch {
      return now;
    }
  };

  return {
    id: groupDoc.id,
    name: data.name || '',
    description: data.description || '',
    createdAt: validateDate(data.createdAt),
    updatedAt: validateDate(data.updatedAt),
    createdBy: data.createdBy || '',
    members: data.members || [],
    admins: data.admins || [],
    settings: {
      isPrivate: data.settings?.isPrivate || false,
      joinRequiresApproval: data.settings?.joinRequiresApproval || false,
      contentModerationLevel: data.settings?.contentModerationLevel || 'low'
    }
  } as Group;
};

export const listGroups = async (): Promise<Group[]> => {
  const groupsSnapshot = await getDocs(collection(db, 'groups'));
  return groupsSnapshot.docs.map(doc => {
    const data = doc.data();
    const now = new Date().toISOString();
    
    // Helper function to validate and format date
    const validateDate = (dateStr: string | undefined) => {
      if (!dateStr) return now;
      try {
        const date = new Date(dateStr);
        return isNaN(date.getTime()) ? now : date.toISOString();
      } catch {
        return now;
      }
    };

    return {
      id: doc.id,
      name: data.name || '',
      description: data.description || '',
      createdAt: validateDate(data.createdAt),
      updatedAt: validateDate(data.updatedAt),
      createdBy: data.createdBy || '',
      members: data.members || [],
      admins: data.admins || [],
      settings: {
        isPrivate: data.settings?.isPrivate || false,
        joinRequiresApproval: data.settings?.joinRequiresApproval || false,
        contentModerationLevel: data.settings?.contentModerationLevel || 'low'
      }
    } as Group;
  });
};

// Audit Logs
export const createAuditLog = async (entry: Omit<AuditLogEntry, 'id' | 'timestamp'>): Promise<void> => {
  const logEntry = {
    ...entry,
    timestamp: new Date().toISOString()
  };
  await addDoc(collection(db, 'audit_logs'), logEntry);
};

export const getAuditLogs = async (
  filters?: Partial<AuditLogEntry>,
  limit?: number
): Promise<AuditLogEntry[]> => {
  let q = query(collection(db, 'audit_logs'), orderBy('timestamp', 'desc'));
  
  if (filters?.userId) {
    q = query(q, where('userId', '==', filters.userId));
  }
  if (filters?.action) {
    q = query(q, where('action', '==', filters.action));
  }
  if (filters?.resourceType) {
    q = query(q, where('resourceType', '==', filters.resourceType));
  }
  if (limit) {
    q = query(q, limit(limit));
  }

  const snapshot = await getDocs(q);
  return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as AuditLogEntry));
};

// Content Moderation
export const createModerationRule = async (
  rule: Omit<ContentModerationRule, 'id' | 'createdAt' | 'updatedAt'>
): Promise<string> => {
  const now = new Date().toISOString();
  const ruleData = {
    ...rule,
    createdAt: now,
    updatedAt: now
  };
  
  const docRef = await addDoc(collection(db, 'moderation_rules'), ruleData);
  return docRef.id;
};

export const updateModerationRule = async (
  ruleId: string,
  updates: Partial<ContentModerationRule>
): Promise<void> => {
  const ruleRef = doc(db, 'moderation_rules', ruleId);
  await updateDoc(ruleRef, {
    ...updates,
    updatedAt: new Date().toISOString()
  });
};

export const getModerationRules = async (): Promise<ContentModerationRule[]> => {
  const snapshot = await getDocs(collection(db, 'moderation_rules'));
  return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as ContentModerationRule));
};

// Analytics
export const getAnalytics = async (timeRange: 'day' | 'week' | 'month'): Promise<AnalyticsData> => {
  const analyticsRef = collection(db, 'analytics');
  const analyticsDoc = await getDoc(doc(analyticsRef, timeRange));
  
  if (!analyticsDoc.exists()) {
    throw new Error('Analytics data not found');
  }
  
  return analyticsDoc.data() as AnalyticsData;
};

export const getReports = async (): Promise<Report[]> => {
  const reportsRef = collection(db, 'reports');
  const q = query(reportsRef, orderBy('createdAt', 'desc'));
  const snapshot = await getDocs(q);
  
  return snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  })) as Report[];
};

export const generateReport = async (type: string): Promise<void> => {
  const reportsRef = collection(db, 'reports');
  const newReport = {
    type,
    name: `${type.charAt(0).toUpperCase() + type.slice(1)} Report`,
    description: `Generated ${type} report`,
    format: 'pdf',
    status: 'pending',
    createdAt: new Date().toISOString(),
  };
  
  await addDoc(reportsRef, newReport);
};

// Reports
export const createReport = async (report: Omit<Report, 'id' | 'createdAt' | 'status'>): Promise<string> => {
  const reportData = {
    ...report,
    status: 'pending',
    createdAt: new Date().toISOString()
  };
  
  const docRef = await addDoc(collection(db, 'reports'), reportData);
  return docRef.id;
};

export const getReport = async (reportId: string): Promise<Report | null> => {
  const reportDoc = await getDoc(doc(db, 'reports', reportId));
  return reportDoc.exists() ? { id: reportDoc.id, ...reportDoc.data() } as Report : null;
};

export const listReports = async (status?: Report['status']): Promise<Report[]> => {
  let q = query(collection(db, 'reports'), orderBy('createdAt', 'desc'));
  
  if (status) {
    q = query(q, where('status', '==', status));
  }
  
  const snapshot = await getDocs(q);
  return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Report));
};
