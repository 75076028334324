import React from 'react';
import { Link } from 'react-router-dom';
import { Plus, Play, Globe, Lock, Users } from 'lucide-react';
import { useStreamStore } from '../store/stream';
import { useAuthStore } from '../store/auth';
import { Button } from '../components/ui/Button';
import { StreamList } from '../components/stream/StreamList';

export function StreamsPage() {
  const { isAuthenticated } = useAuthStore();
  const { streams } = useStreamStore();

  const liveStreams = streams.filter(stream => stream.isLive);
  const upcomingStreams = streams.filter(stream => !stream.isLive);

  return (
    <div className="space-y-8">
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-3xl font-light tracking-wide text-white mb-2">Medical Streams</h1>
          <p className="text-gray-400 font-light">
            Watch live medical education streams and surgical demonstrations
          </p>
        </div>
        {isAuthenticated && (
          <Link to="/stream/create">
            <Button className="flex items-center space-x-2 bg-blue-600 hover:bg-blue-700">
              <Plus className="h-4 w-4" />
              <span>Create Stream</span>
            </Button>
          </Link>
        )}
      </div>

      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        {/* Featured Stream Section */}
        <div className="md:col-span-2 lg:col-span-3">
          <div className="bg-gradient-to-br from-blue-600/20 to-purple-900/30 rounded-xl p-6 border border-blue-500/10 backdrop-blur-sm">
            <h2 className="text-xl font-light text-white/90 mb-4">Featured Streams</h2>
            <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
              {liveStreams.slice(0, 3).map((stream) => (
                <Link
                  key={stream.id}
                  to={`/stream/${stream.id}`}
                  className="bg-white/5 rounded-lg p-4 border border-white/10 hover:border-white/20 transition-all duration-300 group"
                >
                  <div className="relative aspect-video mb-3 overflow-hidden rounded-lg">
                    <img
                      src={stream.thumbnailUrl}
                      alt={stream.title}
                      className="object-cover w-full h-full transform transition-transform duration-300 group-hover:scale-105"
                    />
                    <div className="absolute top-2 left-2">
                      <span className="px-2 py-1 text-xs font-medium rounded-full bg-red-500/90 text-white">
                        LIVE
                      </span>
                    </div>
                    <div className="absolute top-2 right-2">
                      {stream.visibility === 'private' ? (
                        <Lock className="h-4 w-4 text-white/80" />
                      ) : stream.visibility === 'group' ? (
                        <Users className="h-4 w-4 text-white/80" />
                      ) : (
                        <Globe className="h-4 w-4 text-white/80" />
                      )}
                    </div>
                  </div>
                  <h3 className="font-light text-white/90 group-hover:text-blue-300 transition-colors">
                    {stream.title}
                  </h3>
                  <p className="mt-1 text-sm text-white/70 line-clamp-2 font-light">
                    {stream.description}
                  </p>
                  <div className="mt-2 flex items-center justify-between">
                    <span className="text-sm text-white/60 font-light">
                      {stream.creatorName}
                    </span>
                    <span className="text-sm text-white/60 font-light">
                      {stream.viewerCount} viewers
                    </span>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>

        {/* Live Streams */}
        <div className="md:col-span-2 lg:col-span-3">
          <div className="space-y-4">
            <div className="flex items-center space-x-2">
              <Play className="h-5 w-5 text-red-400" />
              <h2 className="text-xl font-light text-white/90">Live Now</h2>
            </div>
            <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
              {liveStreams.map((stream) => (
                <Link
                  key={stream.id}
                  to={`/stream/${stream.id}`}
                  className="bg-gradient-to-br from-gray-800/50 to-gray-900/50 rounded-xl border border-gray-700/50 backdrop-blur-sm p-4 transition-all duration-300 hover:border-gray-600/50 group"
                >
                  <div className="relative aspect-video mb-3 overflow-hidden rounded-lg">
                    <img
                      src={stream.thumbnailUrl}
                      alt={stream.title}
                      className="object-cover w-full h-full transform transition-transform duration-300 group-hover:scale-105"
                    />
                    <div className="absolute top-2 left-2">
                      <span className="px-2 py-1 text-xs font-medium rounded-full bg-red-500/90 text-white">
                        LIVE
                      </span>
                    </div>
                  </div>
                  <h3 className="font-light text-white/90 group-hover:text-blue-300 transition-colors">
                    {stream.title}
                  </h3>
                  <p className="mt-1 text-sm text-white/70 line-clamp-2 font-light">
                    {stream.description}
                  </p>
                  <div className="mt-2 flex items-center justify-between">
                    <span className="text-sm text-white/60 font-light">
                      {stream.creatorName}
                    </span>
                    <div className="flex items-center space-x-2">
                      <span className="text-sm text-white/60 font-light">
                        {stream.viewerCount} viewers
                      </span>
                      {stream.visibility === 'private' ? (
                        <Lock className="h-4 w-4 text-white/60" />
                      ) : stream.visibility === 'group' ? (
                        <Users className="h-4 w-4 text-white/60" />
                      ) : (
                        <Globe className="h-4 w-4 text-white/60" />
                      )}
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>

        {/* Upcoming Streams */}
        <div className="md:col-span-2 lg:col-span-3">
          <div className="space-y-4">
            <div className="flex items-center space-x-2">
              <Play className="h-5 w-5 text-blue-400" />
              <h2 className="text-xl font-light text-white/90">Upcoming Streams</h2>
            </div>
            <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
              {upcomingStreams.map((stream) => (
                <Link
                  key={stream.id}
                  to={`/stream/${stream.id}`}
                  className="bg-gradient-to-br from-gray-800/50 to-gray-900/50 rounded-xl border border-gray-700/50 backdrop-blur-sm p-4 transition-all duration-300 hover:border-gray-600/50 group"
                >
                  <div className="relative aspect-video mb-3 overflow-hidden rounded-lg">
                    <img
                      src={stream.thumbnailUrl}
                      alt={stream.title}
                      className="object-cover w-full h-full transform transition-transform duration-300 group-hover:scale-105"
                    />
                    <div className="absolute top-2 left-2">
                      <span className="px-2 py-1 text-xs font-medium rounded-full bg-blue-500/90 text-white">
                        UPCOMING
                      </span>
                    </div>
                  </div>
                  <h3 className="font-light text-white/90 group-hover:text-blue-300 transition-colors">
                    {stream.title}
                  </h3>
                  <p className="mt-1 text-sm text-white/70 line-clamp-2 font-light">
                    {stream.description}
                  </p>
                  <div className="mt-2 flex items-center justify-between">
                    <span className="text-sm text-white/60 font-light">
                      {stream.creatorName}
                    </span>
                    <div className="flex items-center space-x-2">
                      {stream.visibility === 'private' ? (
                        <Lock className="h-4 w-4 text-white/60" />
                      ) : stream.visibility === 'group' ? (
                        <Users className="h-4 w-4 text-white/60" />
                      ) : (
                        <Globe className="h-4 w-4 text-white/60" />
                      )}
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}