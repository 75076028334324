import { Users, Plus, Lock, Globe } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { Button } from '../components/ui/Button';
import { Card } from '../components/ui/Card';
import { GroupList } from '../components/group/GroupList';
import { useAuthStore } from '../store/auth';
import InvitationsList from '../components/InvitationsList';
import styles from './GroupsPage.module.css';

interface FeaturedGroup {
  id: string;
  title: string;
  description: string;
}

const featuredGroups: FeaturedGroup[] = [
  {
    id: '1',
    title: 'Featured Group 1',
    description: 'Join this featured group to access exclusive content and collaborate with peers'
  },
  {
    id: '2',
    title: 'Featured Group 2',
    description: 'Join this featured group to access exclusive content and collaborate with peers'
  },
  {
    id: '3',
    title: 'Featured Group 3',
    description: 'Join this featured group to access exclusive content and collaborate with peers'
  }
];

type TabType = 'groups' | 'invitations';

export function GroupsPage() {
  const { isAuthenticated } = useAuthStore();
  const [activeTab, setActiveTab] = useState<TabType>('groups');

  return (
    <div className={`p-6 space-y-8 ${styles.gradientBackground}`}>
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-2xl font-semibold text-white mb-2">Groups</h1>
          <p className="text-gray-400">
            Join professional medical groups to collaborate and share knowledge
          </p>
        </div>
        {isAuthenticated && (
          <Link to="/groups/create">
            <Button variant="primary" className="flex items-center space-x-2">
              <Plus className="w-4 h-4" />
              <span>Create Group</span>
            </Button>
          </Link>
        )}
      </div>

      {isAuthenticated && (
        <div className="bg-gray-800/50 rounded-lg p-6 border border-gray-700">
          <div className="flex space-x-4 mb-6 border-b border-gray-700">
            <button
              onClick={() => setActiveTab('groups')}
              className={`px-4 py-2 -mb-px ${
                activeTab === 'groups'
                  ? 'border-b-2 border-blue-500 text-blue-500 font-medium'
                  : 'text-gray-400 hover:text-white'
              }`}
            >
              My Groups
            </button>
            <button
              onClick={() => setActiveTab('invitations')}
              className={`px-4 py-2 -mb-px ${
                activeTab === 'invitations'
                  ? 'border-b-2 border-blue-500 text-blue-500 font-medium'
                  : 'text-gray-400 hover:text-white'
              }`}
            >
              Invitations
            </button>
          </div>

          {activeTab === 'groups' ? (
            <div className="space-y-8">
              <div>
                <h2 className="text-xl font-semibold text-white mb-4">Public Groups</h2>
                <GroupList private={false} />
              </div>
              
              <div>
                <h2 className="text-xl font-semibold text-white mb-4">Private Groups</h2>
                <GroupList private={true} />
              </div>
              
              <div>
                <h2 className="text-xl font-semibold text-white mb-4">Featured Groups</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {featuredGroups.map((group) => (
                    <Card key={group.id} className="bg-gray-800/50 p-6 border border-gray-700">
                      <div className="flex items-center space-x-4 mb-4">
                        <div className="bg-blue-900/50 p-3 rounded-full">
                          <Users className="w-6 h-6 text-blue-400" />
                        </div>
                        <div>
                          <h3 className="text-white font-medium">{group.title}</h3>
                          <div className="flex items-center space-x-2 text-sm text-gray-400">
                            <Globe className="w-4 h-4" />
                            <span>Public Group</span>
                          </div>
                        </div>
                      </div>
                      <p className="text-gray-400 text-sm mb-4">{group.description}</p>
                      <Button variant="primary" className="w-full">
                        Join Group
                      </Button>
                    </Card>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <InvitationsList />
          )}
        </div>
      )}

      {!isAuthenticated && (
        <div className="text-center py-12 bg-gray-800/50 rounded-xl border border-gray-700">
          <Lock className="w-12 h-12 text-gray-400 mx-auto mb-4" />
          <h2 className="text-xl font-semibold text-white mb-2">
            Sign in to Access Groups
          </h2>
          <p className="text-gray-400 mb-6">
            Please sign in to view and join medical professional groups
          </p>
          <Link to="/login">
            <Button variant="primary">Sign In</Button>
          </Link>
        </div>
      )}
    </div>
  );
}