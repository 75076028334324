import React from 'react';
import { SignupForm } from '../components/auth/SignupForm/SignupForm';
import { Laptop2 } from 'lucide-react';

export function SignupPage() {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-md">
        <div className="text-center mb-8">
          <Laptop2 className="mx-auto h-12 w-12 text-blue-400" />
          <h2 className="mt-6 text-3xl font-light tracking-wide text-white">
            Create your account
          </h2>
          <p className="text-center text-gray-300 mb-8">
            Join SVIZ.live to access streaming and visualization content
          </p>
        </div>

        <div className="bg-gray-800/50 backdrop-blur-sm rounded-xl border border-gray-700/50 p-8">
          <SignupForm />
        </div>
      </div>
    </div>
  );
}