import { doc, updateDoc, serverTimestamp, runTransaction } from 'firebase/firestore';
import { db } from '../firebase';
import { getMemberDocId } from './utils';
import { validateGroupAdmin, validateGroupMembership } from './validation';
import { handleFirebaseError } from '../utils/errors';
import { logger } from '../utils/logger';
import { GROUP_ERRORS, GROUP_ROLES } from './constants';
import { getGroupDoc, getMemberDoc } from './db';
import type { Group } from '../../types/group';

export async function joinGroupAction(groupId: string, userId: string, userName: string): Promise<void> {
  logger.debug('Attempting to join group', { groupId, userId });
  
  try {
    const { data: groupData } = await getGroupDoc(groupId);
    const { data: memberData } = await getMemberDoc(groupId, userId);

    if (memberData) {
      throw new Error(GROUP_ERRORS.ALREADY_MEMBER);
    }

    if (groupData.isPrivate) {
      const isAdmin = await validateGroupAdmin(groupId, userId);
      if (!isAdmin) {
        throw new Error(GROUP_ERRORS.PRIVATE_GROUP);
      }
    }

    const memberRef = doc(db, 'groupMembers', getMemberDocId(userId, groupId));
    const groupRef = doc(db, 'groups', groupId);

    await runTransaction(db, async (transaction) => {
      const timestamp = serverTimestamp();
      
      transaction.set(memberRef, {
        userId,
        groupId,
        userName,
        role: GROUP_ROLES.MEMBER,
        joinedAt: timestamp,
      });

      transaction.update(groupRef, {
        memberCount: groupData.memberCount + 1,
        updatedAt: timestamp,
      });
    });

    logger.info('Successfully joined group', { groupId, userId });
  } catch (error) {
    logger.error('Failed to join group', error as Error);
    handleFirebaseError(error);
  }
}

export async function leaveGroupAction(groupId: string, userId: string): Promise<void> {
  logger.debug('Attempting to leave group', { groupId, userId });
  
  try {
    const isMember = await validateGroupMembership(groupId, userId);
    if (!isMember) {
      throw new Error(GROUP_ERRORS.NOT_MEMBER);
    }

    const isOwner = await validateGroupAdmin(groupId, userId);
    if (isOwner) {
      throw new Error(GROUP_ERRORS.OWNER_LEAVE);
    }

    const memberRef = doc(db, 'groupMembers', getMemberDocId(userId, groupId));
    const groupRef = doc(db, 'groups', groupId);

    await runTransaction(db, async (transaction) => {
      const groupDoc = await transaction.get(groupRef);
      if (!groupDoc.exists()) {
        throw new Error(GROUP_ERRORS.NOT_FOUND);
      }

      transaction.delete(memberRef);
      transaction.update(groupRef, {
        memberCount: Math.max(0, groupDoc.data().memberCount - 1),
        updatedAt: serverTimestamp(),
      });
    });

    logger.info('Successfully left group', { groupId, userId });
  } catch (error) {
    logger.error('Failed to leave group', error as Error);
    handleFirebaseError(error);
  }
}

export async function removeGroupMemberAction(groupId: string, userId: string, adminId: string): Promise<void> {
  logger.debug('Attempting to remove group member', { groupId, userId, adminId });
  
  try {
    const [isMember, isAdmin] = await Promise.all([
      validateGroupMembership(groupId, userId),
      validateGroupAdmin(groupId, adminId),
    ]);

    if (!isMember) {
      throw new Error(GROUP_ERRORS.NOT_MEMBER);
    }

    if (!isAdmin) {
      throw new Error(GROUP_ERRORS.PERMISSION_DENIED);
    }

    const { data: memberData } = await getMemberDoc(groupId, userId);
    if (memberData?.role === GROUP_ROLES.OWNER) {
      throw new Error(GROUP_ERRORS.OWNER_REMOVE);
    }

    const memberRef = doc(db, 'groupMembers', getMemberDocId(userId, groupId));
    const groupRef = doc(db, 'groups', groupId);

    await runTransaction(db, async (transaction) => {
      const groupDoc = await transaction.get(groupRef);
      if (!groupDoc.exists()) {
        throw new Error(GROUP_ERRORS.NOT_FOUND);
      }

      transaction.delete(memberRef);
      transaction.update(groupRef, {
        memberCount: Math.max(0, groupDoc.data().memberCount - 1),
        updatedAt: serverTimestamp(),
      });
    });

    logger.info('Successfully removed group member', { groupId, userId, adminId });
  } catch (error) {
    logger.error('Failed to remove group member', error as Error);
    handleFirebaseError(error);
  }
}

export async function updateGroupSettingsAction(groupId: string, updates: Partial<Group>): Promise<void> {
  logger.debug('Attempting to update group settings', { groupId, updates });
  
  try {
    const groupRef = doc(db, 'groups', groupId);
    await updateDoc(groupRef, {
      ...updates,
      updatedAt: serverTimestamp(),
    });
    logger.info('Successfully updated group settings', { groupId });
  } catch (error) {
    logger.error('Failed to update group settings', error as Error);
    handleFirebaseError(error);
  }
}

export async function updateMemberRoleAction(groupId: string, userId: string, newRole: string): Promise<void> {
  logger.debug('Attempting to update member role', { groupId, userId, newRole });
  
  try {
    const memberRef = doc(db, 'groupMembers', getMemberDocId(userId, groupId));
    const memberDoc = await getDoc(memberRef);
    
    if (!memberDoc.exists()) {
      throw new Error(GROUP_ERRORS.NOT_MEMBER);
    }

    if (memberDoc.data().role === GROUP_ROLES.OWNER) {
      throw new Error('Cannot change owner role');
    }

    await updateDoc(memberRef, {
      role: newRole,
      updatedAt: serverTimestamp(),
    });
    
    logger.info('Successfully updated member role', { groupId, userId, newRole });
  } catch (error) {
    logger.error('Failed to update member role', error as Error);
    handleFirebaseError(error);
  }
}