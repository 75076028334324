import React from 'react';
import { ROLES } from '../../config/roles';

export function RoleList() {
  return (
    <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
      {ROLES.map((role) => (
        <div
          key={role.name}
          className="bg-white rounded-lg shadow-sm p-6 border border-gray-200"
        >
          <h3 className="text-lg font-medium text-gray-900 mb-2">{role.label}</h3>
          <p className="text-sm text-gray-500 mb-4">{role.description}</p>
          <div>
            <h4 className="text-sm font-medium text-gray-700 mb-2">Permissions:</h4>
            <div className="flex flex-wrap gap-2">
              {role.permissions.map((permission) => (
                <span
                  key={permission.id}
                  className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"
                >
                  {permission.name}
                </span>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}