import React from 'react';
import { useAuthStore } from '../../store/auth';
import { useStreamStore } from '../../store/stream';
import { useGroupStore } from '../../store/group';
import { RoleGuard } from '../../components/auth/RoleGuard';
import { PERMISSIONS } from '../../config/roles';
import { 
  BarChart3, 
  Users, 
  Play, 
  Settings, 
  Shield, 
  FileText, 
  BarChart2, 
  History 
} from 'lucide-react';

const StatCard: React.FC<{
  title: string;
  value: string | number;
  icon: React.ReactNode;
  description?: string;
}> = ({ title, value, icon, description }) => (
  <div className="bg-white/5 backdrop-blur-lg rounded-lg p-6 border border-gray-800">
    <div className="flex items-center justify-between">
      <div>
        <p className="text-sm font-medium text-gray-400">{title}</p>
        <p className="text-2xl font-semibold text-white mt-2">{value}</p>
        {description && (
          <p className="text-sm text-gray-500 mt-1">{description}</p>
        )}
      </div>
      <div className="text-blue-400">{icon}</div>
    </div>
  </div>
);

export function AdminDashboardPage() {
  const { user } = useAuthStore();
  const { streams } = useStreamStore();
  const { groups } = useGroupStore();

  const stats = [
    {
      title: 'Total Users',
      value: user ? 1 : 0, // TODO: Replace with actual user count from admin service
      icon: <Users className="h-6 w-6" />,
      description: 'Active platform users'
    },
    {
      title: 'Active Streams',
      value: streams.filter(s => s.isLive).length,
      icon: <Play className="h-6 w-6" />,
      description: 'Currently live streams'
    },
    {
      title: 'Total Groups',
      value: groups.length,
      icon: <BarChart3 className="h-6 w-6" />,
      description: 'Learning groups'
    },
    {
      title: 'System Status',
      value: 'Healthy',
      icon: <Settings className="h-6 w-6" />,
      description: 'All systems operational'
    }
  ];

  return (
    <RoleGuard
      requiredPermission={PERMISSIONS.MANAGE_SYSTEM.id}
      fallback={
        <div className="text-center py-12">
          <h2 className="text-2xl font-semibold text-white">Access Denied</h2>
          <p className="text-gray-400 mt-2">
            You don't have permission to access the admin dashboard.
          </p>
        </div>
      }
    >
      <div className="space-y-8">
        <div>
          <h1 className="text-3xl font-bold text-white">Admin Dashboard</h1>
          <p className="text-gray-400 mt-2">Monitor and manage your platform</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {stats.map((stat) => (
            <StatCard key={stat.title} {...stat} />
          ))}
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Quick Actions */}
          <div className="bg-white/5 backdrop-blur-lg rounded-lg p-6 border border-gray-800">
            <h2 className="text-xl font-semibold text-white mb-4">Quick Actions</h2>
            <div className="grid grid-cols-2 gap-4">
              <button
                onClick={() => window.location.href = '/admin/users'}
                className="p-4 bg-blue-600/10 hover:bg-blue-600/20 rounded-lg border border-blue-500/20 transition-colors"
              >
                <Users className="h-5 w-5 text-blue-400 mb-2" />
                <span className="text-sm font-medium text-white">Manage Users</span>
              </button>
              <button
                onClick={() => window.location.href = '/admin/streams'}
                className="p-4 bg-purple-600/10 hover:bg-purple-600/20 rounded-lg border border-purple-500/20 transition-colors"
              >
                <Play className="h-5 w-5 text-purple-400 mb-2" />
                <span className="text-sm font-medium text-white">Manage Streams</span>
              </button>
              <button
                onClick={() => window.location.href = '/admin/analytics'}
                className="p-4 bg-green-600/10 hover:bg-green-600/20 rounded-lg border border-green-500/20 transition-colors"
              >
                <BarChart2 className="h-5 w-5 text-green-400 mb-2" />
                <span className="text-sm font-medium text-white">Analytics</span>
              </button>
              <button
                onClick={() => window.location.href = '/admin/moderation'}
                className="p-4 bg-red-600/10 hover:bg-red-600/20 rounded-lg border border-red-500/20 transition-colors"
              >
                <Shield className="h-5 w-5 text-red-400 mb-2" />
                <span className="text-sm font-medium text-white">Content Moderation</span>
              </button>
              <button
                onClick={() => window.location.href = '/admin/audit-logs'}
                className="p-4 bg-yellow-600/10 hover:bg-yellow-600/20 rounded-lg border border-yellow-500/20 transition-colors"
              >
                <History className="h-5 w-5 text-yellow-400 mb-2" />
                <span className="text-sm font-medium text-white">Audit Logs</span>
              </button>
              <button
                onClick={() => window.location.href = '/admin/reports'}
                className="p-4 bg-indigo-600/10 hover:bg-indigo-600/20 rounded-lg border border-indigo-500/20 transition-colors"
              >
                <FileText className="h-5 w-5 text-indigo-400 mb-2" />
                <span className="text-sm font-medium text-white">Reports</span>
              </button>
              <button
                onClick={() => window.location.href = '/admin/groups'}
                className="p-4 bg-pink-600/10 hover:bg-pink-600/20 rounded-lg border border-pink-500/20 transition-colors"
              >
                <Users className="h-5 w-5 text-pink-400 mb-2" />
                <span className="text-sm font-medium text-white">Manage Groups</span>
              </button>
            </div>
          </div>

          {/* Recent Activity */}
          <div className="bg-white/5 backdrop-blur-lg rounded-lg p-6 border border-gray-800">
            <h2 className="text-xl font-semibold text-white mb-4">Recent Activity</h2>
            <div className="space-y-4">
              {/* We'll implement this in the next phase */}
              <p className="text-gray-400 text-sm">Activity feed coming soon...</p>
            </div>
          </div>
        </div>
      </div>
    </RoleGuard>
  );
}
