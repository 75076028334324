// Removed unused import of FC
// import type { FC } from 'react';
import { AlertTriangle, XCircle } from 'lucide-react';
import { cn } from '../../utils/cn';

interface ErrorAlertProps {
  title: string;
  message: string;
  type?: 'error' | 'warning';
  onDismiss?: () => void;
}

export function ErrorAlert({ 
  title, 
  message, 
  type = 'error',
  onDismiss 
}: ErrorAlertProps) {
  const isError = type === 'error';
  
  return (
    <div className={cn(
      'rounded-lg p-4 mb-4',
      isError ? 'bg-red-50 border border-red-200' : 'bg-yellow-50 border border-yellow-200'
    )}>
      <div className="flex items-start">
        <div className="flex-shrink-0">
          {isError ? (
            <XCircle className="h-5 w-5 text-red-400" />
          ) : (
            <AlertTriangle className="h-5 w-5 text-yellow-400" />
          )}
        </div>
        <div className="ml-3 w-full">
          <h3 className={cn(
            'text-sm font-medium',
            isError ? 'text-red-800' : 'text-yellow-800'
          )}>
            {title}
          </h3>
          <div className={cn(
            'mt-2 text-sm',
            isError ? 'text-red-700' : 'text-yellow-700'
          )}>
            {message}
          </div>
        </div>
        {onDismiss && (
          <button
            type="button"
            className={cn(
              'ml-auto flex-shrink-0 -mr-1 -mt-1 p-1 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2',
              isError 
                ? 'text-red-400 hover:text-red-500 focus:ring-red-500' 
                : 'text-yellow-400 hover:text-yellow-500 focus:ring-yellow-500'
            )}
            onClick={onDismiss}
          >
            <span className="sr-only">Dismiss</span>
            <XCircle className="h-5 w-5" />
          </button>
        )}
      </div>
    </div>
  );
}
