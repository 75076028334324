import * as React from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';

interface StreamThumbnailProps {
  streamKey: string;
  userId: string;
  userName: string;
  onClick?: () => void;
}

function StreamThumbnail({ streamKey, userId, userName, onClick }: StreamThumbnailProps) {
  const [error, setError] = React.useState<string | null>(null);
  const [thumbnailLoaded, setThumbnailLoaded] = React.useState(false);
  const [isStreamActive, setIsStreamActive] = React.useState(false);

  React.useEffect(() => {
    const checkStreamStatus = async () => {
      try {
        const streamDoc = await getDoc(doc(db, 'streams', streamKey));
        if (streamDoc.exists()) {
          const data = streamDoc.data();
          setIsStreamActive(data.isActive || false);
        }
      } catch (error) {
        console.error('Error checking stream status:', error);
        setIsStreamActive(false);
      }
    };

    checkStreamStatus();
  }, [streamKey]);

  const getThumbnailUrl = () => {
    return `https://live.surgi-cloud.com/${streamKey}.jpg`;
  };

  return (
    <div 
      className="aspect-video bg-gray-900 rounded-lg overflow-hidden relative cursor-pointer hover:opacity-90 transition-opacity"
      onClick={onClick}
    >
      {!thumbnailLoaded && !error && (
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      )}
      
      {error ? (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-800">
          <p className="text-red-400 text-sm">{error}</p>
        </div>
      ) : (
        <img
          src={getThumbnailUrl()}
          alt="Stream thumbnail"
          className="w-full h-full object-cover"
          onLoad={() => setThumbnailLoaded(true)}
          onError={() => setError('Failed to load thumbnail')}
        />
      )}
      
      {!isStreamActive && (
        <div className="absolute inset-0 bg-black/70 flex items-center justify-center">
          <span className="text-gray-400 text-sm">Stream is offline</span>
        </div>
      )}
    </div>
  );
}

export default StreamThumbnail;
