// Set global for AWS SDK browser usage
if (typeof global === 'undefined') {
  (window as any).global = window;
}

import { 
  GetObjectCommand, 
  ListObjectsV2Command, 
  DeleteObjectCommand, 
  HeadObjectCommand,
  PutObjectCommand,
  _Object
} from '@aws-sdk/client-s3';
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { s3Client } from '../config/aws-config';

const STORAGE_BUCKET_NAME = 'sviz';
const URL_EXPIRATION = 3600; // 1 hour in seconds

// Add CORS headers to requests
const corsHeaders = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,HEAD',
  'Access-Control-Allow-Headers': 'Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token',
  'Access-Control-Expose-Headers': 'ETag'
};

export interface StorageError {
  code: string;
  message: string;
  originalError?: any;
}

export class StorageError extends Error {
  constructor(message: string, code: string, originalError?: any) {
    super(message);
    this.name = 'StorageError';
    this.code = code;
    this.originalError = originalError;
  }
}

const validateCredentials = () => {
  if (!import.meta.env.VITE_WASABI_ACCESS_KEY_ID || !import.meta.env.VITE_WASABI_SECRET_ACCESS_KEY) {
    throw new StorageError(
      'Storage credentials are not configured',
      'storage/missing-credentials'
    );
  }
};

async function checkBucketAccess(): Promise<void> {
  try {
    // Try to get a test object - 404 is expected and means we have access
    const testKey = '.bucket-access-test';
    try {
      await s3Client.send(new GetObjectCommand({
        Bucket: STORAGE_BUCKET_NAME,
        Key: testKey
      }));
    } catch (error: any) {
      // 404 is expected and actually good - means we have access but object doesn't exist
      if (error.name !== 'NotFound' && error.name !== 'NoSuchKey') {
        throw error;
      }
      return;
    }
    
    console.log('Successfully verified bucket access');
  } catch (error: any) {
    console.error('Bucket access error:', error);
    
    if (error.name === 'NoSuchBucket') {
      throw new StorageError(
        'Storage bucket does not exist. Please create it in the Wasabi console.',
        'storage/bucket-not-found',
        error
      );
    } else if (error.name === 'Forbidden' || error.name === 'AccessDenied') {
      throw new StorageError(
        'Access denied to storage bucket. Please verify bucket permissions.',
        'storage/access-denied',
        error
      );
    } else {
      throw new StorageError(
        `Failed to access storage bucket: ${error.message}`,
        'storage/bucket-error',
        error
      );
    }
  }
};

export interface StorageFile {
  key: string;
  size: number;
  lastModified: Date;
}

export const listUserFiles = async (userId: string): Promise<StorageFile[]> => {
  try {
    const params = {
      Bucket: STORAGE_BUCKET_NAME,
      Prefix: `${userId}/`,
      MaxKeys: 1000
    };

    const command = new ListObjectsV2Command(params);
    const response = await s3Client.send(command);
    
    // Filter out the folder itself and map to StorageFile interface
    const files = response.Contents?.filter((item: any) => item.Key !== `${userId}/`)
      .map((item: any) => ({
        key: item.Key || '',
        size: item.Size || 0,
        lastModified: item.LastModified || new Date()
      })) || [];

    return files;
  } catch (error: any) {
    console.error('Error listing files:', error);
    throw new StorageError(
      `Failed to list files: ${error.message}`,
      error.code || 'storage/list-error',
      error
    );
  }
};

export const deleteUserFile = async (userId: string, fileName: string): Promise<void> => {
  try {
    const key = `${userId}/${fileName}`;
    await s3Client.send(new DeleteObjectCommand({
      Bucket: STORAGE_BUCKET_NAME,
      Key: key
    }));
  } catch (error: any) {
    throw new StorageError(
      `Failed to delete file: ${error.message}`,
      'storage/delete-error',
      error
    );
  }
};

export const getFileUrl = async (userId: string, fileName: string): Promise<string> => {
  try {
    const key = `${userId}/${fileName}`;
    const params = {
      Bucket: STORAGE_BUCKET_NAME,
      Key: key,
      Expires: URL_EXPIRATION
    };

    // First check if the file exists
    try {
      await s3Client.send(new HeadObjectCommand({
        Bucket: STORAGE_BUCKET_NAME,
        Key: key
      }));
    } catch (error: any) {
      if (error.name === 'NotFound') {
        throw new StorageError('File not found', 'FileNotFound', error);
      }
      throw error;
    }

    // Generate signed URL
    return await getSignedUrl(s3Client, new GetObjectCommand(params), { expiresIn: URL_EXPIRATION });
  } catch (error: any) {
    console.error('Error in getFileUrl:', error);
    throw new StorageError(
      `Failed to generate file URL: ${error.message}`,
      error.name || 'storage/url-generation-error',
      error
    );
  }
};

// Function to check if a file exists
export const fileExists = async (userId: string, fileName: string): Promise<boolean> => {
  try {
    const key = `${userId}/${fileName}`;
    await s3Client.send(new HeadObjectCommand({
      Bucket: STORAGE_BUCKET_NAME,
      Key: key
    }));
    return true;
  } catch (error: any) {
    if (error.name === 'NotFound') {
      return false;
    }
    throw new StorageError(
      `Failed to check file existence: ${error.message}`,
      'storage/check-error',
      error
    );
  }
};

export const getSignedUploadUrl = async (key: string, contentType: string): Promise<string> => {
  const params = {
    Bucket: STORAGE_BUCKET_NAME,
    Key: key,
    ContentType: contentType
  };

  try {
    const command = new PutObjectCommand(params);
    const url = await getSignedUrl(s3Client, command, { 
      expiresIn: URL_EXPIRATION,
      // Required headers for Wasabi
      signableHeaders: new Set([
        'host',
        'content-type'
      ])
    });

    return url;
  } catch (error: any) {
    console.error('Error generating signed URL:', error);
    throw new StorageError(
      `Failed to generate upload URL: ${error.message}`,
      error.code || 'storage/url-generation-error',
      error
    );
  }
};

export const getSignedDownloadUrl = async (key: string): Promise<string> => {
  const params = {
    Bucket: STORAGE_BUCKET_NAME,
    Key: key,
    Expires: URL_EXPIRATION
  };

  try {
    return await getSignedUrl(s3Client, new GetObjectCommand(params), { expiresIn: URL_EXPIRATION });
  } catch (error: any) {
    throw new StorageError(
      `Failed to generate download URL: ${error.message}`,
      'storage/download-url-error',
      error
    );
  }
};

export const uploadFile = async (
  userId: string,
  file: File,
  onProgress?: (progressEvent: { loaded: number; total: number }) => void
): Promise<StorageFile> => {
  try {
    const key = `${userId}/${file.name}`;
    const params = {
      Bucket: STORAGE_BUCKET_NAME,
      Key: key,
      Body: file,
      ContentType: file.type
    };

    if (onProgress) {
      // For progress tracking, we need to use the presigned URL approach
      const signedUrl = await getSignedUrl(s3Client, new PutObjectCommand(params), { expiresIn: URL_EXPIRATION });
      
      await new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('PUT', signedUrl);
        xhr.setRequestHeader('Content-Type', file.type);
        
        xhr.upload.onprogress = (e) => {
          if (onProgress) {
            onProgress({
              loaded: e.loaded,
              total: e.total
            });
          }
        };
        
        xhr.onload = () => {
          if (xhr.status === 200) {
            resolve(null);
          } else {
            reject(new Error(`Upload failed with status ${xhr.status}`));
          }
        };
        
        xhr.onerror = () => reject(new Error('Upload failed'));
        xhr.send(file);
      });
    } else {
      // Fallback to regular upload if no progress tracking needed
      await s3Client.send(new PutObjectCommand(params));
    }

    return {
      key,
      size: file.size,
      lastModified: new Date()
    };
  } catch (error: any) {
    console.error('Error uploading file:', error);
    throw new StorageError(
      `Failed to upload file: ${error.message}`,
      'storage/upload-error',
      error
    );
  }
};

export const downloadFile = async (key: string): Promise<Blob> => {
  try {
    const signedUrl = await getSignedDownloadUrl(key);
    
    const response = await fetch(signedUrl);
    if (!response.ok) {
      throw new Error(`Download failed with status: ${response.status}`);
    }

    return await response.blob();
  } catch (error: any) {
    throw new StorageError(
      `Failed to download file: ${error.message}`,
      'storage/download-error',
      error
    );
  }
};

export const createUserFolder = async (userId: string): Promise<void> => {
  try {
    // Create an empty object with a trailing slash to represent a folder
    await s3Client.send(new PutObjectCommand({
      Bucket: STORAGE_BUCKET_NAME,
      Key: `${userId}/`,
      Body: '',
      ContentType: 'application/x-directory'
    }));
    
    console.log(`Created folder for user: ${userId}`);
  } catch (error: any) {
    throw new StorageError(
      `Failed to create user folder: ${error.message}`,
      'storage/folder-creation-error',
      error
    );
  }
};

export const initializeUserStorage = async (userId: string): Promise<void> => {
  try {
    validateCredentials();
    await checkBucketAccess();
    
    // Create the user's folder
    await createUserFolder(userId);

    // Test write access by uploading and deleting a test file
    try {
      const testKey = `${userId}/.write-test`;
      const testFile = new File(['test'], '.write-test', { type: 'text/plain' });
      await uploadFile(userId, testFile);
      
      // Clean up the test file
      await s3Client.send(new DeleteObjectCommand({
        Bucket: STORAGE_BUCKET_NAME,
        Key: testKey
      }));

      console.log('Successfully initialized storage for user:', userId);
    } catch (error: any) {
      // If write test fails, try to clean up the folder
      try {
        await s3Client.send(new DeleteObjectCommand({
          Bucket: STORAGE_BUCKET_NAME,
          Key: `${userId}/`
        }));
      } catch (cleanupError) {
        console.error('Failed to clean up user folder after initialization failure:', cleanupError);
      }
      
      throw new StorageError(
        `Failed to verify write permissions: ${error.message}`,
        'storage/write-permission-error',
        error
      );
    }
  } catch (error: any) {
    if (error instanceof StorageError) {
      throw error;
    }
    throw new StorageError(
      `Failed to initialize user storage: ${error.message}`,
      'storage/initialization-error',
      error
    );
  }
};
