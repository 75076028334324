import React, { useEffect, useRef, useState } from 'react';
import { useAuthStore } from '../../store/auth';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { X, Maximize2, Minimize2, Play, Pause } from 'lucide-react';

interface DeviceStreamPlayerProps {
  streamKey: string;
}

export default function DeviceStreamPlayer({ streamKey }: DeviceStreamPlayerProps) {
  const { user } = useAuthStore();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMinimized, setIsMinimized] = useState(true);
  const [showControls, setShowControls] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const initializeStream = async () => {
      try {
        // Store stream key in Firestore for thumbnail access
        if (user?.id) {
          const streamDocRef = doc(db, 'streams', streamKey);
          await setDoc(streamDocRef, {
            streamKey,
            userId: user.id,
            updatedAt: new Date(),
            isActive: true,
          }, { merge: true });
        }
      } catch (err) {
        console.error('Error initializing stream:', err);
        setError(err instanceof Error ? err.message : 'Failed to initialize stream');
      }
    };

    initializeStream();
  }, [streamKey, user?.id]);

  const handleThumbnailClick = () => {
    console.log('Thumbnail clicked');
    setIsPlaying(true);
    setIsMinimized(false);
  };

  const togglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  const closePlayer = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      setIsPlaying(false);
      setIsMinimized(true);
    }
  };

  useEffect(() => {
    if (videoRef.current && isPlaying) {
      console.log('Attempting to play video');
      videoRef.current.play()
        .then(() => {
          console.log('Video playing successfully');
        })
        .catch(err => {
          console.error('Error playing video:', err);
          setError('Failed to play video');
          setIsPlaying(false);
        });
    }
  }, [isPlaying]);

  if (error) {
    return (
      <div className="rounded-lg bg-red-500/10 border border-red-500/20 p-4">
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  // Thumbnail view
  if (!isPlaying) {
    return (
      <div 
        className="aspect-video bg-gray-900 rounded-lg overflow-hidden relative cursor-pointer hover:opacity-90 transition-opacity"
        onClick={handleThumbnailClick}
      >
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-gradient-to-br from-gray-800 to-gray-900">
          <Play className="w-16 h-16 text-white/80 mb-4" />
          <p className="text-white/60 text-sm">Click to start streaming</p>
        </div>
      </div>
    );
  }

  // Video player
  return (
    <div 
      className={`
        relative bg-black overflow-hidden transition-all duration-300
        ${isMinimized 
          ? 'fixed bottom-4 right-4 w-80 rounded-lg shadow-lg z-50' 
          : 'w-full aspect-video rounded-lg'
        }
      `}
      onMouseEnter={() => setShowControls(true)}
      onMouseLeave={() => setShowControls(false)}
    >
      <video
        ref={videoRef}
        src={`https://live.surgi-cloud.com/${streamKey}.mp4`}
        className="w-full h-full"
        playsInline={false}
        controls={false}
      />

      {/* Controls overlay */}
      <div 
        className={`
          absolute inset-0 bg-gradient-to-t from-black/70 to-transparent
          transition-opacity duration-200
          ${showControls ? 'opacity-100' : 'opacity-0'}
        `}
      >
        <div className="absolute top-2 right-2 flex space-x-2">
          <button
            onClick={toggleMinimize}
            className="p-1 text-white/80 hover:text-white transition-colors"
          >
            {isMinimized ? <Maximize2 className="w-5 h-5" /> : <Minimize2 className="w-5 h-5" />}
          </button>
          <button
            onClick={closePlayer}
            className="p-1 text-white/80 hover:text-red-400 transition-colors"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="absolute bottom-2 left-2">
          <button
            onClick={togglePlay}
            className="p-1 text-white/80 hover:text-white transition-colors"
          >
            {isPlaying ? (
              <Pause className="w-6 h-6" />
            ) : (
              <Play className="w-6 h-6" />
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
