import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Users, Eye } from 'lucide-react';
import type { Stream } from '../../types/stream';
import { Button } from '../ui/Button';

interface StreamCardProps {
  stream: Stream;
}

export function StreamCard({ stream }: StreamCardProps) {
  const navigate = useNavigate();

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      <img
        src={stream.thumbnailUrl}
        alt={stream.title}
        className="w-full h-48 object-cover"
      />
      <div className="p-4">
        <div className="flex items-center justify-between mb-2">
          <span className={`px-2 py-1 rounded text-sm ${
            stream.isLive ? 'bg-red-100 text-red-800' : 'bg-gray-100 text-gray-800'
          }`}>
            {stream.isLive ? 'LIVE' : 'ENDED'}
          </span>
          <div className="flex items-center space-x-2 text-gray-600">
            <Eye className="h-4 w-4" />
            <span>{stream.viewerCount}</span>
          </div>
        </div>
        <h3 className="text-lg font-semibold mb-1">{stream.title}</h3>
        <p className="text-gray-600 text-sm mb-2">{stream.description}</p>
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-1">
            <Users className="h-4 w-4 text-gray-500" />
            <span className="text-sm text-gray-600">
              {stream.visibility === 'public' ? 'Public' : 'Private Group'}
            </span>
          </div>
          <Button
            size="sm"
            onClick={() => navigate(`/stream/${stream.id}`)}
          >
            Join Stream
          </Button>
        </div>
      </div>
    </div>
  );
}