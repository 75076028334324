import React, { useState } from 'react';
import { MoreVertical, Shield, Crown, Users } from 'lucide-react';
import type { Group } from '../../types/group';
import { Button } from '../ui/Button';
import { useGroupStore } from '../../store/group';
import { useAuthStore } from '../../store/auth';

interface GroupMembersProps {
  group: Group;
  isAdmin: boolean;
}

export function GroupMembers({ group, isAdmin }: GroupMembersProps) {
  const { removeMember, updateMemberRole } = useGroupStore();
  const { user } = useAuthStore();
  const [selectedMember, setSelectedMember] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleAction = async (userId: string, action: 'remove' | 'promote' | 'demote') => {
    try {
      setError(null);
      setIsLoading(true);

      if (action === 'remove') {
        await removeMember(group.id, userId);
      } else if (action === 'promote') {
        await updateMemberRole(group.id, userId, 'admin');
      } else if (action === 'demote') {
        await updateMemberRole(group.id, userId, 'member');
      }
      setSelectedMember(null);
    } catch (error: any) {
      setError(error.message || 'Failed to perform action');
    } finally {
      setIsLoading(false);
    }
  };

  if (!group.members?.length) {
    return (
      <div className="bg-gradient-to-br from-gray-800/30 to-gray-900/30 rounded-xl border border-gray-700/50 backdrop-blur-sm p-6">
        <div className="flex items-center space-x-2 mb-6">
          <Users className="h-5 w-5 text-gray-400" />
          <h2 className="text-xl font-light tracking-wide text-white/90">Members</h2>
        </div>
        <p className="text-gray-400 font-light">No members found</p>
      </div>
    );
  }

  return (
    <div className="bg-gradient-to-br from-gray-800/30 to-gray-900/30 rounded-xl border border-gray-700/50 backdrop-blur-sm p-6">
      <div className="flex items-center space-x-2 mb-6">
        <Users className="h-5 w-5 text-gray-400" />
        <h2 className="text-xl font-light tracking-wide text-white/90">Members</h2>
      </div>

      {error && (
        <div className="mb-4 p-3 rounded-md bg-red-50/10 border border-red-200/20">
          <p className="text-sm text-red-400">{error}</p>
        </div>
      )}

      <div className="space-y-4">
        {group.members.map((member) => (
          <div key={member.id} className="flex items-center justify-between py-3 border-b border-gray-700/50 last:border-0">
            <div className="flex items-center space-x-3">
              <div className="h-10 w-10 rounded-full bg-gradient-to-br from-blue-500/20 to-purple-500/20 border border-gray-700/50 flex items-center justify-center">
                <span className="text-white/80">{member.userName?.charAt(0) || '?'}</span>
              </div>
              <div>
                <p className="font-light text-white/90">{member.userName || 'Unknown User'}</p>
                <div className="flex items-center space-x-2">
                  {member.role === 'owner' && (
                    <span className="flex items-center text-yellow-500/90 text-sm">
                      <Crown className="h-4 w-4 mr-1" />
                      Owner
                    </span>
                  )}
                  {member.role === 'admin' && (
                    <span className="flex items-center text-blue-400/90 text-sm">
                      <Shield className="h-4 w-4 mr-1" />
                      Admin
                    </span>
                  )}
                </div>
              </div>
            </div>

            {isAdmin && member.role !== 'owner' && member.userId !== user?.id && (
              <div className="relative">
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => setSelectedMember(selectedMember === member.id ? null : member.id)}
                  disabled={isLoading}
                  className="border-gray-700 text-gray-400 hover:bg-gray-800"
                >
                  <MoreVertical className="h-4 w-4" />
                </Button>
                
                {selectedMember === member.id && (
                  <div className="absolute right-0 mt-2 w-48 rounded-lg shadow-lg bg-gray-800 ring-1 ring-gray-700 backdrop-blur-sm">
                    <div className="py-1">
                      {member.role === 'member' && (
                        <button
                          onClick={() => handleAction(member.userId, 'promote')}
                          disabled={isLoading}
                          className="block w-full text-left px-4 py-2 text-sm text-gray-300 hover:bg-gray-700/50"
                        >
                          Make Admin
                        </button>
                      )}
                      {member.role === 'admin' && (
                        <button
                          onClick={() => handleAction(member.userId, 'demote')}
                          disabled={isLoading}
                          className="block w-full text-left px-4 py-2 text-sm text-gray-300 hover:bg-gray-700/50"
                        >
                          Remove Admin
                        </button>
                      )}
                      <button
                        onClick={() => handleAction(member.userId, 'remove')}
                        disabled={isLoading}
                        className="block w-full text-left px-4 py-2 text-sm text-red-400 hover:bg-gray-700/50"
                      >
                        Remove from Group
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}