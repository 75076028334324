import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Link, useNavigate } from 'react-router-dom';
import { Eye, EyeOff, Loader } from 'lucide-react';
import { useAuthStore } from '../../../store/auth';
import { Button } from '../../ui/Button';
import { Input } from '../../ui/Input';
import { ErrorAlert } from '../../ui/ErrorAlert';
import { PasswordStrengthMeter } from './PasswordStrengthMeter';
import { signupSchema, type SignupFormData } from './validation';
import { getErrorMessage } from '../../../utils/errorMessages';

export function SignupForm() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState<{ title: string; message: string; type: 'error' | 'warning' } | null>(null);
  const { register: signUp, isLoading } = useAuthStore();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<SignupFormData>({
    resolver: zodResolver(signupSchema),
    defaultValues: {
      acceptTerms: false,
    },
  });

  const password = watch('password', '');

  const onSubmit = async (data: SignupFormData) => {
    try {
      setError(null);
      await signUp(data.email, data.password, data.name);
      navigate('/verify-email');
    } catch (error: any) {
      const errorCode = error.code || 'unknown';
      const errorDetails = getErrorMessage(errorCode);
      setError(errorDetails);
      
      // Log the error for debugging
      console.error('Registration failed:', {
        code: errorCode,
        message: error.message,
        originalError: error.originalError
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      {error && (
        <ErrorAlert
          title={error.title}
          message={error.message}
          type={error.type}
          onDismiss={() => setError(null)}
        />
      )}

      <Input
        {...register('name')}
        label="Full Name"
        placeholder="Dr. John Doe"
        error={errors.name?.message}
      />

      <Input
        {...register('email')}
        type="email"
        label="Email Address"
        placeholder="john.doe@example.com"
        error={errors.email?.message}
      />

      <div className="space-y-4">
        <div className="relative">
          <Input
            {...register('password')}
            type={showPassword ? 'text' : 'password'}
            label="Password"
            error={errors.password?.message}
          />
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="absolute right-3 top-8 text-gray-400 hover:text-gray-600"
          >
            {showPassword ? (
              <EyeOff className="h-5 w-5" />
            ) : (
              <Eye className="h-5 w-5" />
            )}
          </button>
        </div>

        <PasswordStrengthMeter password={password} />
      </div>

      <div className="relative">
        <Input
          {...register('confirmPassword')}
          type={showConfirmPassword ? 'text' : 'password'}
          label="Confirm Password"
          error={errors.confirmPassword?.message}
        />
        <button
          type="button"
          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
          className="absolute right-3 top-8 text-gray-400 hover:text-gray-600"
        >
          {showConfirmPassword ? (
            <EyeOff className="h-5 w-5" />
          ) : (
            <Eye className="h-5 w-5" />
          )}
        </button>
      </div>

      <div className="flex items-center space-x-2">
        <input
          type="checkbox"
          {...register('acceptTerms')}
          className="rounded border-gray-700 bg-gray-800 text-blue-500 focus:ring-blue-500"
        />
        <label className="text-sm text-gray-300">
          I accept the{' '}
          <Link to="/terms" className="text-blue-400 hover:text-blue-300">
            terms and conditions
          </Link>
        </label>
      </div>
      {errors.acceptTerms && (
        <p className="text-sm text-red-500 mt-1">{errors.acceptTerms.message}</p>
      )}

      <Button
        type="submit"
        isLoading={isLoading}
        className="w-full bg-blue-600 hover:bg-blue-700"
      >
        {isLoading ? (
          <>
            <Loader className="h-4 w-4 animate-spin mr-2" />
            Creating Account...
          </>
        ) : (
          'Create Account'
        )}
      </Button>

      <p className="text-center text-sm text-gray-400">
        Already have an account?{' '}
        <Link to="/login" className="text-blue-400 hover:text-blue-300">
          Sign in
        </Link>
      </p>
    </form>
  );
}