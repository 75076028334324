import { useState, useCallback } from 'react';
import { useAuthStore } from '../store/auth';
import { useGroupStore } from '../store/group';
import { checkGroupPermissions } from '../lib/group';
import { logger } from '../lib/utils/logger';

export function useGroup(groupId?: string) {
  const { user } = useAuthStore();
  const { currentGroup, groups, leaveGroup: storeLeaveGroup, joinGroup: storeJoinGroup } = useGroupStore();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const group = groupId 
    ? groups.find(g => g.id === groupId) || currentGroup
    : currentGroup;

  const handleError = useCallback((error: unknown) => {
    const message = error instanceof Error ? error.message : 'An unexpected error occurred';
    logger.error('Group operation failed', error instanceof Error ? error : new Error(message));
    setError(message);
  }, []);

  const isMember = useCallback((): boolean => {
    if (!user || !group) return false;
    return group.members?.some(member => member.userId === user.id) || false;
  }, [user, group]);

  const isOwner = useCallback(async (): Promise<boolean> => {
    if (!user || !group) return false;
    try {
      const { isOwner } = await checkGroupPermissions(group.id, user.id);
      return isOwner;
    } catch (error) {
      logger.warn('Failed to check owner status', error as Error);
      return false;
    }
  }, [user, group]);

  const leaveGroup = useCallback(async () => {
    if (!user || !group) return;
    setIsLoading(true);
    setError(null);
    try {
      await storeLeaveGroup(group.id);
      logger.info('Successfully left group', { userId: user.id, groupId: group.id });
    } catch (error) {
      handleError(error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, [user, group, storeLeaveGroup, handleError]);

  const joinGroup = useCallback(async () => {
    if (!user || !group) return;
    setIsLoading(true);
    setError(null);
    try {
      await storeJoinGroup(group.id);
      logger.info('Successfully joined group', { userId: user.id, groupId: group.id });
    } catch (error) {
      handleError(error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, [user, group, storeJoinGroup, handleError]);

  return {
    group,
    isLoading,
    error,
    isMember,
    isOwner,
    leaveGroup,
    joinGroup,
  };
}