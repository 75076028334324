import React, { useState } from 'react';
import { useAuthStore } from '../../store/auth';
import { PERMISSIONS } from '../../config/roles';
import { RoleGuard } from '../auth/RoleGuard';
import { RoleList } from './RoleList';
import { UserList } from './UserList';
import { Tabs } from '../ui/Tabs';

export function RoleManagement() {
  const { users, updateUserRole, isLoading } = useAuthStore();
  const [activeTab, setActiveTab] = useState('users');

  const handleRoleUpdate = async (userId: string, newRole: string) => {
    try {
      await updateUserRole(userId, newRole);
    } catch (error) {
      console.error('Failed to update user role:', error);
    }
  };

  const tabs = [
    { id: 'users', label: 'User Management' },
    { id: 'roles', label: 'Role Overview' },
  ];

  return (
    <RoleGuard
      requiredPermission={PERMISSIONS.MANAGE_USERS.id}
      fallback={
        <div className="text-center p-4 bg-yellow-50 rounded-lg">
          <p className="text-yellow-700">You don't have permission to manage roles.</p>
        </div>
      }
    >
      <div className="bg-white shadow-sm rounded-lg p-6">
        <h2 className="text-xl font-semibold mb-6">Role Management</h2>
        
        <Tabs
          tabs={tabs}
          activeTab={activeTab}
          onChange={setActiveTab}
          className="mb-6"
        />

        {activeTab === 'users' ? (
          <UserList
            users={users}
            onRoleUpdate={handleRoleUpdate}
            isLoading={isLoading}
          />
        ) : (
          <RoleList />
        )}
      </div>
    </RoleGuard>
  );
}