import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useAuthStore } from '../store/auth';
import { FaPlay, FaUsers, FaChartLine, FaShieldAlt } from 'react-icons/fa';

// SEO metadata
const metadata = {
  title: 'SVIZ.live - Advanced Surgical Live Streaming Platform',
  description: 'Experience seamless surgical video live streaming with SVIZ.live. Advanced features for medical professionals, secure streaming, and real-time collaboration.',
  keywords: 'surgical streaming, medical video LIve Streaming platform, secure medical streaming, surgical education',
};

export function LandingPage() {
  const { user } = useAuthStore();

  useEffect(() => {
    // Update metadata
    document.title = metadata.title;
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', metadata.description);
    }
  }, []);

  const fadeIn = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white">
      {/* Header/Navigation */}
      <header className="fixed top-0 w-full bg-black/50 backdrop-blur-lg z-50">
        <nav className="container mx-auto px-6 py-4 flex justify-between items-center">
          <Link to="/" className="flex items-center space-x-2">
            <img src="/logo.svg" alt="SVIZ.live Logo" className="h-8 w-8" />
            <span className="text-xl font-bold bg-gradient-to-r from-blue-400 to-teal-400 bg-clip-text text-transparent">
              SVIZ.live
            </span>
          </Link>
          <div className="flex items-center space-x-6">
            {user ? (
              <div className="flex items-center space-x-4">
                <Link 
                  to="/dashboard" 
                  className="px-4 py-2 rounded-lg bg-blue-500 hover:bg-blue-600 transition-colors"
                >
                  Dashboard
                </Link>
                <Link 
                  to="/profile" 
                  className="px-4 py-2 rounded-lg border border-gray-600 hover:border-gray-400 transition-colors"
                >
                  Profile
                </Link>
              </div>
            ) : (
              <div className="flex items-center space-x-4">
                <Link 
                  to="/login" 
                  className="px-4 py-2 rounded-lg border border-gray-600 hover:border-gray-400 transition-colors"
                >
                  Sign In
                </Link>
                <Link 
                  to="/register" 
                  className="px-4 py-2 rounded-lg bg-blue-500 hover:bg-blue-600 transition-colors"
                >
                  Sign Up
                </Link>
              </div>
            )}
          </div>
        </nav>
      </header>

      {/* Hero Section */}
      <motion.section 
        className="pt-32 pb-20 px-6"
        initial="initial"
        animate="animate"
        variants={fadeIn}
      >
        <div className="container mx-auto text-center">
          <motion.h1 
            className="text-5xl md:text-6xl font-bold mb-6 bg-gradient-to-r from-blue-400 to-teal-400 bg-clip-text text-transparent"
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            Advanced Surgical Live Streaming Platform
          </motion.h1>
          <motion.p 
            className="text-xl text-gray-400 mb-8 max-w-2xl mx-auto"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            Stream, collaborate, and learn with our cutting-edge platform designed for medical professionals.
          </motion.p>
          <motion.div 
            className="flex justify-center space-x-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.4 }}
          >
            <Link 
              to="/register" 
              className="px-8 py-3 rounded-lg bg-blue-500 hover:bg-blue-600 transition-colors text-lg font-semibold"
            >
              Get Started
            </Link>
            <Link 
              to="/demo" 
              className="px-8 py-3 rounded-lg border border-gray-600 hover:border-gray-400 transition-colors text-lg font-semibold"
            >
              Watch Demo
            </Link>
          </motion.div>
        </div>
      </motion.section>

      {/* Features Section */}
      <section className="py-20 px-6 bg-gray-900/50">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold text-center mb-12">Why Choose SVIZ.live?</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {[
              {
                icon: <FaPlay className="text-4xl text-blue-400" />,
                title: "High-Quality Streaming",
                description: "Crystal clear video quality with minimal latency for precise surgical visualization."
              },
              {
                icon: <FaUsers className="text-4xl text-teal-400" />,
                title: "Real-time Collaboration",
                description: "Connect with colleagues worldwide for consultations and educational purposes."
              },
              {
                icon: <FaChartLine className="text-4xl text-purple-400" />,
                title: "Advanced Analytics",
                description: "Gain insights from comprehensive streaming and viewer analytics."
              },
              {
                icon: <FaShieldAlt className="text-4xl text-green-400" />,
                title: "Secure Platform",
                description: "HIPAA-compliant security measures to protect sensitive medical data."
              }
            ].map((feature, index) => (
              <motion.div
                key={index}
                className="p-6 rounded-xl bg-gray-800/50 backdrop-blur-lg border border-gray-700"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                viewport={{ once: true }}
              >
                <div className="mb-4">{feature.icon}</div>
                <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                <p className="text-gray-400">{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20 px-6">
        <div className="container mx-auto text-center">
          <motion.div
            className="p-12 rounded-2xl bg-gradient-to-r from-blue-600/20 to-teal-600/20 backdrop-blur-lg border border-gray-700"
            initial={{ opacity: 0, scale: 0.95 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
          >
            <h2 className="text-3xl font-bold mb-4">Ready to Transform Your Surgical Practice?</h2>
            <p className="text-xl text-gray-400 mb-8 max-w-2xl mx-auto">
              Join thousands of medical professionals who trust SVIZ.live for their streaming needs.
            </p>
            <Link 
              to="/register" 
              className="px-8 py-3 rounded-lg bg-blue-500 hover:bg-blue-600 transition-colors text-lg font-semibold inline-block"
            >
              Start Free Trial
            </Link>
          </motion.div>
        </div>
      </section>

      {/* Contact Section */}
      <section className="py-20 px-6 bg-gray-900/50">
        <div className="container mx-auto max-w-4xl">
          <h2 className="text-3xl font-bold text-center mb-12">Get in Touch</h2>
          <motion.form 
            className="space-y-6"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium mb-2">Name</label>
                <input 
                  type="text" 
                  className="w-full px-4 py-2 rounded-lg bg-gray-800 border border-gray-700 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 outline-none transition-colors"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-2">Email</label>
                <input 
                  type="email" 
                  className="w-full px-4 py-2 rounded-lg bg-gray-800 border border-gray-700 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 outline-none transition-colors"
                />
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium mb-2">Message</label>
              <textarea 
                rows={4}
                className="w-full px-4 py-2 rounded-lg bg-gray-800 border border-gray-700 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 outline-none transition-colors"
              />
            </div>
            <button 
              type="submit" 
              className="w-full px-8 py-3 rounded-lg bg-blue-500 hover:bg-blue-600 transition-colors text-lg font-semibold"
            >
              Send Message
            </button>
          </motion.form>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-background-secondary border-t border-border">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            {/* Company Info */}
            <div className="col-span-1">
              <Link to="/" className="text-xl font-bold bg-gradient-to-r from-blue-400 to-teal-400 bg-clip-text text-transparent">
                SVIZ.live
              </Link>
              <p className="mt-4 text-text-secondary">
                Advanced surgical Live Streaming platform for medical professionals.
              </p>
            </div>

            {/* Product Links */}
            <div>
              <h3 className="text-sm font-semibold uppercase tracking-wider">Product</h3>
              <ul className="mt-4 space-y-4">
                <li>
                  <Link to="/features" className="text-text-secondary hover:text-text transition-colors">
                    Features
                  </Link>
                </li>
                <li>
                  <Link to="/security" className="text-text-secondary hover:text-text transition-colors">
                    Security
                  </Link>
                </li>
                <li>
                  <Link to="/demo" className="text-text-secondary hover:text-text transition-colors">
                    Request Demo
                  </Link>
                </li>
              </ul>
            </div>

            {/* Company Links */}
            <div>
              <h3 className="text-sm font-semibold uppercase tracking-wider">Company</h3>
              <ul className="mt-4 space-y-4">
                <li>
                  <Link to="/about" className="text-text-secondary hover:text-text transition-colors">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/contact" className="text-text-secondary hover:text-text transition-colors">
                    Contact
                  </Link>
                </li>
                <li>
                  <a href="https://blog.sviz.live" className="text-text-secondary hover:text-text transition-colors">
                    Blog
                  </a>
                </li>
              </ul>
            </div>

            {/* Legal Links */}
            <div>
              <h3 className="text-sm font-semibold uppercase tracking-wider">Legal</h3>
              <ul className="mt-4 space-y-4">
                <li>
                  <Link to="/privacy" className="text-text-secondary hover:text-text transition-colors">
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to="/terms" className="text-text-secondary hover:text-text transition-colors">
                    Terms of Service
                  </Link>
                </li>
                <li>
                  <Link to="/security-policy" className="text-text-secondary hover:text-text transition-colors">
                    Security Policy
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="mt-8 pt-8 border-t border-border">
            <p className="text-center text-text-secondary">
              &copy; {new Date().getFullYear()} SVIZ.live. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}
