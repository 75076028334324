import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { UserPlus, Search } from 'lucide-react';
import { Button } from '../ui/Button';
import { Input } from '../ui/Input';
import { useGroupStore } from '../../store/group';

const inviteSchema = z.object({
  email: z.string().email('Please enter a valid email address'),
});

type InviteFormData = z.infer<typeof inviteSchema>;

interface InviteMemberProps {
  groupId: string;
}

export function InviteMember({ groupId }: InviteMemberProps) {
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const { sendInvitation, isLoading } = useGroupStore();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<InviteFormData>({
    resolver: zodResolver(inviteSchema),
  });

  const onSubmit = async (data: InviteFormData) => {
    try {
      setError(null);
      setSuccess(null);
      await sendInvitation(groupId, data.email);
      setSuccess(`Successfully sent invitation to ${data.email}`);
      reset(); // Clear the form
    } catch (error: any) {
      setError(error.message || 'Failed to send invitation');
    }
  };

  return (
    <div className="bg-gradient-to-br from-gray-800/30 to-gray-900/30 rounded-xl border border-gray-700/50 backdrop-blur-sm p-6">
      <div className="flex items-center space-x-2 mb-6">
        <UserPlus className="h-5 w-5 text-gray-400" />
        <h2 className="text-xl font-light tracking-wide text-white/90">Invite Members</h2>
      </div>

      {error && (
        <div className="mb-4 p-3 rounded-md bg-red-50/10 border border-red-200/20">
          <p className="text-sm text-red-400">{error}</p>
        </div>
      )}

      {success && (
        <div className="mb-4 p-3 rounded-md bg-green-50/10 border border-green-200/20">
          <p className="text-sm text-green-400">{success}</p>
        </div>
      )}

      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <div className="relative">
          <Input
            {...register('email')}
            type="email"
            placeholder="Enter member's email address"
            label="Email Address"
            error={errors.email?.message}
            disabled={isLoading}
          />
          <Search className="absolute right-3 top-9 h-4 w-4 text-gray-400" />
        </div>

        <Button
          type="submit"
          isLoading={isLoading}
          className="w-full bg-blue-600 hover:bg-blue-700"
        >
          <UserPlus className="h-4 w-4 mr-2" />
          Send Invitation
        </Button>
      </form>

      <div className="mt-4 pt-4 border-t border-gray-700/50">
        <p className="text-sm text-gray-400">
          Only users registered on the platform can be invited to the group.
          The user will receive an invitation and must accept it to join the group.
        </p>
      </div>
    </div>
  );
}