import React, { useState } from 'react';
import { FiX, FiDownload, FiZoomIn, FiZoomOut } from 'react-icons/fi';

interface PreviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  fileName: string;
  url: string;
  fileType: 'image' | 'video' | 'audio' | 'pdf' | 'text' | 'other';
}

const PreviewModal: React.FC<PreviewModalProps> = ({
  isOpen,
  onClose,
  fileName,
  url,
  fileType
}) => {
  const [zoom, setZoom] = useState(1);
  
  if (!isOpen) return null;

  const handleZoomIn = () => setZoom(prev => Math.min(prev + 0.25, 3));
  const handleZoomOut = () => setZoom(prev => Math.max(prev - 0.25, 0.5));
  
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="fixed inset-0 bg-black/90 backdrop-blur-sm z-50 flex items-center justify-center p-4">
      <div className="bg-gray-900 rounded-lg max-w-6xl w-full max-h-[90vh] overflow-hidden flex flex-col border border-gray-700">
        {/* Header */}
        <div className="flex justify-between items-center p-4 border-b border-gray-700">
          <h3 className="text-lg font-semibold text-white truncate">{fileName}</h3>
          <div className="flex items-center gap-2">
            {(fileType === 'image') && (
              <>
                <button
                  onClick={handleZoomOut}
                  className="p-2 hover:bg-gray-700 rounded-full text-gray-300 hover:text-white transition-colors"
                  title="Zoom out"
                >
                  <FiZoomOut className="w-5 h-5" />
                </button>
                <button
                  onClick={handleZoomIn}
                  className="p-2 hover:bg-gray-700 rounded-full text-gray-300 hover:text-white transition-colors"
                  title="Zoom in"
                >
                  <FiZoomIn className="w-5 h-5" />
                </button>
              </>
            )}
            <button
              onClick={handleDownload}
              className="p-2 hover:bg-gray-700 rounded-full text-gray-300 hover:text-white transition-colors"
              title="Download file"
            >
              <FiDownload className="w-5 h-5" />
            </button>
            <button
              onClick={onClose}
              className="p-2 hover:bg-gray-700 rounded-full text-gray-300 hover:text-white transition-colors"
            >
              <FiX className="w-6 h-6" />
            </button>
          </div>
        </div>
        
        {/* Content */}
        <div className="flex-1 overflow-auto p-4 flex items-center justify-center bg-gray-800">
          {fileType === 'image' && (
            <div className="relative overflow-auto max-h-full">
              <img
                src={url}
                alt={fileName}
                className="max-w-none"
                style={{
                  transform: `scale(${zoom})`,
                  transition: 'transform 0.2s ease-in-out'
                }}
              />
            </div>
          )}
          
          {fileType === 'video' && (
            <video
              src={url}
              controls
              className="max-w-full max-h-[70vh]"
              autoPlay
            >
              Your browser does not support the video tag.
            </video>
          )}

          {fileType === 'audio' && (
            <div className="w-full max-w-2xl bg-gray-700 p-6 rounded-lg">
              <audio
                src={url}
                controls
                className="w-full"
                autoPlay
              >
                Your browser does not support the audio tag.
              </audio>
            </div>
          )}

          {fileType === 'pdf' && (
            <iframe
              src={url}
              title={fileName}
              className="w-full h-full bg-white rounded-lg"
            />
          )}

          {(fileType === 'text' || fileType === 'other') && (
            <div className="flex flex-col items-center justify-center text-center text-gray-300 space-y-4">
              <p>This file type cannot be previewed directly.</p>
              <button
                onClick={handleDownload}
                className="px-4 py-2 bg-blue-600 hover:bg-blue-700 rounded-lg text-white transition-colors flex items-center gap-2"
              >
                <FiDownload className="w-4 h-4" />
                Download File
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PreviewModal;
