import { Sidebar } from './Sidebar';
import { Footer } from './Footer';

interface LayoutProps {
  children: React.ReactNode;
}

export function Layout({ children }: LayoutProps) {
  return (
    <div className="flex justify-center items-center min-h-screen bg-background">
      <div className="flex w-full min-h-screen">
        <Sidebar />
        <div className="flex-1 ml-0 lg:ml-4 flex flex-col">
          <main className="flex-1 flex justify-center items-center">
            <div className="w-full px-4 py-8">
              {children}
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </div>
  );
}
