interface ErrorMessage {
  title: string;
  message: string;
  type: 'error' | 'warning';
}

export const getErrorMessage = (code: string): ErrorMessage => {
  switch (code) {
    // Storage related errors
    case 'storage/missing-credentials':
      return {
        title: 'Configuration Error',
        message: 'The storage system is not properly configured. Please contact support.',
        type: 'error'
      };
    case 'storage/bucket-not-found':
      return {
        title: 'Storage Error',
        message: 'Unable to access storage system. Please try again later or contact support.',
        type: 'error'
      };
    case 'storage/access-denied':
      return {
        title: 'Access Denied',
        message: 'Unable to create storage space. Please contact support for assistance.',
        type: 'error'
      };
    case 'storage/network-error':
      return {
        title: 'Network Error',
        message: 'Unable to connect to storage system. Please check your internet connection and try again.',
        type: 'warning'
      };
    case 'storage/invalid-credentials':
      return {
        title: 'Authentication Error',
        message: 'Storage system authentication failed. Please contact support.',
        type: 'error'
      };

    // User creation related errors
    case 'user-creation/email-in-use':
      return {
        title: 'Email Already Registered',
        message: 'An account with this email already exists. Please sign in or use a different email.',
        type: 'warning'
      };
    case 'user-creation/document-error':
      return {
        title: 'Account Creation Failed',
        message: 'Unable to complete account setup. Please try again later.',
        type: 'error'
      };
    case 'user-creation/storage-error':
      return {
        title: 'Storage Setup Failed',
        message: 'Unable to set up your storage space. Please try again or contact support.',
        type: 'error'
      };

    // Default error
    default:
      return {
        title: 'Unexpected Error',
        message: 'An unexpected error occurred. Please try again later or contact support.',
        type: 'error'
      };
  }
};
