import { BrowserRouter as Router, Routes, Route, Outlet, Navigate } from 'react-router-dom';
import { useAuth } from './hooks/useAuth';
import { useEffect } from 'react';
import { useThemeStore } from './store/theme';
import { Navbar } from './components/layout/Navbar';
import { LoginForm } from './components/auth/LoginForm';
import { SignupPage } from './pages/SignupPage';
import { RoleManagement } from './components/admin/RoleManagement';
import { RoleGuard } from './components/auth/RoleGuard';
import { AuthGuard } from './components/auth/AuthGuard';
import { DemoAccountsPage } from './pages/DemoAccountsPage';
import { GroupsPage } from './pages/GroupsPage';
import { GroupDetailPage } from './pages/GroupDetailPage';
import { CreateGroupPage } from './pages/CreateGroupPage';
import { CreateStreamPage } from './pages/CreateStreamPage';
import { StreamsPage } from './pages/StreamsPage';
import { StreamPage } from './pages/StreamPage';
import { StreamHistoryPage } from './pages/StreamHistoryPage';
import { PERMISSIONS } from './config/roles';
import { HomePage } from './pages/HomePage';
import { LandingPage } from './pages/LandingPage';
import FileManager from './components/FileManager/FileManager';
import { EmailVerification } from './components/auth/EmailVerification';
import { DeviceStreamPage } from './pages/DeviceStreamPage';
import { SettingsPage } from './pages/SettingsPage';
import { AdminDashboardPage } from './pages/admin/DashboardPage';
import { AdminUsersPage } from './pages/admin/UsersPage';
import { AdminStreamsPage } from './pages/admin/StreamsPage';
import { CreateAdminPage } from './pages/admin/CreateAdminPage';
import { FirstAdminPage } from './pages/FirstAdminPage';
import { AnalyticsPage } from './pages/admin/AnalyticsPage';
import { ContentModerationPage } from './pages/admin/ContentModerationPage';
import { AuditLogsPage } from './pages/admin/AuditLogsPage';
import { ReportsPage } from './pages/admin/ReportsPage';
import { AdminGroupsPage } from './pages/admin/AdminGroupsPage';
import { AdminGroupManagementPage } from './pages/admin/AdminGroupManagementPage';
import { Layout } from './components/layout/Layout';
import { ProfilePage } from './pages/ProfilePage';
import FeaturesPage from './pages/FeaturesPage';
import SecurityPage from './pages/SecurityPage';
import DemoPage from './pages/DemoPage';
import PrivacyPage from './pages/PrivacyPage';
import TermsPage from './pages/TermsPage';
import SecurityPolicyPage from './pages/SecurityPolicyPage';
import { Dashboard } from './components/dashboard/Dashboard';

export default function App() {
  useAuth();
  const { theme } = useThemeStore();

  useEffect(() => {
    // Initialize theme on app load
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [theme]);

  const { isLoading } = useAuth();

  if (isLoading) {
    return (
      <div className="min-h-screen gradient-bg flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <Router>
      <div className="min-h-screen gradient-bg">
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/features" element={<FeaturesPage />} />
          <Route path="/security" element={<SecurityPage />} />
          <Route path="/demo" element={<DemoPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/security-policy" element={<SecurityPolicyPage />} />
          <Route path="/login" element={
            <div className="max-w-md mx-auto bg-gray-800/50 backdrop-blur-lg p-8 rounded-lg shadow-xl border border-gray-700">
              <h2 className="text-2xl font-bold text-white mb-6">Sign In</h2>
              <LoginForm />
            </div>
          } />
          <Route path="/register" element={
            <div className="max-w-md mx-auto bg-gray-800/50 backdrop-blur-lg p-8 rounded-lg shadow-xl border border-gray-700">
              <h2 className="text-2xl font-bold text-white mb-6">Create Account</h2>
              <SignupPage />
            </div>
          } />
          <Route path="/verify-email" element={<EmailVerification />} />
          <Route path="/first-admin" element={<FirstAdminPage />} />

          {/* Protected Routes */}
          <Route element={
            <AuthGuard>
              <Layout>
                <Outlet />
              </Layout>
            </AuthGuard>
          }>
            {/* Dashboard Route */}
            <Route path="/dashboard" element={<Dashboard />} />

            {/* Admin Routes */}
            <Route path="/admin" element={<AdminDashboardPage />} />
            <Route path="/admin/users" element={<AdminUsersPage />} />
            <Route path="/admin/streams" element={<AdminStreamsPage />} />
            <Route path="/admin/create-admin" element={<CreateAdminPage />} />
            <Route path="/admin/analytics" element={<AnalyticsPage />} />
            <Route path="/admin/moderation" element={<ContentModerationPage />} />
            <Route path="/admin/audit-logs" element={<AuditLogsPage />} />
            <Route path="/admin/reports" element={<ReportsPage />} />
            <Route path="/admin/groups" element={<AdminGroupsPage />} />
            <Route path="/admin/groups/:groupId" element={<AdminGroupManagementPage />} />
            <Route path="/admin/roles" element={
              <RoleGuard
                requiredPermission={PERMISSIONS.MANAGE_USERS.id}
                fallback={
                  <div className="text-center py-12">
                    <h2 className="text-2xl font-semibold text-white">Access Denied</h2>
                    <p className="text-gray-400 mt-2">
                      You don't have permission to access this page.
                    </p>
                  </div>
                }
              >
                <RoleManagement />
              </RoleGuard>
            } />
            {/* User Routes */}
            <Route path="/home" element={<LandingPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/groups" element={<GroupsPage />} />
            <Route path="/groups/create" element={<CreateGroupPage />} />
            <Route path="/groups/:groupId" element={<GroupDetailPage />} />
            <Route path="/streams" element={<StreamsPage />} />
            <Route path="/stream/create" element={<CreateStreamPage />} />
            <Route path="/stream/:streamId" element={<StreamPage />} />
            <Route path="/stream/history" element={<StreamHistoryPage />} />
            <Route path="/device-stream" element={<DeviceStreamPage />} />
            <Route path="/files" element={<FileManager />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/demo-accounts" element={<DemoAccountsPage />} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
}