import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../lib/firebase';
import { resendVerificationEmail } from '../../services/auth';

const COOLDOWN_DURATION = 60; // 60 seconds cooldown

export const EmailVerification: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [cooldown, setCooldown] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate('/login');
      } else if (user.emailVerified) {
        navigate('/');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  // Cooldown timer effect
  useEffect(() => {
    if (cooldown <= 0) return;

    const timer = setInterval(() => {
      setCooldown((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [cooldown]);

  const checkVerificationStatus = async () => {
    setLoading(true);
    setError(null);
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('No user found. Please sign in again.');
      }
      // Reload the user to get the latest emailVerified status
      await user.reload();
      if (user.emailVerified) {
        navigate('/');
      } else {
        setError('Email not verified yet. Please check your inbox and click the verification link.');
      }
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleResendEmail = async () => {
    if (cooldown > 0) {
      setError(`Please wait ${cooldown} seconds before requesting another email.`);
      return;
    }

    setLoading(true);
    setError(null);
    setSuccess(false);
    
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('No user found. Please sign in again.');
      }
      await resendVerificationEmail(user);
      setSuccess(true);
      setCooldown(COOLDOWN_DURATION);
    } catch (err: any) {
      if (err.code === 'auth/too-many-requests') {
        setError('Too many email requests. Please wait a few minutes before trying again.');
        setCooldown(COOLDOWN_DURATION * 5); // Longer cooldown for rate limit
      } else {
        setError(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Email Verification Required
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Please check your email and click the verification link to continue.
          </p>
        </div>
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
            <span className="block sm:inline">{error}</span>
          </div>
        )}
        {success && (
          <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
            <span className="block sm:inline">Verification email sent successfully!</span>
          </div>
        )}
        <div className="flex flex-col items-center space-y-4">
          <button
            onClick={checkVerificationStatus}
            disabled={loading}
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
          >
            {loading ? 'Checking...' : 'Check Verification Status'}
          </button>
          <button
            onClick={handleResendEmail}
            disabled={loading || cooldown > 0}
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50"
          >
            {loading ? 'Sending...' : cooldown > 0 ? `Resend Email (${cooldown}s)` : 'Resend Verification Email'}
          </button>
          <button
            onClick={() => navigate('/login')}
            className="text-sm text-indigo-600 hover:text-indigo-500"
          >
            Return to Login
          </button>
        </div>
      </div>
    </div>
  );
};
