import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useAuthStore } from '../store/auth';
import { Button } from '../components/ui/Button';
import { Input } from '../components/ui/Input';

const createStreamSchema = z.object({
  title: z.string()
    .min(3, 'Title must be at least 3 characters')
    .max(100, 'Title must be less than 100 characters'),
  description: z.string()
    .min(10, 'Description must be at least 10 characters')
    .max(500, 'Description must be less than 500 characters'),
  visibility: z.enum(['public', 'private', 'group'])
});

type CreateStreamFormData = z.infer<typeof createStreamSchema>;

export function CreateStreamPage() {
  const { user } = useAuthStore();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<CreateStreamFormData>({
    resolver: zodResolver(createStreamSchema),
    defaultValues: {
      visibility: 'public'
    }
  });

  const onSubmit = async (data: CreateStreamFormData) => {
    try {
      // TODO: Implement stream creation logic
      console.log('Creating stream:', data);
      navigate('/streams');
    } catch (error) {
      console.error('Failed to create stream:', error);
    }
  };

  if (!user) {
    return (
      <div className="text-center py-12">
        <h2 className="text-2xl font-light text-white">Access Denied</h2>
        <p className="text-gray-400 mt-2">Please sign in to create a stream.</p>
      </div>
    );
  }

  return (
    <div className="max-w-2xl mx-auto">
      <div className="bg-gray-800/50 backdrop-blur-sm rounded-xl border border-gray-700/50 p-8">
        <h2 className="text-2xl font-light text-white mb-6">Create New Stream</h2>
        
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <Input
            {...register('title')}
            label="Stream Title"
            placeholder="Enter a title for your stream"
            error={errors.title?.message}
          />

          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-200">
              Description
            </label>
            <textarea
              {...register('description')}
              className="w-full px-3 py-2 bg-gray-900/50 border border-gray-700 rounded-md text-gray-200 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
              rows={4}
              placeholder="Describe what your stream will be about"
            />
            {errors.description && (
              <p className="text-sm text-red-500">{errors.description.message}</p>
            )}
          </div>

          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-200">
              Visibility
            </label>
            <select
              {...register('visibility')}
              className="w-full px-3 py-2 bg-gray-900/50 border border-gray-700 rounded-md text-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="public">Public</option>
              <option value="private">Private</option>
              <option value="group">Group Only</option>
            </select>
            {errors.visibility && (
              <p className="text-sm text-red-500">{errors.visibility.message}</p>
            )}
          </div>

          <div className="flex justify-end space-x-4">
            <Button
              type="button"
              variant="outline"
              onClick={() => navigate('/streams')}
              className="border-gray-700 text-gray-300 hover:bg-gray-800"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              isLoading={isSubmitting}
              className="bg-blue-600 hover:bg-blue-700"
            >
              Create Stream
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
