import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Settings, Trash2 } from 'lucide-react';
import type { Group } from '../../types/group';
import { useGroupStore } from '../../store/group';
import { useAuthStore } from '../../store/auth';
import { Button } from '../ui/Button';
import { Input } from '../ui/Input';
import { db } from '../../lib/firebase';
import { doc, updateDoc } from 'firebase/firestore';

interface GroupSettingsProps {
  group: Group;
}

const settingsSchema = z.object({
  name: z.string().min(2, 'Name must be at least 2 characters'),
  description: z.string().min(20, 'Description must be at least 20 characters'),
  isPrivate: z.boolean(),
});

type SettingsFormData = z.infer<typeof settingsSchema>;

export function GroupSettings({ group }: GroupSettingsProps) {
  const { updateGroup, isLoading } = useGroupStore();
  const { user } = useAuthStore();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SettingsFormData>({
    resolver: zodResolver(settingsSchema),
    defaultValues: {
      name: group.name,
      description: group.description,
      isPrivate: group.isPrivate,
    },
  });

  const onSubmit = async (data: SettingsFormData) => {
    if (!user?.id) return;

    try {
      const groupRef = doc(db, 'groups', group.id);
      await updateDoc(groupRef, {
        ...data,
        updatedAt: new Date(),
      });

      updateGroup(group.id, data);
    } catch (err) {
      console.error('Error updating group:', err);
    }
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this group? This action cannot be undone.')) {
      try {
        // Since deleteGroup is not available in the store, we'll use leaveGroup for now
        // TODO: Implement deleteGroup functionality in the group store
        const { leaveGroup } = useGroupStore.getState();
        await leaveGroup(group.id);
      } catch (error) {
        console.error('Failed to delete group:', error);
      }
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center space-x-2">
        <Settings className="h-5 w-5 text-gray-400" />
        <h2 className="text-lg font-medium text-white">Group Settings</h2>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-200">
            Group Name
          </label>
          <Input
            id="name"
            type="text"
            {...register('name')}
            className="mt-1"
            error={errors.name?.message}
          />
        </div>

        <div>
          <label htmlFor="description" className="block text-sm font-medium text-gray-200">
            Description
          </label>
          <Input
            id="description"
            type="text"
            {...register('description')}
            className="mt-1"
            error={errors.description?.message}
          />
        </div>

        <div className="flex items-center space-x-2">
          <input
            type="checkbox"
            id="isPrivate"
            {...register('isPrivate')}
            className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
          />
          <label htmlFor="isPrivate" className="text-sm text-gray-200">
            Make this group private
          </label>
        </div>

        <div className="pt-4">
          <Button type="submit" isLoading={isLoading}>
            Save Changes
          </Button>
        </div>
      </form>

      <div className="border-t border-gray-700 pt-6">
        <div className="flex items-center space-x-2">
          <Trash2 className="h-5 w-5 text-red-500" />
          <h3 className="text-lg font-medium text-white">Danger Zone</h3>
        </div>

        <p className="mt-2 text-sm text-gray-400">
          Once you delete a group, there is no going back. Please be certain.
        </p>

        <div className="mt-4">
          <Button variant="destructive" onClick={handleDelete} isLoading={isLoading}>
            Delete Group
          </Button>
        </div>
      </div>
    </div>
  );
}