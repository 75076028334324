import React from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Button } from '../components/ui/Button';
import { Shield, Lock, Server, AlertTriangle } from 'lucide-react';

export default function SecurityPolicyPage() {
  return (
    <>
      <Helmet>
        <title>Security Policy - SVIZ.live</title>
        <meta
          name="description"
          content="SVIZ.live security policy - Learn about our comprehensive security measures protecting your surgical video content."
        />
      </Helmet>

      <div className="min-h-screen bg-background">
        {/* Navigation */}
        <nav className="fixed top-0 left-0 right-0 z-50 bg-background/80 backdrop-blur-lg border-b border-border">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between items-center h-16">
              <Link to="/" className="text-2xl font-bold bg-gradient-to-r from-primary to-blue-600 bg-clip-text text-transparent">
                SVIZ.live
              </Link>
              <Button variant="outline" size="sm" asChild>
                <Link to="/">Back to Home</Link>
              </Button>
            </div>
          </div>
        </nav>

        {/* Main Content */}
        <main className="pt-24 pb-16 px-4 sm:px-6 lg:px-8 max-w-4xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h1 className="text-4xl font-bold mb-8">Security Policy</h1>
            
            <div className="space-y-8">
              <section>
                <div className="flex items-center gap-2 mb-4">
                  <Shield className="w-6 h-6 text-primary" />
                  <h2 className="text-2xl font-semibold">Data Protection</h2>
                </div>
                <p className="text-text-secondary mb-4">
                  Our comprehensive data protection includes:
                </p>
                <ul className="list-disc list-inside space-y-2 text-text-secondary">
                  <li>End-to-end encryption for all video content</li>
                  <li>Multi-factor authentication</li>
                  <li>Regular security audits</li>
                  <li>Automated threat detection</li>
                </ul>
              </section>

              <section>
                <div className="flex items-center gap-2 mb-4">
                  <Lock className="w-6 h-6 text-primary" />
                  <h2 className="text-2xl font-semibold">Access Control</h2>
                </div>
                <p className="text-text-secondary mb-4">
                  We implement strict access controls:
                </p>
                <ul className="list-disc list-inside space-y-2 text-text-secondary">
                  <li>Role-based access management</li>
                  <li>Session monitoring and timeout</li>
                  <li>IP whitelisting options</li>
                  <li>Detailed access logs</li>
                </ul>
              </section>

              <section>
                <div className="flex items-center gap-2 mb-4">
                  <Server className="w-6 h-6 text-primary" />
                  <h2 className="text-2xl font-semibold">Infrastructure Security</h2>
                </div>
                <p className="text-text-secondary mb-4">
                  Our infrastructure is protected by:
                </p>
                <ul className="list-disc list-inside space-y-2 text-text-secondary">
                  <li>HIPAA-compliant cloud storage</li>
                  <li>Regular vulnerability assessments</li>
                  <li>DDoS protection</li>
                  <li>24/7 monitoring</li>
                </ul>
              </section>

              <section>
                <div className="flex items-center gap-2 mb-4">
                  <AlertTriangle className="w-6 h-6 text-primary" />
                  <h2 className="text-2xl font-semibold">Incident Response</h2>
                </div>
                <p className="text-text-secondary mb-4">
                  Our incident response includes:
                </p>
                <ul className="list-disc list-inside space-y-2 text-text-secondary">
                  <li>24/7 security team</li>
                  <li>Automated alerts</li>
                  <li>Incident documentation</li>
                  <li>Regular response drills</li>
                </ul>
              </section>
            </div>
          </motion.div>
        </main>

        {/* Footer */}
        <footer className="bg-background-secondary border-t border-border">
          <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
              {/* Company Info */}
              <div className="col-span-1">
                <Link to="/" className="text-2xl font-bold bg-gradient-to-r from-primary to-blue-600 bg-clip-text text-transparent">
                  SVIZ.live
                </Link>
                <p className="mt-4 text-text-secondary">
                  Advanced surgical video platform for medical professionals.
                </p>
              </div>

              {/* Product Links */}
              <div>
                <h3 className="text-sm font-semibold uppercase tracking-wider">Product</h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link to="/features" className="text-text-secondary hover:text-text transition-colors">
                      Features
                    </Link>
                  </li>
                  <li>
                    <Link to="/security" className="text-text-secondary hover:text-text transition-colors">
                      Security
                    </Link>
                  </li>
                  <li>
                    <Link to="/demo" className="text-text-secondary hover:text-text transition-colors">
                      Request Demo
                    </Link>
                  </li>
                </ul>
              </div>

              {/* Company Links */}
              <div>
                <h3 className="text-sm font-semibold uppercase tracking-wider">Company</h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link to="/about" className="text-text-secondary hover:text-text transition-colors">
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" className="text-text-secondary hover:text-text transition-colors">
                      Contact
                    </Link>
                  </li>
                  <li>
                    <a href="https://blog.sviz.live" className="text-text-secondary hover:text-text transition-colors">
                      Blog
                    </a>
                  </li>
                </ul>
              </div>

              {/* Legal Links */}
              <div>
                <h3 className="text-sm font-semibold uppercase tracking-wider">Legal</h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link to="/privacy" className="text-text-secondary hover:text-text transition-colors">
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link to="/terms" className="text-text-secondary hover:text-text transition-colors">
                      Terms of Service
                    </Link>
                  </li>
                  <li>
                    <Link to="/security-policy" className="text-text-secondary hover:text-text transition-colors">
                      Security Policy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="mt-8 pt-8 border-t border-border">
              <p className="text-center text-text-secondary">
                &copy; {new Date().getFullYear()} SVIZ.live. All rights reserved.
              </p>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}
