import React, { useState, useEffect, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../store/auth';
import { RoleGuard } from '../../components/auth/RoleGuard';
import { PERMISSIONS, ROLES } from '../../config/roles';
import { Search, Filter, MoreVertical, Mail, CheckCircle, XCircle } from 'lucide-react';
import { Select } from '../../components/ui/Select';
import { manuallyVerifyEmail, getEmailVerificationStatus } from '../../services/auth';
import { auth } from '../../lib/firebase';
import { sendEmailVerification } from 'firebase/auth';
import { getDoc, doc, getDocs, collection, updateDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';

interface UserFilters {
  role?: string;
  status?: string;
  search: string;
}

interface User {
  id: string;
  name: string;
  email: string;
  role: string;
  emailVerified?: boolean;
}

interface UserWithVerification extends User {
  emailVerified: boolean;
}

interface SelectOption {
  value: string;
  label: string;
}

export function AdminUsersPage() {
  const navigate = useNavigate();
  const [usersWithVerification, setUsersWithVerification] = useState<UserWithVerification[]>([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<UserFilters>({
    search: '',
    role: undefined,
    status: undefined,
  });

  // Fetch users from Firestore
  useEffect(() => {
    const fetchUsers = async () => {
      const usersRef = collection(db, 'users');
      const usersSnapshot = await getDocs(usersRef);
      const usersData = usersSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as User[];

      const usersWithVerificationStatus = await Promise.all(
        usersData.map(async (user) => ({
          ...user,
          emailVerified: await getEmailVerificationStatus(user.id)
        }))
      );

      setUsersWithVerification(usersWithVerificationStatus);
    };

    fetchUsers();
  }, []);

  const filteredUsers = usersWithVerification.filter((user) => {
    if (filters.search && !user.name.toLowerCase().includes(filters.search.toLowerCase()) &&
        !user.email.toLowerCase().includes(filters.search.toLowerCase())) {
      return false;
    }
    if (filters.role && user.role !== filters.role) {
      return false;
    }
    return true;
  });

  const handleRoleChange = async (userId: string, event: ChangeEvent<HTMLSelectElement>) => {
    const newRole = event.target.value;
    try {
      setLoading(true);
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        role: newRole,
        updatedAt: new Date().toISOString()
      });

      // Update local state
      setUsersWithVerification(prev =>
        prev.map(user =>
          user.id === userId ? { ...user, role: newRole } : user
        )
      );
    } catch (error) {
      console.error('Failed to update user role:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleResendVerification = async (userId: string, email: string) => {
    try {
      setLoading(true);
      // Get the user from Firestore
      const userDoc = await getDoc(doc(db, 'users', userId));
      if (!userDoc.exists()) {
        throw new Error('User not found');
      }

      // If the current user is the same as the target user, we can send verification email
      const currentUser = auth.currentUser;
      if (currentUser && currentUser.email === email) {
        await sendEmailVerification(currentUser);
        alert('Verification email sent successfully!');
      } else {
        throw new Error('Cannot send verification email for another user');
      }
    } catch (error) {
      console.error('Failed to resend verification email:', error);
      alert('Failed to send verification email. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleManualVerification = async (userId: string) => {
    try {
      setLoading(true);
      await manuallyVerifyEmail(userId);
      
      // Refresh the verification statuses after manual verification
      const updatedUsers = await Promise.all(
        usersWithVerification.map(async (user) => {
          if (user.id === userId) {
            const isVerified = await getEmailVerificationStatus(user.id);
            return { ...user, emailVerified: isVerified };
          }
          return user;
        })
      );
      
      setUsersWithVerification(updatedUsers);
    } catch (error) {
      console.error('Failed to manually verify email:', error);
    } finally {
      setLoading(false);
    }
  };

  const roleOptions = Object.values(ROLES).map((role) => ({
    value: role.name,
    label: role.name,
  }));

  return (
    <RoleGuard
      requiredPermission={PERMISSIONS.MANAGE_USERS.id}
      fallback={
        <div className="text-center py-12">
          <h2 className="text-2xl font-semibold text-white">Access Denied</h2>
          <p className="text-gray-400 mt-2">
            You don't have permission to manage users.
          </p>
        </div>
      }
    >
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold text-white">User Management</h1>
          <div className="flex gap-4">
            <button
              onClick={() => navigate('/admin/create-admin')}
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
            >
              Create Admin User
            </button>
            <div className="relative">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
              <input
                type="text"
                placeholder="Search users..."
                className="pl-10 pr-4 py-2 bg-gray-800 rounded-lg text-white"
                value={filters.search}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setFilters({ ...filters, search: e.target.value })}
              />
            </div>
            <Select
              value={filters.role || ''}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => 
                setFilters({ ...filters, role: e.target.value })
              }
              options={roleOptions}
            />
          </div>
        </div>

        <div className="bg-gray-800 rounded-lg overflow-hidden">
          <table className="w-full">
            <thead>
              <tr className="bg-gray-700">
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">User</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Role</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Email Status</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-700">
              {filteredUsers.map((user) => (
                <tr key={user.id}>
                  <td className="px-6 py-4">
                    <div>
                      <div className="font-medium text-white">{user.name}</div>
                      <div className="text-sm text-gray-400">{user.email}</div>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <Select
                      value={user.role}
                      onChange={(e: ChangeEvent<HTMLSelectElement>) => handleRoleChange(user.id, e)}
                      options={roleOptions}
                    />
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center">
                      {user.emailVerified ? (
                        <CheckCircle className="text-green-500 mr-2" size={20} />
                      ) : (
                        <XCircle className="text-red-500 mr-2" size={20} />
                      )}
                      <span className={user.emailVerified ? "text-green-500" : "text-red-500"}>
                        {user.emailVerified ? "Verified" : "Not Verified"}
                      </span>
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex space-x-2">
                      {!user.emailVerified && (
                        <>
                          <button
                            onClick={() => handleManualVerification(user.id)}
                            disabled={loading}
                            className="px-3 py-1 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:opacity-50"
                          >
                            Verify
                          </button>
                          <button
                            onClick={() => handleResendVerification(user.id, user.email)}
                            disabled={loading}
                            className="px-3 py-1 bg-gray-600 text-white rounded-md hover:bg-gray-700 disabled:opacity-50"
                          >
                            Resend
                          </button>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </RoleGuard>
  );
}
