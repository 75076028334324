import type { User } from '../../types/auth';
import { Avatar } from '../ui/Avatar';
import { Button } from '../ui/Button';
import { Edit, Share2 } from 'lucide-react';

interface ProfileHeaderProps {
  user: User;
  streamCount: number;
  groupCount: number;
}

export function ProfileHeader({ user, streamCount, groupCount }: ProfileHeaderProps) {
  return (
    <div className="relative">
      {/* Cover Image */}
      <div className="h-48 w-full rounded-xl bg-gradient-to-r from-blue-600 to-purple-600 relative overflow-hidden">
        <div className="absolute inset-0 bg-black/20" />
      </div>

      <div className="container px-4">
        <div className="relative -mt-20 flex items-end space-x-4">
          {/* Profile Picture */}
          <Avatar
            src={user.avatar || ''}
            alt={user.name}
            className="h-32 w-32 rounded-full border-4 border-gray-900 bg-gray-800"
          />

          {/* User Info */}
          <div className="mb-4 flex-1">
            <h1 className="text-2xl font-bold text-white">{user.name}</h1>
            <p className="text-gray-400">@{user.email.split('@')[0]}</p>
            
            <div className="mt-2 flex space-x-4 text-sm text-gray-400">
              <span>{streamCount} Streams</span>
              <span>•</span>
              <span>{groupCount} Groups</span>
              <span>•</span>
              <span>{user.role}</span>
            </div>
          </div>

          {/* Action Buttons */}
          <div className="mb-4 flex space-x-2">
            <Button variant="outline" size="sm">
              <Share2 className="mr-2 h-4 w-4" />
              Share Profile
            </Button>
            <Button variant="outline" size="sm">
              <Edit className="mr-2 h-4 w-4" />
              Edit Profile
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
