import React from 'react';
import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { RoleGuard } from '../../components/auth/RoleGuard';
import { PERMISSIONS } from '../../config/roles';
import { Group } from '../../types/admin';
import { getGroup } from '../../services/admin';
import { Users, Lock, Unlock, Calendar, Link as LinkIcon, ArrowLeft } from 'lucide-react';
import { format } from 'date-fns';

interface GroupStream {
  id: string;
  title: string;
  url: string;
  createdAt: string;
}

export function AdminGroupManagementPage() {
  const { groupId } = useParams<{ groupId: string }>();
  const navigate = useNavigate();
  const [group, setGroup] = useState<Group | null>(null);
  const [streams, setStreams] = useState<GroupStream[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (groupId) {
      loadGroup();
    }
  }, [groupId]);

  const loadGroup = async () => {
    try {
      const groupData = await getGroup(groupId!);
      if (!groupData) {
        setError('Group not found');
        return;
      }
      setGroup(groupData);
      // In a real implementation, you would also fetch the group's streams
      setStreams([]);
    } catch (error) {
      setError('Failed to load group details');
      console.error('Error loading group:', error);
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (date: string) => {
    try {
      if (!date) return 'N/A';
      const parsedDate = new Date(date);
      if (isNaN(parsedDate.getTime())) return 'Invalid Date';
      return format(parsedDate, 'MMM d, yyyy h:mm a');
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid Date';
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error || !group) {
    return (
      <div className="min-h-screen bg-gray-900 text-white p-8">
        <button
          onClick={() => navigate('/admin/groups')}
          className="flex items-center text-gray-400 hover:text-white mb-8"
        >
          <ArrowLeft className="h-4 w-4 mr-2" />
          Back to Groups
        </button>
        <div className="flex items-center justify-center">
          <div className="text-red-500">{error || 'Group not found'}</div>
        </div>
      </div>
    );
  }

  return (
    <RoleGuard
      requiredPermission={PERMISSIONS.MANAGE_GROUPS.id}
      fallback={
        <div className="text-center py-12">
          <h2 className="text-2xl font-semibold text-white">Access Denied</h2>
          <p className="text-gray-400 mt-2">
            You don't have permission to view group details.
          </p>
        </div>
      }
    >
      <div className="min-h-screen bg-gray-900 text-white p-8">
        <button
          onClick={() => navigate('/admin/groups')}
          className="flex items-center text-gray-400 hover:text-white mb-8"
        >
          <ArrowLeft className="h-4 w-4 mr-2" />
          Back to Groups
        </button>

        <div className="grid gap-6">
          {/* Group Info */}
          <div className="bg-gray-800 rounded-lg p-6">
            <div className="flex items-start justify-between">
              <div>
                <h1 className="text-2xl font-bold text-white flex items-center">
                  {group.name}
                  {group.settings?.isPrivate ? (
                    <Lock className="h-5 w-5 text-yellow-400 ml-2" />
                  ) : (
                    <Unlock className="h-5 w-5 text-green-400 ml-2" />
                  )}
                </h1>
                <p className="text-gray-400 mt-2">{group.description}</p>
              </div>
              <div className="text-right">
                <p className="text-sm text-gray-400">Created by</p>
                <p className="text-white">{group.createdBy}</p>
                <p className="text-sm text-gray-400 mt-2">Created on</p>
                <p className="text-white">{formatDate(group.createdAt)}</p>
              </div>
            </div>

            <div className="mt-6">
              <h3 className="text-lg font-semibold text-white mb-3">Settings</h3>
              <div className="grid grid-cols-2 gap-4">
                <div className="bg-gray-700/50 p-4 rounded-lg">
                  <p className="text-gray-400">Privacy</p>
                  <p className="text-white font-medium">
                    {group.settings?.isPrivate ? 'Private Group' : 'Public Group'}
                  </p>
                </div>
                <div className="bg-gray-700/50 p-4 rounded-lg">
                  <p className="text-gray-400">Join Requirements</p>
                  <p className="text-white font-medium">
                    {group.settings?.joinRequiresApproval ? 'Approval Required' : 'Open Join'}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Members */}
          <div className="bg-gray-800 rounded-lg p-6">
            <h3 className="text-lg font-semibold text-white mb-4 flex items-center">
              <Users className="h-5 w-5 mr-2" />
              Members ({group.members.length})
            </h3>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {group.members.map((memberId) => (
                <div
                  key={memberId}
                  className="bg-gray-700/50 p-4 rounded-lg flex items-center"
                >
                  <div className="h-8 w-8 rounded-full bg-gray-600 flex items-center justify-center mr-3">
                    <Users className="h-4 w-4 text-gray-400" />
                  </div>
                  <div>
                    <p className="text-white">{memberId}</p>
                    <p className="text-xs text-gray-400">
                      {group.admins.includes(memberId) ? 'Admin' : 'Member'}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Streams */}
          <div className="bg-gray-800 rounded-lg p-6">
            <h3 className="text-lg font-semibold text-white mb-4">Group Streams</h3>
            {streams.length > 0 ? (
              <div className="space-y-4">
                {streams.map((stream) => (
                  <div
                    key={stream.id}
                    className="bg-gray-700/50 p-4 rounded-lg flex items-center justify-between"
                  >
                    <div className="flex items-center">
                      <LinkIcon className="h-5 w-5 text-gray-400 mr-3" />
                      <div>
                        <p className="text-white">{stream.title}</p>
                        <p className="text-sm text-gray-400">{stream.url}</p>
                      </div>
                    </div>
                    <div className="flex items-center text-sm text-gray-400">
                      <Calendar className="h-4 w-4 mr-1" />
                      {formatDate(stream.createdAt)}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-gray-400 text-center py-4">
                No streams found for this group.
              </p>
            )}
          </div>
        </div>
      </div>
    </RoleGuard>
  );
}
