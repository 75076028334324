import * as React from 'react';
import { cn } from '../../utils/cn';

interface AvatarProps extends React.ImgHTMLAttributes<HTMLImageElement> {}

export function Avatar({ className, src, alt, ...props }: AvatarProps) {
  return (
    <img
      src={src || '/default-avatar.png'}
      alt={alt}
      className={cn('rounded-full object-cover', className)}
      {...props}
    />
  );
}
