import React from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Button } from '../components/ui/Button';

export default function TermsPage() {
  return (
    <>
      <Helmet>
        <title>Terms of Service - SVIZ.live</title>
        <meta
          name="description"
          content="SVIZ.live terms of service - Understanding your rights and responsibilities when using our surgical video platform."
        />
      </Helmet>

      <div className="min-h-screen bg-background">
        {/* Navigation */}
        <nav className="fixed top-0 left-0 right-0 z-50 bg-background/80 backdrop-blur-lg border-b border-border">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between items-center h-16">
              <Link to="/" className="text-2xl font-bold bg-gradient-to-r from-primary to-blue-600 bg-clip-text text-transparent">
                SVIZ.live
              </Link>
              <Button variant="outline" size="sm" asChild>
                <Link to="/">Back to Home</Link>
              </Button>
            </div>
          </div>
        </nav>

        {/* Main Content */}
        <main className="pt-24 pb-16 px-4 sm:px-6 lg:px-8 max-w-4xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h1 className="text-4xl font-bold mb-8">Terms of Service</h1>
            
            <div className="space-y-8">
              <section>
                <h2 className="text-2xl font-semibold mb-4">Service Usage</h2>
                <p className="text-text-secondary mb-4">
                  By using SVIZ.live, you agree to:
                </p>
                <ul className="list-disc list-inside space-y-2 text-text-secondary">
                  <li>Use the platform for legitimate medical purposes only</li>
                  <li>Maintain patient privacy and confidentiality</li>
                  <li>Comply with applicable healthcare regulations</li>
                  <li>Keep your account credentials secure</li>
                </ul>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">User Responsibilities</h2>
                <p className="text-text-secondary mb-4">
                  As a user, you are responsible for:
                </p>
                <ul className="list-disc list-inside space-y-2 text-text-secondary">
                  <li>Obtaining necessary patient consents</li>
                  <li>Maintaining accurate professional credentials</li>
                  <li>Ensuring proper internet connectivity</li>
                  <li>Reporting any security concerns promptly</li>
                </ul>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">Content Guidelines</h2>
                <p className="text-text-secondary mb-4">
                  All content must:
                </p>
                <ul className="list-disc list-inside space-y-2 text-text-secondary">
                  <li>Comply with medical privacy laws</li>
                  <li>Be professionally appropriate</li>
                  <li>Not violate intellectual property rights</li>
                  <li>Be accurately labeled and categorized</li>
                </ul>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">Service Modifications</h2>
                <p className="text-text-secondary mb-4">
                  SVIZ.live reserves the right to:
                </p>
                <ul className="list-disc list-inside space-y-2 text-text-secondary">
                  <li>Modify or discontinue services</li>
                  <li>Update terms and conditions</li>
                  <li>Adjust pricing with notice</li>
                  <li>Terminate accounts for violations</li>
                </ul>
              </section>
            </div>
          </motion.div>
        </main>

        {/* Footer */}
        <footer className="bg-background-secondary border-t border-border">
          <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
              {/* Company Info */}
              <div className="col-span-1">
                <Link to="/" className="text-2xl font-bold bg-gradient-to-r from-primary to-blue-600 bg-clip-text text-transparent">
                  SVIZ.live
                </Link>
                <p className="mt-4 text-text-secondary">
                  Advanced surgical video platform for medical professionals.
                </p>
              </div>

              {/* Product Links */}
              <div>
                <h3 className="text-sm font-semibold uppercase tracking-wider">Product</h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link to="/features" className="text-text-secondary hover:text-text transition-colors">
                      Features
                    </Link>
                  </li>
                  <li>
                    <Link to="/security" className="text-text-secondary hover:text-text transition-colors">
                      Security
                    </Link>
                  </li>
                  <li>
                    <Link to="/demo" className="text-text-secondary hover:text-text transition-colors">
                      Request Demo
                    </Link>
                  </li>
                </ul>
              </div>

              {/* Company Links */}
              <div>
                <h3 className="text-sm font-semibold uppercase tracking-wider">Company</h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link to="/about" className="text-text-secondary hover:text-text transition-colors">
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" className="text-text-secondary hover:text-text transition-colors">
                      Contact
                    </Link>
                  </li>
                  <li>
                    <a href="https://blog.sviz.live" className="text-text-secondary hover:text-text transition-colors">
                      Blog
                    </a>
                  </li>
                </ul>
              </div>

              {/* Legal Links */}
              <div>
                <h3 className="text-sm font-semibold uppercase tracking-wider">Legal</h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link to="/privacy" className="text-text-secondary hover:text-text transition-colors">
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link to="/terms" className="text-text-secondary hover:text-text transition-colors">
                      Terms of Service
                    </Link>
                  </li>
                  <li>
                    <Link to="/security-policy" className="text-text-secondary hover:text-text transition-colors">
                      Security Policy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="mt-8 pt-8 border-t border-border">
              <p className="text-center text-text-secondary">
                {new Date().getFullYear()} SVIZ.live. All rights reserved.
              </p>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}
