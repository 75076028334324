import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../store/auth';
import { ROLES } from '../config/roles';
import { Button } from '../components/ui/Button';

export function DemoAccountsPage() {
  const { login } = useAuthStore();
  const navigate = useNavigate();

  const demoAccounts = [
    {
      email: 'john.doe@hospital.com',
      password: 'demo123',
      name: 'Dr. John Doe',
      role: 'admin',
      description: 'Administrator with full system access',
    },
    {
      email: 'sarah.johnson@hospital.com',
      password: 'demo123',
      name: 'Dr. Sarah Johnson',
      role: 'creator',
      description: 'Content creator with streaming permissions',
    },
    {
      email: 'michael.chen@hospital.com',
      password: 'demo123',
      name: 'Dr. Michael Chen',
      role: 'user',
      description: 'Standard user with basic viewing permissions',
    },
  ];

  const handleLogin = async (email: string) => {
    try {
      await login(email, 'demo123');
      navigate('/');
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  return (
    <div className="max-w-4xl mx-auto">
      <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4 mb-6">
        <h2 className="text-lg font-medium text-yellow-800 mb-2">Demo Accounts</h2>
        <p className="text-sm text-yellow-700">
          Use these accounts to test different user roles and permissions. All accounts use the
          password: <code className="bg-yellow-100 px-2 py-1 rounded">demo123</code>
        </p>
      </div>

      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        {demoAccounts.map((account) => {
          const role = ROLES.find((r) => r.name === account.role);
          return (
            <div
              key={account.email}
              className="bg-white rounded-lg shadow-sm border border-gray-200 p-6"
            >
              <div className="flex items-center justify-between mb-4">
                <div className="h-10 w-10 rounded-full bg-blue-100 flex items-center justify-center">
                  <span className="text-lg font-medium text-blue-700">
                    {account.name.charAt(0)}
                  </span>
                </div>
                <span className="px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                  {role?.label}
                </span>
              </div>
              
              <h3 className="font-medium text-gray-900 mb-1">{account.name}</h3>
              <p className="text-sm text-gray-500 mb-4">{account.description}</p>
              
              <div className="space-y-2">
                <p className="text-sm">
                  <span className="font-medium">Email:</span>{' '}
                  <code className="bg-gray-100 px-1 rounded">{account.email}</code>
                </p>
                <p className="text-sm">
                  <span className="font-medium">Password:</span>{' '}
                  <code className="bg-gray-100 px-1 rounded">demo123</code>
                </p>
              </div>

              <Button
                onClick={() => handleLogin(account.email)}
                className="w-full mt-4"
              >
                Login as {role?.label}
              </Button>
            </div>
          );
        })}
      </div>
    </div>
  );
}