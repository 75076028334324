import { useAuthStore } from '../store/auth';
import { ROLE_PERMISSIONS } from '../config/roles';
import type { Permission } from '../types/roles';

export function usePermissions() {
  const user = useAuthStore((state) => state.user);

  const hasPermission = (permissionId: string): boolean => {
    if (!user) return false;
    const rolePermissions = ROLE_PERMISSIONS[user.role];
    return rolePermissions.some((permission: Permission) => permission.id === permissionId);
  };

  const getAllPermissions = (): Permission[] => {
    if (!user) return [];
    return ROLE_PERMISSIONS[user.role];
  };

  return {
    hasPermission,
    getAllPermissions,
    userRole: user?.role,
  };
}