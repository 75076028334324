import { useState, useEffect } from 'react';
import type { User } from '../../types/auth';
import { Card } from '../ui/Card';
import { Input } from '../ui/Input';
import { Button } from '../ui/Button';
import { Switch } from '../ui/Switch';
import { useThemeStore } from '../../store/theme';
import {
  User as UserIcon,
  Bell,
  Shield,
  Palette,
  Sun,
  Moon,
} from 'lucide-react';

interface ProfileSettingsProps {
  user: User;
}

type NotificationSettings = Record<'email' | 'push' | 'streams', boolean>;
type PrivacySettings = Record<'publicProfile' | 'showEmail' | 'showStats', boolean>;
type AppearanceSettings = Record<'theme' | 'compactView', boolean>;

interface FormData {
  name: string;
  email: string;
  notifications: NotificationSettings;
  privacy: PrivacySettings;
  appearance: AppearanceSettings;
}

type SettingSection = keyof Pick<FormData, 'notifications' | 'privacy' | 'appearance'>;

export function ProfileSettings({ user }: ProfileSettingsProps) {
  const { theme, setTheme } = useThemeStore();
  const [formData, setFormData] = useState<FormData>({
    name: user.name,
    email: user.email,
    notifications: {
      email: true,
      push: true,
      streams: true,
    },
    privacy: {
      publicProfile: true,
      showEmail: false,
      showStats: true,
    },
    appearance: {
      theme: theme === 'dark',
      compactView: false,
    },
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleToggleChange = (section: SettingSection, setting: string) => {
    setFormData((prev) => {
      const updatedSection: Record<string, boolean> = { ...prev[section] };
      updatedSection[setting as keyof typeof updatedSection] = !updatedSection[setting as keyof typeof updatedSection];
      
      return {
        ...prev,
        [section]: updatedSection
      };
    });
  };

  useEffect(() => {
    setTheme(formData.appearance.theme ? 'dark' : 'light');
  }, [formData.appearance.theme, setTheme]);

  const sections = [
    {
      title: 'Profile Information',
      icon: UserIcon,
      content: (
        <div className="space-y-4">
          <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
            <div className="space-y-2">
              <label className="block text-sm font-medium tracking-tight text-gray-300 mb-1">
                Name
              </label>
              <Input
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="w-full bg-gray-700 border border-gray-600 rounded-md px-3 py-2 text-sm tracking-tight focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>
            <div className="space-y-2">
              <label className="block text-sm font-medium tracking-tight text-gray-300 mb-1">
                Email
              </label>
              <Input
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full bg-gray-700 border border-gray-600 rounded-md px-3 py-2 text-sm tracking-tight focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'Notifications',
      icon: Bell,
      content: (
        <div className="space-y-4">
          {Object.entries(formData.notifications).map(([key, value]) => (
            <div key={key} className="flex items-center justify-between">
              <div>
                <h3 className="text-sm font-medium tracking-tight capitalize">
                  {key.replace(/([A-Z])/g, ' $1').trim()} Notifications
                </h3>
                <p className="text-sm text-gray-400 tracking-tight">Receive notifications about your activity</p>
              </div>
              <Switch
                checked={value}
                onCheckedChange={() => handleToggleChange('notifications', key)}
                className="bg-gray-700"
              />
            </div>
          ))}
        </div>
      ),
    },
    {
      title: 'Privacy',
      icon: Shield,
      content: (
        <div className="space-y-4">
          {Object.entries(formData.privacy).map(([key, value]) => (
            <div key={key} className="flex items-center justify-between">
              <div>
                <h3 className="text-sm font-medium tracking-tight capitalize">
                  {key.replace(/([A-Z])/g, ' $1').trim()}
                </h3>
                <p className="text-sm text-gray-400 tracking-tight">Control your privacy settings</p>
              </div>
              <Switch
                checked={value}
                onCheckedChange={() => handleToggleChange('privacy', key)}
                className="bg-gray-700"
              />
            </div>
          ))}
        </div>
      ),
    },
    {
      title: 'Appearance',
      icon: Palette,
      content: (
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <div>
              <h3 className="text-sm font-medium tracking-tight">Theme</h3>
              <p className="text-sm text-gray-400 tracking-tight">Choose between light and dark theme</p>
            </div>
            <div className="flex items-center space-x-2">
              <Sun className={`h-4 w-4 ${!formData.appearance.theme ? 'text-yellow-400' : 'text-gray-400'}`} />
              <Switch
                checked={formData.appearance.theme}
                onCheckedChange={() => handleToggleChange('appearance', 'theme')}
                className={formData.appearance.theme ? 'bg-gray-700' : 'bg-blue-600'}
              />
              <Moon className={`h-4 w-4 ${formData.appearance.theme ? 'text-blue-400' : 'text-gray-400'}`} />
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div>
              <h3 className="text-sm font-medium tracking-tight">Compact View</h3>
              <p className="text-sm text-gray-400 tracking-tight">Make the interface more compact</p>
            </div>
            <Switch
              checked={formData.appearance.compactView}
              onCheckedChange={() => handleToggleChange('appearance', 'compactView')}
              className="bg-gray-700"
            />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="max-w-2xl mx-auto p-6">
      <h1 className="text-2xl font-semibold tracking-tight mb-8 text-text">Profile Settings</h1>
      
      <div className="space-y-6">
        {sections.map((section) => (
          <Card key={section.title} className="bg-background-secondary rounded-lg p-6 border border-border">
            <h2 className="text-lg font-medium tracking-tight mb-4 text-text">{section.title}</h2>
            {section.content}
          </Card>
        ))}
        
        <div className="flex justify-end space-x-4">
          <Button
            variant="outline"
            className="px-4 py-2 text-sm font-medium tracking-tight text-text-secondary hover:text-text transition-colors border-border"
          >
            Cancel
          </Button>
          <Button
            className="px-4 py-2 text-sm font-medium tracking-tight bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
          >
            Save Changes
          </Button>
        </div>
      </div>
    </div>
  );
}
