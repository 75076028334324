import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import type { GroupMember } from '../../types/group';

export function getMemberDocId(userId: string, groupId: string): string {
  return `${userId}_${groupId}`;
}

export async function getMemberRole(groupId: string, userId: string): Promise<string | null> {
  const memberRef = doc(db, 'groupMembers', getMemberDocId(userId, groupId));
  const memberDoc = await getDoc(memberRef);
  return memberDoc.exists() ? memberDoc.data().role : null;
}

export async function getMemberData(groupId: string, userId: string): Promise<GroupMember | null> {
  const memberRef = doc(db, 'groupMembers', getMemberDocId(userId, groupId));
  const memberDoc = await getDoc(memberRef);
  
  if (!memberDoc.exists()) {
    return null;
  }

  const data = memberDoc.data();
  return {
    id: memberDoc.id,
    userId: data.userId,
    userName: data.userName,
    role: data.role,
    joinedAt: data.joinedAt.toDate().toISOString(),
  };
}