import React from 'react';
import { Bell } from 'lucide-react';
import { useNotificationStore } from '../../store/notification';
import { Card } from '../ui/Card';

export function NotificationsPanel() {
  const { notifications, markAsRead, removeNotification } = useNotificationStore();

  const handleNotificationClick = (id: string) => {
    markAsRead(id);
  };

  const handleRemove = (e: React.MouseEvent, id: string) => {
    e.stopPropagation();
    removeNotification(id);
  };

  return (
    <Card className="h-full">
      <div className="p-6">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-2">
            <Bell className="h-5 w-5 text-blue-500" />
            <h2 className="text-lg font-semibold text-gray-200">Recent Notifications</h2>
          </div>
        </div>
        <div className="space-y-4 max-h-[400px] overflow-y-auto">
          {notifications.length === 0 ? (
            <div className="text-center py-8">
              <Bell className="h-12 w-12 text-gray-600 mx-auto mb-4" />
              <p className="text-gray-500">No notifications yet</p>
            </div>
          ) : (
            notifications.map((notification) => (
              <div
                key={notification.id}
                onClick={() => handleNotificationClick(notification.id)}
                className={`p-4 rounded-lg cursor-pointer transition-colors ${
                  !notification.read
                    ? 'bg-blue-500/10 hover:bg-blue-500/20'
                    : 'bg-gray-800/50 hover:bg-gray-800/70'
                }`}
              >
                <div className="flex justify-between items-start">
                  <div className="space-y-1">
                    <h3 className={`font-medium ${!notification.read ? 'text-blue-400' : 'text-gray-300'}`}>
                      {notification.title}
                    </h3>
                    <p className="text-sm text-gray-400">{notification.message}</p>
                    <p className="text-xs text-gray-500">
                      {new Date(notification.createdAt).toLocaleString()}
                    </p>
                  </div>
                  <button
                    onClick={(e) => handleRemove(e, notification.id)}
                    className="text-gray-500 hover:text-gray-400 p-1"
                  >
                    ×
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </Card>
  );
}
