import { useState } from 'react';
import { useAuthStore } from '../store/auth';
import { useStreamStore } from '../store/stream';
import { useGroupStore } from '../store/group';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../components/ui/Tabs';
import {
  ProfileHeader,
  StreamHistory,
  ProfileStats,
  ProfileSettings,
} from '../components/profile';
import { StreamAnalytics } from '../components/analytics/StreamAnalytics';

export function ProfilePage() {
  const { user } = useAuthStore();
  const { streams } = useStreamStore();
  const { groups } = useGroupStore();
  const [activeTab, setActiveTab] = useState('overview');

  if (!user) {
    return (
      <div className="text-center py-12">
        <h2 className="text-2xl font-light text-white">Please sign in to view your profile</h2>
      </div>
    );
  }

  const userStreams = streams.filter(stream => stream.creatorId === user.id);
  const userGroups = groups.filter(group => 
    group.members?.some(member => member.userId === user.id)
  );

  return (
    <div className="container mx-auto px-4 py-8 space-y-8">
      <ProfileHeader user={user} streamCount={userStreams.length} groupCount={userGroups.length} />
      
      <div className="w-full">
        <Tabs value={activeTab} onValueChange={setActiveTab}>
          <TabsList className="grid w-full grid-cols-4 rounded-xl bg-gray-800/50 p-1">
            <TabsTrigger
              value="overview"
              className="rounded-lg px-3 py-2 text-sm font-medium transition-all data-[state=active]:bg-blue-600"
            >
              Overview
            </TabsTrigger>
            <TabsTrigger
              value="streams"
              className="rounded-lg px-3 py-2 text-sm font-medium transition-all data-[state=active]:bg-blue-600"
            >
              Streams
            </TabsTrigger>
            <TabsTrigger
              value="analytics"
              className="rounded-lg px-3 py-2 text-sm font-medium transition-all data-[state=active]:bg-blue-600"
            >
              Analytics
            </TabsTrigger>
            <TabsTrigger
              value="settings"
              className="rounded-lg px-3 py-2 text-sm font-medium transition-all data-[state=active]:bg-blue-600"
            >
              Settings
            </TabsTrigger>
          </TabsList>

          <TabsContent value="overview" className="mt-6">
            <div className="grid gap-6 grid-cols-1 lg:grid-cols-2">
              <ProfileStats user={user} streams={userStreams} groups={userGroups} />
              <div className="space-y-6">
                <StreamHistory streams={userStreams.slice(0, 5)} />
                <StreamAnalytics streams={userStreams} compact />
              </div>
            </div>
          </TabsContent>

          <TabsContent value="streams" className="mt-6">
            <StreamHistory streams={userStreams} detailed />
          </TabsContent>

          <TabsContent value="analytics" className="mt-6">
            <StreamAnalytics streams={userStreams} />
          </TabsContent>

          <TabsContent value="settings" className="mt-6">
            <ProfileSettings user={user} />
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
}
