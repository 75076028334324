import { create } from 'zustand';
import type { AuthState } from '../types/auth';
import { loginUser, logoutUser, createUser } from '../services/auth';

export const useAuthStore = create<AuthState>((set) => ({
  user: null,
  isAuthenticated: false,
  isLoading: false,
  isEmailVerified: false,

  setUser: (user) => set({ user }),
  setIsAuthenticated: (isAuthenticated) => set({ isAuthenticated }),
  setIsEmailVerified: (isEmailVerified) => set({ isEmailVerified }),

  login: async (email: string, password: string) => {
    set({ isLoading: true });
    try {
      const user = await loginUser(email, password);
      set({ 
        user, 
        isAuthenticated: true,
        // Email verification is handled by the auth state listener
        isEmailVerified: false
      });
    } catch (error) {
      set({ user: null, isAuthenticated: false, isEmailVerified: false });
      throw error;
    } finally {
      set({ isLoading: false });
    }
  },

  logout: async () => {
    set({ isLoading: true });
    try {
      await logoutUser();
      set({ user: null, isAuthenticated: false, isEmailVerified: false });
    } catch (error) {
      console.error('Logout failed:', error);
      throw error;
    } finally {
      set({ isLoading: false });
    }
  },

  register: async (email: string, password: string, name: string) => {
    set({ isLoading: true });
    try {
      await createUser(email, password, name);
      set({ user: null, isAuthenticated: false, isEmailVerified: false });
    } catch (error) {
      set({ user: null, isAuthenticated: false, isEmailVerified: false });
      throw error;
    } finally {
      set({ isLoading: false });
    }
  }
}));