import React from 'react';
import { User } from '../../types/auth';
import { Stream } from '../../types/stream';
import { Group } from '../../types/group';
import { Card } from '../ui/Card';
import { Users, Clock, Eye, Award, TrendingUp } from 'lucide-react';

interface ProfileStatsProps {
  user: User;
  streams: Stream[];
  groups: Group[];
}

export function ProfileStats({ user, streams, groups }: ProfileStatsProps) {
  // Calculate statistics
  const totalViewers = streams.reduce((acc, stream) => acc + (stream.viewerCount || 0), 0);
  const totalStreamHours = streams.reduce((acc, stream) => {
    if (stream.startedAt && stream.endedAt) {
      const duration = (new Date(stream.endedAt).getTime() - new Date(stream.startedAt).getTime()) / 1000;
      return acc + duration / 3600;
    }
    return acc;
  }, 0);
  
  const avgViewersPerStream = streams.length ? Math.round(totalViewers / streams.length) : 0;
  const impactScore = Math.floor(streams.length * 10 + (totalViewers / 10));

  const stats = [
    {
      label: 'Total Viewers',
      value: totalViewers.toLocaleString(),
      icon: Eye,
      color: 'text-blue-500',
    },
    {
      label: 'Stream Hours',
      value: totalStreamHours.toFixed(1),
      icon: Clock,
      color: 'text-green-500',
    },
    {
      label: 'Avg. Viewers',
      value: avgViewersPerStream.toLocaleString(),
      icon: Users,
      color: 'text-purple-500',
    },
    {
      label: 'Impact Score',
      value: impactScore.toLocaleString(),
      icon: Award,
      color: 'text-yellow-500',
    },
  ];

  return (
    <div className="space-y-6">
      <h3 className="text-xl font-semibold text-white">Statistics</h3>
      
      <div className="grid gap-4 grid-cols-2">
        {stats.map((stat) => (
          <Card key={stat.label} className="p-4 bg-gray-800/50">
            <div className="flex items-center space-x-2">
              <stat.icon className={`h-5 w-5 ${stat.color}`} />
              <span className="text-sm text-gray-400">{stat.label}</span>
            </div>
            <div className="mt-2 text-2xl font-bold text-white">{stat.value}</div>
          </Card>
        ))}
      </div>

      {/* Growth Chart */}
      <Card className="p-4 bg-gray-800/50">
        <div className="flex items-center justify-between">
          <h4 className="text-sm font-medium text-gray-400">Viewer Growth</h4>
          <TrendingUp className="h-4 w-4 text-green-500" />
        </div>
        <div className="mt-4 h-32 w-full">
          {/* Add your chart component here */}
          <div className="flex h-full items-end space-x-2">
            {streams.slice(-7).map((stream, i) => (
              <div
                key={i}
                className="flex-1 bg-blue-500/20 hover:bg-blue-500/30 transition-all rounded-t"
                style={{
                  height: `${(stream.viewerCount || 0) / (Math.max(...streams.map(s => s.viewerCount || 0)) || 1) * 100}%`,
                }}
              />
            ))}
          </div>
          <div className="mt-2 flex justify-between text-xs text-gray-500">
            <span>7 days ago</span>
            <span>Today</span>
          </div>
        </div>
      </Card>
    </div>
  );
}
