export const GROUP_ROLES = {
  OWNER: 'owner',
  ADMIN: 'admin',
  MEMBER: 'member',
} as const;

export const GROUP_ERRORS = {
  NOT_FOUND: 'Group not found',
  ALREADY_MEMBER: 'You are already a member of this group',
  NOT_MEMBER: 'You are not a member of this group',
  OWNER_LEAVE: 'Group owner cannot leave the group',
  OWNER_REMOVE: 'Cannot remove the group owner',
  PRIVATE_GROUP: 'Cannot join a private group without an invitation',
  PERMISSION_DENIED: 'You do not have permission to perform this action',
  FETCH_FAILED: 'Failed to fetch group data',
  UPDATE_FAILED: 'Failed to update group data',
  DELETE_FAILED: 'Failed to delete group data',
} as const;