import React from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Button } from '../components/ui/Button';

export default function PrivacyPage() {
  return (
    <>
      <Helmet>
        <title>Privacy Policy - SVIZ.live</title>
        <meta
          name="description"
          content="SVIZ.live privacy policy - Learn how we protect your data and maintain privacy in our surgical video platform."
        />
      </Helmet>

      <div className="min-h-screen bg-background">
        {/* Navigation */}
        <nav className="fixed top-0 left-0 right-0 z-50 bg-background/80 backdrop-blur-lg border-b border-border">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between items-center h-16">
              <Link to="/" className="text-2xl font-bold bg-gradient-to-r from-primary to-blue-600 bg-clip-text text-transparent">
                SVIZ.live
              </Link>
              <Button variant="outline" size="sm" asChild>
                <Link to="/">Back to Home</Link>
              </Button>
            </div>
          </div>
        </nav>

        {/* Main Content */}
        <main className="pt-24 pb-16 px-4 sm:px-6 lg:px-8 max-w-4xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h1 className="text-4xl font-bold mb-8">Privacy Policy</h1>
            
            <div className="space-y-8">
              <section>
                <h2 className="text-2xl font-semibold mb-4">Data Collection and Usage</h2>
                <p className="text-text-secondary mb-4">
                  At SVIZ.live, we take your privacy seriously. We collect and process data necessary for providing our surgical video streaming services, including:
                </p>
                <ul className="list-disc list-inside space-y-2 text-text-secondary">
                  <li>Account information (name, email, professional credentials)</li>
                  <li>Video content and metadata</li>
                  <li>Usage analytics and platform interactions</li>
                  <li>Technical data for service optimization</li>
                </ul>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">Data Protection</h2>
                <p className="text-text-secondary mb-4">
                  We implement robust security measures to protect your data:
                </p>
                <ul className="list-disc list-inside space-y-2 text-text-secondary">
                  <li>End-to-end encryption for video content</li>
                  <li>Secure data storage with regular backups</li>
                  <li>Access controls and authentication</li>
                  <li>Regular security audits and updates</li>
                </ul>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">Data Sharing</h2>
                <p className="text-text-secondary mb-4">
                  We only share your data with:
                </p>
                <ul className="list-disc list-inside space-y-2 text-text-secondary">
                  <li>Authorized team members and collaborators</li>
                  <li>Service providers essential for platform operation</li>
                  <li>Legal authorities when required by law</li>
                </ul>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">Your Rights</h2>
                <p className="text-text-secondary mb-4">
                  You have the right to:
                </p>
                <ul className="list-disc list-inside space-y-2 text-text-secondary">
                  <li>Access your personal data</li>
                  <li>Request data correction or deletion</li>
                  <li>Opt-out of certain data processing</li>
                  <li>Export your data</li>
                </ul>
              </section>
            </div>
          </motion.div>
        </main>

        {/* Footer */}
        <footer className="bg-background-secondary border-t border-border">
          <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
              {/* Company Info */}
              <div className="col-span-1">
                <Link to="/" className="text-2xl font-bold bg-gradient-to-r from-primary to-blue-600 bg-clip-text text-transparent">
                  SVIZ.live
                </Link>
                <p className="mt-4 text-text-secondary">
                  Advanced surgical video platform for medical professionals.
                </p>
              </div>

              {/* Product Links */}
              <div>
                <h3 className="text-sm font-semibold uppercase tracking-wider">Product</h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link to="/features" className="text-text-secondary hover:text-text transition-colors">
                      Features
                    </Link>
                  </li>
                  <li>
                    <Link to="/security" className="text-text-secondary hover:text-text transition-colors">
                      Security
                    </Link>
                  </li>
                  <li>
                    <Link to="/demo" className="text-text-secondary hover:text-text transition-colors">
                      Request Demo
                    </Link>
                  </li>
                </ul>
              </div>

              {/* Company Links */}
              <div>
                <h3 className="text-sm font-semibold uppercase tracking-wider">Company</h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link to="/about" className="text-text-secondary hover:text-text transition-colors">
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" className="text-text-secondary hover:text-text transition-colors">
                      Contact
                    </Link>
                  </li>
                  <li>
                    <a href="https://blog.sviz.live" className="text-text-secondary hover:text-text transition-colors">
                      Blog
                    </a>
                  </li>
                </ul>
              </div>

              {/* Legal Links */}
              <div>
                <h3 className="text-sm font-semibold uppercase tracking-wider">Legal</h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link to="/privacy" className="text-text-secondary hover:text-text transition-colors">
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link to="/terms" className="text-text-secondary hover:text-text transition-colors">
                      Terms of Service
                    </Link>
                  </li>
                  <li>
                    <Link to="/security-policy" className="text-text-secondary hover:text-text transition-colors">
                      Security Policy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="mt-8 pt-8 border-t border-border">
              <p className="text-center text-text-secondary">
                {new Date().getFullYear()} SVIZ.live. All rights reserved.
              </p>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}
