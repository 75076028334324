// Configure AWS SDK for browser environment
import { S3Client } from '@aws-sdk/client-s3';
import { Buffer } from 'buffer';

// Polyfill Buffer for browser environment
if (typeof window !== 'undefined') {
  window.Buffer = window.Buffer || Buffer;
}

// Polyfill other required globals
(window as any).global = window;
(window as any).process = {
  env: { DEBUG: undefined },
  version: '',
  nextTick: requestAnimationFrame,
};

// Define bucket name as a constant
export const STORAGE_BUCKET_NAME = 'sviz';

// Initialize S3 client with Wasabi configuration
export const s3Client = new S3Client({
  region: 'us-east-1',
  endpoint: 'https://s3.us-east-1.wasabisys.com',
  credentials: {
    accessKeyId: import.meta.env.VITE_WASABI_ACCESS_KEY_ID || '',
    secretAccessKey: import.meta.env.VITE_WASABI_SECRET_ACCESS_KEY || ''
  },
  forcePathStyle: true, // Required for Wasabi
  // Wasabi-specific configuration
  maxAttempts: 3,
  retryMode: 'standard'
});

export default s3Client;
