import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Users, Lock, Globe } from 'lucide-react';
import { useGroupStore } from '../../store/group';
import { Button } from '../ui/Button';
import { Input } from '../ui/Input';

const groupSchema = z.object({
  name: z.string()
    .min(3, 'Name must be at least 3 characters')
    .max(50, 'Name must be less than 50 characters'),
  description: z.string()
    .min(20, 'Description must be at least 20 characters')
    .max(500, 'Description must be less than 500 characters'),
  isPrivate: z.boolean(),
});

type GroupFormData = z.infer<typeof groupSchema>;

interface Props {
  onSuccess?: () => void;
}

export function CreateGroupForm({ onSuccess }: Props) {
  const [error, setError] = useState<string | null>(null);
  const { createGroup, isLoading } = useGroupStore();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<GroupFormData>({
    resolver: zodResolver(groupSchema),
    defaultValues: {
      isPrivate: false,
    },
  });

  const isPrivate = watch('isPrivate');

  const onSubmit = async (data: GroupFormData) => {
    try {
      setError(null);
      await createGroup(data);
      onSuccess?.();
    } catch (error: any) {
      setError(error.message || 'Failed to create group');
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      {error && (
        <div className="p-3 rounded-md bg-red-50/10 border border-red-200/20">
          <p className="text-sm text-red-400">{error}</p>
        </div>
      )}

      <Input
        {...register('name')}
        label="Group Name"
        placeholder="e.g., Cardiology Research Team"
        error={errors.name?.message}
      />

      <div className="space-y-1">
        <label className="block text-sm font-light tracking-wide text-gray-300">
          Description
        </label>
        <textarea
          {...register('description')}
          className="w-full rounded-md bg-gray-800 border-gray-700 text-gray-100 shadow-sm focus:border-blue-500 focus:ring-blue-500 placeholder-gray-500"
          rows={4}
          placeholder="Describe the purpose and activities of your group..."
        />
        {errors.description?.message && (
          <p className="text-sm text-red-500">{errors.description.message}</p>
        )}
      </div>

      <div className="space-y-4">
        <label className="block text-sm font-light tracking-wide text-gray-300">
          Privacy Settings
        </label>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <button
            type="button"
            className={`w-full text-left rounded-lg p-4 border transition-all duration-300 ${
              !isPrivate
                ? 'bg-blue-600/20 border-blue-500/50'
                : 'bg-gray-800/50 border-gray-700 hover:border-gray-600'
            }`}
            onClick={() => setValue('isPrivate', false)}
          >
            <div className="flex items-center space-x-3">
              <Globe className={`h-5 w-5 ${!isPrivate ? 'text-blue-400' : 'text-gray-400'}`} />
              <div>
                <h3 className="font-light text-white">Public Group</h3>
                <p className="text-sm text-gray-400 mt-1">
                  Anyone can find and join this group
                </p>
              </div>
            </div>
          </button>

          <button
            type="button"
            className={`w-full text-left rounded-lg p-4 border transition-all duration-300 ${
              isPrivate
                ? 'bg-purple-600/20 border-purple-500/50'
                : 'bg-gray-800/50 border-gray-700 hover:border-gray-600'
            }`}
            onClick={() => setValue('isPrivate', true)}
          >
            <div className="flex items-center space-x-3">
              <Lock className={`h-5 w-5 ${isPrivate ? 'text-purple-400' : 'text-gray-400'}`} />
              <div>
                <h3 className="font-light text-white">Private Group</h3>
                <p className="text-sm text-gray-400 mt-1">
                  Members must be invited to join
                </p>
              </div>
            </div>
          </button>
        </div>
        <input
          type="checkbox"
          {...register('isPrivate')}
          className="hidden"
        />
      </div>

      <Button
        type="submit"
        isLoading={isLoading}
        className="w-full bg-blue-600 hover:bg-blue-700"
      >
        <Users className="h-4 w-4 mr-2" />
        Create Group
      </Button>
    </form>
  );
}