import React, { useState, useEffect } from 'react';
import { RoleGuard } from '../../components/auth/RoleGuard';
import { PERMISSIONS } from '../../config/roles';
import { AnalyticsData } from '../../types/admin';
import { getAnalytics } from '../../services/admin';
import { Users, Video, HardDrive, UserPlus, Group } from 'lucide-react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';

export function AnalyticsPage() {
  const [analytics, setAnalytics] = useState<AnalyticsData | null>(null);
  const [timeRange, setTimeRange] = useState<'day' | 'week' | 'month'>('week');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    loadAnalytics();
  }, [timeRange]);

  const loadAnalytics = async () => {
    try {
      const data = await getAnalytics(timeRange);
      setAnalytics(data);
    } catch (error) {
      setError('Failed to load analytics');
      console.error('Error loading analytics:', error);
    } finally {
      setLoading(false);
    }
  };

  const formatBytes = (bytes: number) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
  };

  const StatCard = ({ title, value, icon: Icon, color }: any) => (
    <div className="bg-gray-800 rounded-lg p-6">
      <div className="flex items-center justify-between">
        <div>
          <p className="text-gray-400 text-sm">{title}</p>
          <p className="text-2xl font-semibold text-white mt-2">{value}</p>
        </div>
        <div className={`p-3 bg-opacity-10 rounded-full ${color}`}>
          <Icon className={`w-6 h-6 ${color}`} />
        </div>
      </div>
    </div>
  );

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (!analytics) return null;

  const streamData = [
    { name: 'Total Streams', value: analytics.streamMetrics.totalStreams },
    { name: 'Active Streams', value: analytics.streamMetrics.activeStreams },
    { name: 'Total Viewers', value: analytics.streamMetrics.totalViewers },
    { name: 'Peak Viewers', value: analytics.streamMetrics.peakConcurrentViewers },
  ];

  return (
    <RoleGuard
      requiredPermission={PERMISSIONS.VIEW_ANALYTICS.id}
      fallback={
        <div className="text-center py-12">
          <h2 className="text-2xl font-semibold text-white">Access Denied</h2>
          <p className="text-gray-400 mt-2">
            You don't have permission to view analytics.
          </p>
        </div>
      }
    >
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold text-white">Analytics Dashboard</h1>
          <select
            value={timeRange}
            onChange={(e) => setTimeRange(e.target.value as 'day' | 'week' | 'month')}
            className="bg-gray-800 text-white border border-gray-700 rounded-md px-3 py-2"
          >
            <option value="day">Last 24 Hours</option>
            <option value="week">Last 7 Days</option>
            <option value="month">Last 30 Days</option>
          </select>
        </div>

        {error && (
          <div className="mb-4 p-4 bg-red-500/10 border border-red-500 rounded text-red-500">
            {error}
          </div>
        )}

        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-4 mb-8">
          <StatCard
            title="Total Users"
            value={analytics.userMetrics.totalUsers}
            icon={Users}
            color="text-blue-500"
          />
          <StatCard
            title="Active Streams"
            value={analytics.streamMetrics.activeStreams}
            icon={Video}
            color="text-green-500"
          />
          <StatCard
            title="Storage Used"
            value={formatBytes(analytics.storageMetrics.storageUsed)}
            icon={HardDrive}
            color="text-purple-500"
          />
          <StatCard
            title="New Users"
            value={analytics.userMetrics.newUsers}
            icon={UserPlus}
            color="text-yellow-500"
          />
        </div>

        <div className="grid gap-6 lg:grid-cols-2 mb-8">
          {/* Stream Analytics Chart */}
          <div className="bg-gray-800 rounded-lg p-6">
            <h3 className="text-lg font-semibold text-white mb-4">Stream Analytics</h3>
            <div className="h-80">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={streamData}>
                  <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                  <XAxis dataKey="name" stroke="#9CA3AF" />
                  <YAxis stroke="#9CA3AF" />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: '#1F2937',
                      border: 'none',
                      borderRadius: '0.375rem',
                      color: '#F3F4F6'
                    }}
                  />
                  <Bar dataKey="value" fill="#3B82F6" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>

          {/* Group Analytics */}
          <div className="bg-gray-800 rounded-lg p-6">
            <h3 className="text-lg font-semibold text-white mb-4">Group Analytics</h3>
            <div className="space-y-4">
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <Group className="w-5 h-5 text-blue-500 mr-2" />
                  <span className="text-gray-400">Total Groups</span>
                </div>
                <span className="text-white font-semibold">
                  {analytics.groupMetrics.totalGroups}
                </span>
              </div>
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <Group className="w-5 h-5 text-green-500 mr-2" />
                  <span className="text-gray-400">Active Groups</span>
                </div>
                <span className="text-white font-semibold">
                  {analytics.groupMetrics.activeGroups}
                </span>
              </div>
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <Users className="w-5 h-5 text-purple-500 mr-2" />
                  <span className="text-gray-400">Average Group Size</span>
                </div>
                <span className="text-white font-semibold">
                  {analytics.groupMetrics.averageGroupSize.toFixed(1)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </RoleGuard>
  );
}
