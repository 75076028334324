import React from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Button } from '../components/ui/Button';
import {
  Video,
  Shield,
  Users,
  Clock,
  Cloud,
  Zap,
  FileVideo,
  MonitorPlay,
  Share2,
  Lock,
  BarChart,
  Settings
} from 'lucide-react';

const features = [
  {
    title: 'Real-Time Streaming',
    description: 'Stream surgical procedures in real-time with ultra-low latency and high-quality video.',
    icon: Video
  },
  {
    title: 'Secure Transmission',
    description: 'End-to-end encryption and HIPAA-compliant data transmission for maximum security.',
    icon: Shield
  },
  {
    title: 'Collaborative Learning',
    description: 'Create and manage groups for collaborative learning and knowledge sharing.',
    icon: Users
  },
  {
    title: 'On-Demand Access',
    description: 'Access recorded procedures anytime, anywhere with our cloud storage solution.',
    icon: Clock
  },
  {
    title: 'Cloud Storage',
    description: 'Secure cloud storage for all your surgical videos with easy retrieval and sharing.',
    icon: Cloud
  },
  {
    title: 'High Performance',
    description: 'Optimized for low-latency streaming and high-quality video transmission.',
    icon: Zap
  },
  {
    title: 'Video Management',
    description: 'Comprehensive tools for organizing, tagging, and managing your video content.',
    icon: FileVideo
  },
  {
    title: 'Multi-Device Support',
    description: 'Stream and view content from any device with our responsive platform.',
    icon: MonitorPlay
  },
  {
    title: 'Easy Sharing',
    description: 'Share procedures securely with specific users or groups with granular permissions.',
    icon: Share2
  },
  {
    title: 'Advanced Security',
    description: 'Role-based access control and audit logging for enhanced security.',
    icon: Lock
  },
  {
    title: 'Analytics',
    description: 'Detailed analytics and insights about your streams and viewer engagement.',
    icon: BarChart
  },
  {
    title: 'Customization',
    description: 'Flexible settings and configurations to meet your specific needs.',
    icon: Settings
  }
];

const FeaturesPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Features | SVIZ.live - Advanced Surgical Video Platform</title>
        <meta name="description" content="Explore SVIZ.live's comprehensive features including real-time streaming, secure transmission, collaborative learning, and more. Purpose-built for medical professionals." />
        <meta name="keywords" content="surgical video streaming, medical education platform, real-time streaming, HIPAA compliant, collaborative learning, medical video platform" />
        <meta property="og:title" content="Features | SVIZ.live - Advanced Surgical Video Platform" />
        <meta property="og:description" content="Discover how SVIZ.live revolutionizes surgical education with real-time streaming, secure transmission, and collaborative learning features." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://sviz.live/features" />
      </Helmet>

      <div className="min-h-screen bg-background">
        {/* Navigation */}
        <nav className="fixed top-0 left-0 right-0 z-50 bg-background/80 backdrop-blur-lg border-b border-border">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between items-center h-16">
              <Link to="/" className="text-2xl font-bold bg-gradient-to-r from-primary to-blue-600 bg-clip-text text-transparent">
                SVIZ.live
              </Link>
              <Button variant="outline" size="sm" asChild>
                <Link to="/">Back to Home</Link>
              </Button>
            </div>
          </div>
        </nav>

        {/* Hero Section */}
        <motion.section
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="pt-20 pb-16 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto"
        >
          <div className="text-center">
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="text-4xl sm:text-5xl lg:text-6xl font-bold bg-gradient-to-r from-primary to-blue-600 bg-clip-text text-transparent"
            >
              Powerful Features for
              <br />
              Medical Professionals
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
              className="mt-6 text-xl text-text-secondary max-w-3xl mx-auto"
            >
              Experience the next generation of surgical video streaming with our comprehensive suite of features designed specifically for healthcare.
            </motion.p>
          </div>
        </motion.section>

        {/* Features Grid */}
        <motion.section
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
          className="py-16 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto"
        >
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <motion.div
                key={feature.title}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.1 * index }}
                className="p-6 rounded-2xl bg-background-secondary border border-border hover:border-primary/50 transition-colors"
              >
                <feature.icon className="w-10 h-10 text-primary mb-4" />
                <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                <p className="text-text-secondary">{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </motion.section>

        {/* CTA Section */}
        <motion.section
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6 }}
          className="py-16 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto text-center"
        >
          <h2 className="text-3xl sm:text-4xl font-bold mb-8">Ready to Transform Your Surgical Education?</h2>
          <div className="flex flex-col sm:flex-row gap-4 justify-center mt-8">
            <Button size="lg">
              <Link to="/demo">Get Started</Link>
            </Button>
            <Button variant="outline" size="lg">
              <Link to="/pricing">View Pricing</Link>
            </Button>
          </div>
        </motion.section>

        {/* Footer */}
        <footer className="bg-background-secondary border-t border-border">
          <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
            <div className="text-center">
              <Link to="/" className="text-2xl font-bold bg-gradient-to-r from-primary to-blue-600 bg-clip-text text-transparent">
                SVIZ.live
              </Link>
              <p className="mt-4 text-text-secondary">
                Advanced surgical video platform for medical professionals.
              </p>
              <div className="mt-8">
                <Button variant="outline" size="sm" asChild>
                  <Link to="/">Back to Home</Link>
                </Button>
              </div>
            </div>
            <div className="mt-8 pt-8 border-t border-border">
              <p className="text-center text-text-secondary">
                {new Date().getFullYear()} SVIZ.live. All rights reserved.
              </p>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default FeaturesPage;
