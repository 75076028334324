import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { Button } from '../components/ui/Button';
import { Input } from '../components/ui/Input';
import { Textarea } from '../components/ui/Textarea';
import {
  Calendar,
  Clock,
  Users,
  Building2,
  Mail,
  Phone,
  MessageSquare,
  CheckCircle
} from 'lucide-react';
import { Link } from 'react-router-dom';

interface DemoFormData {
  name: string;
  email: string;
  phone: string;
  organization: string;
  role: string;
  teamSize: string;
  message: string;
}

const DemoPage: React.FC = () => {
  const [formData, setFormData] = useState<DemoFormData>({
    name: '',
    email: '',
    phone: '',
    organization: '',
    role: '',
    teamSize: '',
    message: ''
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Here you would typically send the form data to your backend
    console.log('Form submitted:', formData);
    setIsSubmitted(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  return (
    <>
      <Helmet>
        <title>Request Demo | SVIZ.live - Advanced Surgical Video Platform</title>
        <meta name="description" content="Schedule a personalized demo of SVIZ.live's surgical video streaming platform. See how our platform can revolutionize your medical education and training." />
        <meta name="keywords" content="surgical video platform demo, medical streaming demo, healthcare platform trial, surgical education demo" />
        <meta property="og:title" content="Request Demo | SVIZ.live - Advanced Surgical Video Platform" />
        <meta property="og:description" content="Get a personalized demo of SVIZ.live and discover how our platform can enhance your surgical education and training programs." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://sviz.live/demo" />
      </Helmet>

      <div className="min-h-screen bg-background">
        {/* Navigation */}
        <nav className="fixed top-0 left-0 right-0 z-50 bg-background/80 backdrop-blur-lg border-b border-border">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between items-center h-16">
              <Link to="/" className="text-2xl font-bold bg-gradient-to-r from-primary to-blue-600 bg-clip-text text-transparent">
                SVIZ.live
              </Link>
              <Button variant="outline" size="sm" asChild>
                <Link to="/">Back to Home</Link>
              </Button>
            </div>
          </div>
        </nav>

        {/* Hero Section */}
        <motion.section
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="pt-20 pb-16 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto"
        >
          <div className="text-center">
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="text-4xl sm:text-5xl lg:text-6xl font-bold bg-gradient-to-r from-primary to-blue-600 bg-clip-text text-transparent"
            >
              See SVIZ.live in Action
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
              className="mt-6 text-xl text-text-secondary max-w-3xl mx-auto"
            >
              Schedule a personalized demo with our team and discover how SVIZ.live can transform your surgical education program.
            </motion.p>
          </div>
        </motion.section>

        {/* Demo Benefits */}
        <motion.section
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
          className="py-16 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto"
        >
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16">
            <div className="flex items-start space-x-4">
              <Calendar className="w-6 h-6 text-primary flex-shrink-0" />
              <div>
                <h3 className="font-semibold mb-2">Flexible Scheduling</h3>
                <p className="text-text-secondary">Choose a time that works best for your team</p>
              </div>
            </div>
            <div className="flex items-start space-x-4">
              <Clock className="w-6 h-6 text-primary flex-shrink-0" />
              <div>
                <h3 className="font-semibold mb-2">30-Minute Demo</h3>
                <p className="text-text-secondary">Comprehensive overview of key features</p>
              </div>
            </div>
            <div className="flex items-start space-x-4">
              <Users className="w-6 h-6 text-primary flex-shrink-0" />
              <div>
                <h3 className="font-semibold mb-2">Team Training</h3>
                <p className="text-text-secondary">Bring your whole team to learn</p>
              </div>
            </div>
            <div className="flex items-start space-x-4">
              <MessageSquare className="w-6 h-6 text-primary flex-shrink-0" />
              <div>
                <h3 className="font-semibold mb-2">Q&A Session</h3>
                <p className="text-text-secondary">Get all your questions answered</p>
              </div>
            </div>
          </div>

          {/* Demo Form */}
          <div className="max-w-2xl mx-auto">
            {isSubmitted ? (
              <motion.div
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                className="text-center p-8 rounded-2xl bg-background-secondary border border-border"
              >
                <CheckCircle className="w-16 h-16 text-primary mx-auto mb-4" />
                <h2 className="text-2xl font-bold mb-4">Thank You!</h2>
                <p className="text-text-secondary mb-6">
                  We've received your demo request and will contact you shortly to schedule a time that works best for you.
                </p>
                <Button variant="outline" onClick={() => setIsSubmitted(false)}>
                  Request Another Demo
                </Button>
              </motion.div>
            ) : (
              <motion.form
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5 }}
                onSubmit={handleSubmit}
                className="space-y-6 p-8 rounded-2xl bg-background-secondary border border-border"
              >
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="space-y-2">
                    <label htmlFor="name" className="block text-sm font-medium">
                      Full Name
                    </label>
                    <Input
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="space-y-2">
                    <label htmlFor="email" className="block text-sm font-medium">
                      Email Address
                    </label>
                    <Input
                      id="email"
                      name="email"
                      type="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="space-y-2">
                    <label htmlFor="phone" className="block text-sm font-medium">
                      Phone Number
                    </label>
                    <Input
                      id="phone"
                      name="phone"
                      type="tel"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="space-y-2">
                    <label htmlFor="organization" className="block text-sm font-medium">
                      Organization
                    </label>
                    <Input
                      id="organization"
                      name="organization"
                      value={formData.organization}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="space-y-2">
                    <label htmlFor="role" className="block text-sm font-medium">
                      Job Role
                    </label>
                    <Input
                      id="role"
                      name="role"
                      value={formData.role}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="space-y-2">
                    <label htmlFor="teamSize" className="block text-sm font-medium">
                      Team Size
                    </label>
                    <Input
                      id="teamSize"
                      name="teamSize"
                      value={formData.teamSize}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="space-y-2">
                  <label htmlFor="message" className="block text-sm font-medium">
                    Additional Information
                  </label>
                  <Textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    rows={4}
                    placeholder="Tell us about your specific needs and what you'd like to learn about during the demo."
                  />
                </div>
                <Button type="submit" className="w-full">
                  Request Demo
                </Button>
              </motion.form>
            )}
          </div>
        </motion.section>

        {/* Footer */}
        <footer className="bg-background-secondary border-t border-border">
          <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
            <div className="text-center">
              <Link to="/" className="text-2xl font-bold bg-gradient-to-r from-primary to-blue-600 bg-clip-text text-transparent">
                SVIZ.live
              </Link>
              <p className="mt-4 text-text-secondary">
                Advanced surgical video platform for medical professionals.
              </p>
              <div className="mt-8">
                <Button variant="outline" size="sm" asChild>
                  <Link to="/">Back to Home</Link>
                </Button>
              </div>
            </div>
            <div className="mt-8 pt-8 border-t border-border">
              <p className="text-center text-text-secondary">
                {new Date().getFullYear()} SVIZ.live. All rights reserved.
              </p>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default DemoPage;
