import React, { useEffect, useState } from 'react';
import { Play, History } from 'lucide-react';
import { useStreamStore } from '../store/stream';
import { useAuthStore } from '../store/auth';
import { StreamCard } from '../components/stream/StreamCard';

export function StreamHistoryPage() {
  const { user } = useAuthStore();
  const { streams, fetchUserStreams, isLoading } = useStreamStore();
  const [filter, setFilter] = useState<'all' | 'active' | 'ended'>('all');

  useEffect(() => {
    if (user?.id) {
      fetchUserStreams(user.id);
    }
  }, [user?.id, fetchUserStreams]);

  const filteredStreams = streams.filter(stream => {
    if (filter === 'active') return stream.isLive;
    if (filter === 'ended') return !stream.isLive;
    return true;
  });

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div>
        <h1 className="text-3xl font-light tracking-wide text-white mb-2">Your Stream History</h1>
        <p className="text-gray-400 font-light">
          View and manage your past and current streams
        </p>
      </div>

      <div className="flex space-x-4 mb-6">
        <button
          onClick={() => setFilter('all')}
          className={`px-4 py-2 rounded-lg font-light ${
            filter === 'all'
              ? 'bg-blue-600 text-white'
              : 'bg-gray-800/50 text-gray-300 hover:bg-gray-700/50'
          }`}
        >
          All Streams
        </button>
        <button
          onClick={() => setFilter('active')}
          className={`px-4 py-2 rounded-lg font-light ${
            filter === 'active'
              ? 'bg-blue-600 text-white'
              : 'bg-gray-800/50 text-gray-300 hover:bg-gray-700/50'
          }`}
        >
          Active Streams
        </button>
        <button
          onClick={() => setFilter('ended')}
          className={`px-4 py-2 rounded-lg font-light ${
            filter === 'ended'
              ? 'bg-blue-600 text-white'
              : 'bg-gray-800/50 text-gray-300 hover:bg-gray-700/50'
          }`}
        >
          Past Streams
        </button>
      </div>

      {filteredStreams.length === 0 ? (
        <div className="text-center py-12 bg-gradient-to-br from-gray-800/50 to-gray-900/50 rounded-xl border border-gray-700/50 backdrop-blur-sm">
          <History className="h-12 w-12 text-gray-500 mx-auto mb-4" />
          <h2 className="text-xl font-light text-white mb-2">No streams found</h2>
          <p className="text-gray-400">
            {filter === 'active'
              ? "You don't have any active streams"
              : filter === 'ended'
              ? "You haven't completed any streams yet"
              : "You haven't created any streams yet"}
          </p>
        </div>
      ) : (
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {filteredStreams.map((stream) => (
            <StreamCard key={stream.id} stream={stream} />
          ))}
        </div>
      )}
    </div>
  );
}
