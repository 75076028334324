import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoleGuard } from '../../components/auth/RoleGuard';
import { PERMISSIONS } from '../../config/roles';
import { Group } from '../../types/admin';
import { listGroups } from '../../services/admin';
import { Users, Lock, Unlock, ChevronRight } from 'lucide-react';
import { format } from 'date-fns';

export function AdminGroupsPage() {
  const [groups, setGroups] = useState<Group[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    loadGroups();
  }, []);

  const loadGroups = async () => {
    try {
      const fetchedGroups = await listGroups();
      setGroups(fetchedGroups);
    } catch (error) {
      setError('Failed to load groups');
      console.error('Error loading groups:', error);
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (date: string) => {
    try {
      if (!date) return 'N/A';
      const parsedDate = new Date(date);
      if (isNaN(parsedDate.getTime())) return 'Invalid Date';
      return format(parsedDate, 'MMM d, yyyy');
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid Date';
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-red-500">{error}</div>
      </div>
    );
  }

  return (
    <RoleGuard
      requiredPermission={PERMISSIONS.MANAGE_GROUPS.id}
      fallback={
        <div className="text-center py-12">
          <h2 className="text-2xl font-semibold text-white">Access Denied</h2>
          <p className="text-gray-400 mt-2">
            You don't have permission to manage groups.
          </p>
        </div>
      }
    >
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <div>
            <h1 className="text-2xl font-bold text-white">Groups Management</h1>
            <p className="text-gray-400 mt-1">Manage and monitor all groups</p>
          </div>
        </div>

        {error && (
          <div className="mb-4 p-4 bg-red-500/10 border border-red-500 rounded text-red-500">
            {error}
          </div>
        )}

        <div className="bg-gray-800 rounded-lg overflow-hidden">
          <table className="w-full">
            <thead>
              <tr className="bg-gray-900">
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Group Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Created By
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Created On
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Members
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Privacy
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-700">
              {groups.map((group) => (
                <tr
                  key={group.id}
                  className="hover:bg-gray-700/50 cursor-pointer"
                  onClick={() => navigate(`/admin/groups/${group.id}`)}
                >
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <Users className="h-5 w-5 text-gray-400 mr-3" />
                      <span className="text-white font-medium">{group.name}</span>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-gray-300">
                    {group.createdBy}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-gray-300">
                    {formatDate(group.createdAt)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-gray-300">
                    {group.members?.length || 0}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      {group.settings?.isPrivate ? (
                        <>
                          <Lock className="h-4 w-4 text-yellow-400 mr-2" />
                          <span className="text-yellow-400">Private</span>
                        </>
                      ) : (
                        <>
                          <Unlock className="h-4 w-4 text-green-400 mr-2" />
                          <span className="text-green-400">Public</span>
                        </>
                      )}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-gray-300">
                    <ChevronRight className="h-5 w-5" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {groups.length === 0 && (
            <div className="text-center py-8 text-gray-400">
              No groups found.
            </div>
          )}
        </div>
      </div>
    </RoleGuard>
  );
}
