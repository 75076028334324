import React, { useEffect, useRef, useState } from 'react';
import { X, Maximize2, Minimize2, Pause, Play, Square, Volume2, VolumeX } from 'lucide-react';

interface MiniPlayerProps {
  streamKey: string;
  onClose: () => void;
}

export function MiniPlayer({ streamKey, onClose }: MiniPlayerProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [error, setError] = useState<string | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const mountedRef = useRef(true);

  useEffect(() => {
    mountedRef.current = true;
    const video = videoRef.current;
    if (!video) return;

    let playAttemptInterval: NodeJS.Timeout;
    let isAttemptingPlay = false;

    const handlePlay = () => {
      if (mountedRef.current) {
        setIsPlaying(true);
        setError(null);
        clearInterval(playAttemptInterval);
      }
    };

    const handleError = () => {
      if (mountedRef.current) {
        setError('Failed to play video stream');
        setIsPlaying(false);
        clearInterval(playAttemptInterval);
      }
    };

    const handlePause = () => {
      if (mountedRef.current) {
        setIsPlaying(false);
      }
    };

    const attemptPlay = async () => {
      if (!mountedRef.current || isAttemptingPlay) return;
      
      try {
        isAttemptingPlay = true;
        await video.play();
        isAttemptingPlay = false;
      } catch (err) {
        isAttemptingPlay = false;
        if (!mountedRef.current) return;
        
        if (err instanceof Error && err.name === 'AbortError') {
          return;
        }
        
        console.error('Error playing video:', err);
        setError('Failed to play video stream');
      }
    };

    video.src = `https://live.surgi-cloud.com/${streamKey.split('_')[0]}.mp4`;
    video.muted = true;
    video.playsInline = true;

    video.addEventListener('play', handlePlay);
    video.addEventListener('error', handleError);
    video.addEventListener('pause', handlePause);

    playAttemptInterval = setInterval(attemptPlay, 1000);
    attemptPlay();

    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      mountedRef.current = false;
      clearInterval(playAttemptInterval);
      
      video.removeEventListener('play', handlePlay);
      video.removeEventListener('error', handleError);
      video.removeEventListener('pause', handlePause);
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      
      if (video.playing) {
        video.pause();
      }
      video.src = '';
      video.load();
    };
  }, [streamKey]);

  const togglePlay = async () => {
    const video = videoRef.current;
    if (!video) return;

    try {
      if (video.paused) {
        await video.play();
      } else {
        video.pause();
      }
    } catch (err) {
      console.error('Error toggling play state:', err);
    }
  };

  const toggleMute = () => {
    const video = videoRef.current;
    if (!video) return;
    
    video.muted = !video.muted;
    setIsMuted(video.muted);
  };

  const toggleFullscreen = async () => {
    const container = containerRef.current;
    if (!container) return;

    try {
      if (!document.fullscreenElement) {
        await container.requestFullscreen();
      } else {
        await document.exitFullscreen();
      }
    } catch (err) {
      console.error('Error toggling fullscreen:', err);
    }
  };

  const stopStream = () => {
    const video = videoRef.current;
    if (!video) return;
    
    video.pause();
    video.currentTime = 0;
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black/50 z-50">
      <div 
        ref={containerRef}
        className={`bg-gray-900 rounded-lg shadow-xl overflow-hidden ${
          isFullscreen ? 'w-full h-full' : 'w-[800px] max-w-[90vw]'
        }`}
      >
        <div className="relative">
          {error ? (
            <div className="p-4 text-red-400 text-sm">{error}</div>
          ) : (
            <div className="relative">
              <video
                ref={videoRef}
                className={`w-full ${isFullscreen ? 'h-screen' : 'h-[450px]'} object-contain bg-black`}
                playsInline
              />
              {!isPlaying && !error && (
                <div className="absolute inset-0 flex items-center justify-center bg-black/50">
                  <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
                </div>
              )}
            </div>
          )}

          {/* Video Controls */}
          <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black/80 to-transparent">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-4">
                <button
                  onClick={togglePlay}
                  className="p-2 rounded-full hover:bg-white/20 transition-colors"
                >
                  {isPlaying ? (
                    <Pause className="w-5 h-5 text-white" />
                  ) : (
                    <Play className="w-5 h-5 text-white" />
                  )}
                </button>
                <button
                  onClick={toggleMute}
                  className="p-2 rounded-full hover:bg-white/20 transition-colors"
                >
                  {isMuted ? (
                    <VolumeX className="w-5 h-5 text-white" />
                  ) : (
                    <Volume2 className="w-5 h-5 text-white" />
                  )}
                </button>
                <button
                  onClick={stopStream}
                  className="p-2 rounded-full hover:bg-white/20 transition-colors"
                >
                  <Square className="w-5 h-5 text-white" />
                </button>
              </div>
              <div className="flex items-center space-x-4">
                <button
                  onClick={toggleFullscreen}
                  className="p-2 rounded-full hover:bg-white/20 transition-colors"
                >
                  {isFullscreen ? (
                    <Minimize2 className="w-5 h-5 text-white" />
                  ) : (
                    <Maximize2 className="w-5 h-5 text-white" />
                  )}
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    onClose();
                  }}
                  className="p-2 rounded-full hover:bg-white/20 transition-colors"
                >
                  <X className="w-5 h-5 text-white" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
