import { useMemo } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
} from 'recharts';
import { Card } from '../ui/Card';
import { format } from 'date-fns';
import { Eye, Clock, TrendingUp, Users } from 'lucide-react';
import type { Stream } from '../../types/stream';

interface StreamAnalyticsProps {
  streams: Stream[];
  compact?: boolean;
}

interface StatCardProps {
  title: string;
  value: string | number;
  icon: React.ElementType;
  trend?: number;
}

function StatCard({ title, value, icon: Icon, trend }: StatCardProps) {
  return (
    <Card className="p-6">
      <div className="flex items-center justify-between">
        <div>
          <p className="text-sm font-medium text-gray-400">{title}</p>
          <h3 className="text-2xl font-bold text-white mt-1">{value}</h3>
        </div>
        <div className="rounded-full bg-blue-500/10 p-3">
          <Icon className="h-6 w-6 text-blue-500" />
        </div>
      </div>
      {trend !== undefined && (
        <div className="mt-4 flex items-center">
          <TrendingUp
            className={`h-4 w-4 ${trend >= 0 ? 'text-green-500' : 'text-red-500'}`}
          />
          <span
            className={`ml-2 text-sm ${
              trend >= 0 ? 'text-green-500' : 'text-red-500'
            }`}
          >
            {trend > 0 ? '+' : ''}
            {trend}% from last month
          </span>
        </div>
      )}
    </Card>
  );
}

export function StreamAnalytics({ streams, compact = false }: StreamAnalyticsProps) {
  const stats = useMemo(() => {
    const totalViews = streams.reduce((acc, stream) => acc + (stream.viewerCount || 0), 0);
    const totalHours = streams.reduce((acc, stream) => {
      if (stream.startedAt && stream.endedAt) {
        const duration = (new Date(stream.endedAt).getTime() - new Date(stream.startedAt).getTime()) / 3600000;
        return acc + duration;
      }
      return acc;
    }, 0);
    
    const highestViews = Math.max(...streams.map(s => s.viewerCount || 0));
    const avgViewersPerStream = totalViews / (streams.length || 1);

    return {
      totalViews,
      totalHours: totalHours.toFixed(1),
      highestViews,
      avgViewersPerStream: Math.round(avgViewersPerStream),
    };
  }, [streams]);

  const viewsData = useMemo(() => {
    return streams
      .sort((a, b) => new Date(a.startedAt || 0).getTime() - new Date(b.startedAt || 0).getTime())
      .map(stream => ({
        date: format(new Date(stream.startedAt || 0), 'MMM dd'),
        views: stream.viewerCount || 0,
      }));
  }, [streams]);

  const streamLengthData = useMemo(() => {
    return streams
      .sort((a, b) => new Date(a.startedAt || 0).getTime() - new Date(b.startedAt || 0).getTime())
      .map(stream => ({
        date: format(new Date(stream.startedAt || 0), 'MMM dd'),
        hours: stream.startedAt && stream.endedAt
          ? (new Date(stream.endedAt).getTime() - new Date(stream.startedAt).getTime()) / 3600000
          : 0,
      }));
  }, [streams]);

  if (compact) {
    return (
      <div className="space-y-6">
        <div className="grid grid-cols-2 gap-4">
          <StatCard
            title="Total Views"
            value={stats.totalViews.toLocaleString()}
            icon={Eye}
          />
          <StatCard
            title="Stream Hours"
            value={stats.totalHours}
            icon={Clock}
          />
        </div>
        <Card className="p-4">
          <h3 className="text-lg font-semibold text-white mb-4">Views Trend</h3>
          <div className="h-[200px]">
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart data={viewsData}>
                <defs>
                  <linearGradient id="viewsGradient" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#3b82f6" stopOpacity={0.8}/>
                    <stop offset="95%" stopColor="#3b82f6" stopOpacity={0}/>
                  </linearGradient>
                </defs>
                <XAxis dataKey="date" stroke="#6b7280" />
                <YAxis stroke="#6b7280" />
                <Tooltip
                  contentStyle={{
                    backgroundColor: '#1f2937',
                    border: 'none',
                    borderRadius: '0.5rem',
                  }}
                />
                <Area
                  type="monotone"
                  dataKey="views"
                  stroke="#3b82f6"
                  fillOpacity={1}
                  fill="url(#viewsGradient)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </Card>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
        <StatCard
          title="Total Views"
          value={stats.totalViews.toLocaleString()}
          icon={Eye}
          trend={12}
        />
        <StatCard
          title="Stream Hours"
          value={stats.totalHours}
          icon={Clock}
          trend={8}
        />
        <StatCard
          title="Highest Views"
          value={stats.highestViews.toLocaleString()}
          icon={TrendingUp}
          trend={15}
        />
        <StatCard
          title="Avg. Viewers"
          value={stats.avgViewersPerStream.toLocaleString()}
          icon={Users}
          trend={5}
        />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <Card className="p-6">
          <h3 className="text-lg font-semibold text-white mb-4">Views Over Time</h3>
          <div className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart data={viewsData}>
                <defs>
                  <linearGradient id="viewsGradient" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#3b82f6" stopOpacity={0.8}/>
                    <stop offset="95%" stopColor="#3b82f6" stopOpacity={0}/>
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                <XAxis dataKey="date" stroke="#6b7280" />
                <YAxis stroke="#6b7280" />
                <Tooltip
                  contentStyle={{
                    backgroundColor: '#1f2937',
                    border: 'none',
                    borderRadius: '0.5rem',
                  }}
                />
                <Area
                  type="monotone"
                  dataKey="views"
                  stroke="#3b82f6"
                  fillOpacity={1}
                  fill="url(#viewsGradient)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </Card>

        <Card className="p-6">
          <h3 className="text-lg font-semibold text-white mb-4">Stream Duration</h3>
          <div className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={streamLengthData}>
                <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                <XAxis dataKey="date" stroke="#6b7280" />
                <YAxis stroke="#6b7280" />
                <Tooltip
                  contentStyle={{
                    backgroundColor: '#1f2937',
                    border: 'none',
                    borderRadius: '0.5rem',
                  }}
                />
                <Bar dataKey="hours" fill="#3b82f6" radius={[4, 4, 0, 0]} />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </Card>
      </div>
    </div>
  );
}
