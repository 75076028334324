import { create } from 'zustand';
import type { ChatState, ChatMessage } from '../types/chat';
import { useAuthStore } from './auth';

export const useChatStore = create<ChatState>((set, get) => ({
  messages: [],
  isLoading: false,
  error: null,

  sendMessage: async (streamId: string, content: string) => {
    const user = useAuthStore.getState().user;
    if (!user) throw new Error('User not authenticated');

    const newMessage: ChatMessage = {
      id: Math.random().toString(),
      streamId,
      userId: user.id,
      userName: user.name,
      content,
      timestamp: new Date().toISOString(),
    };

    set((state) => ({
      messages: [...state.messages, newMessage],
    }));
  },

  fetchMessages: async (streamId: string) => {
    set({ isLoading: true });
    try {
      // TODO: Replace with actual API call
      const mockMessages: ChatMessage[] = [
        {
          id: '1',
          streamId,
          userId: '2',
          userName: 'Dr. Sarah Johnson',
          content: 'Great demonstration of the technique!',
          timestamp: new Date().toISOString(),
        },
        {
          id: '2',
          streamId,
          userId: '3',
          userName: 'Dr. Michael Chen',
          content: 'Could you explain that last step in more detail?',
          timestamp: new Date().toISOString(),
        },
      ];
      set({ messages: mockMessages });
    } catch (error) {
      set({ error: 'Failed to fetch messages' });
    } finally {
      set({ isLoading: false });
    }
  },
}));