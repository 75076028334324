import { GROUP_ERRORS } from './constants';
import { createMemberDoc, deleteMemberDoc, getGroupDoc, getMemberDoc } from './db';
import { checkGroupPermissions } from './permissions';
import { handleFirebaseError } from '../utils/errors';
import { logger } from '../utils/logger';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';

export async function joinGroup(groupId: string, userId: string, userName: string) {
  logger.debug('Attempting to join group', { groupId, userId });
  
  try {
    const { data: groupData } = await getGroupDoc(groupId);
    const { data: memberData } = await getMemberDoc(groupId, userId);

    if (memberData) {
      throw new Error(GROUP_ERRORS.ALREADY_MEMBER);
    }

    // Check both root-level and settings-level privacy
    const isPrivate = groupData.isPrivate || groupData.settings?.isPrivate;
    const requiresApproval = groupData.settings?.joinRequiresApproval;

    if (isPrivate || requiresApproval) {
      const { isAdmin } = await checkGroupPermissions(groupId, userId);
      if (!isAdmin) {
        throw new Error(GROUP_ERRORS.PRIVATE_GROUP);
      }
    }

    await createMemberDoc(groupId, userId, userName, 'member');
    logger.info('Successfully joined group', { groupId, userId });
  } catch (error) {
    logger.error('Failed to join group', error as Error);
    handleFirebaseError(error);
  }
}

export async function leaveGroup(groupId: string, userId: string) {
  logger.debug('Attempting to leave group', { groupId, userId });
  
  try {
    const { isMember, isOwner } = await checkGroupPermissions(groupId, userId);

    if (!isMember) {
      throw new Error(GROUP_ERRORS.NOT_MEMBER);
    }

    if (isOwner) {
      throw new Error(GROUP_ERRORS.OWNER_LEAVE);
    }

    await deleteMemberDoc(groupId, userId);
    logger.info('Successfully left group', { groupId, userId });
  } catch (error) {
    logger.error('Failed to leave group', error as Error);
    handleFirebaseError(error);
  }
}

export async function removeGroupMember(groupId: string, userId: string, adminId: string) {
  logger.debug('Attempting to remove group member', { groupId, userId, adminId });
  
  try {
    const [memberPerms, adminPerms] = await Promise.all([
      checkGroupPermissions(groupId, userId),
      checkGroupPermissions(groupId, adminId),
    ]);

    if (!memberPerms.isMember) {
      throw new Error(GROUP_ERRORS.NOT_MEMBER);
    }

    if (!adminPerms.isAdmin && !adminPerms.isOwner) {
      throw new Error(GROUP_ERRORS.PERMISSION_DENIED);
    }

    if (memberPerms.isOwner) {
      throw new Error(GROUP_ERRORS.OWNER_REMOVE);
    }

    await deleteMemberDoc(groupId, userId);
    logger.info('Successfully removed group member', { groupId, userId, adminId });
  } catch (error) {
    logger.error('Failed to remove group member', error as Error);
    handleFirebaseError(error);
  }
}

export async function inviteMember(groupId: string, email: string, inviterId: string) {
  logger.debug('Attempting to invite member to group', { groupId, email, inviterId });
  
  try {
    // Check inviter's permissions
    const { isAdmin, isOwner } = await checkGroupPermissions(groupId, inviterId);
    if (!isAdmin && !isOwner) {
      throw new Error(GROUP_ERRORS.PERMISSION_DENIED);
    }

    // Get group data to check if it exists
    const { data: groupData } = await getGroupDoc(groupId);
    if (!groupData) {
      throw new Error(GROUP_ERRORS.NOT_FOUND);
    }

    // Find user by email
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where('email', '==', email));
    const querySnapshot = await getDocs(q);
    
    if (querySnapshot.empty) {
      throw new Error('User with this email not found');
    }

    const userDoc = querySnapshot.docs[0];
    const userData = userDoc.data();
    const userId = userDoc.id;

    // Check if user is already a member
    const { data: memberData } = await getMemberDoc(groupId, userId);
    if (memberData) {
      throw new Error(GROUP_ERRORS.ALREADY_MEMBER);
    }

    // Add user to group with inviter information
    await createMemberDoc(groupId, userId, userData.name || email, 'member', inviterId);
    logger.info('Successfully invited member to group', { groupId, email });
  } catch (error) {
    logger.error('Failed to invite member to group', error as Error);
    handleFirebaseError(error);
  }
}