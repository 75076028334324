import React, { useEffect, useState } from 'react';
import { Play } from 'lucide-react';
import StreamThumbnail from '../VideoStream/StreamThumbnail';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { MiniPlayer } from '../VideoStream/MiniPlayer';
import { Link } from 'react-router-dom';

interface GroupStreamsProps {
  groupId: string;
}

interface StreamInfo {
  userId: string;
  userName: string;
  streamKey: string;
  addedAt: Date;
}

export function GroupStreams({ groupId }: GroupStreamsProps) {
  const [streams, setStreams] = useState<StreamInfo[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeStreamKey, setActiveStreamKey] = useState<string | null>(null);

  useEffect(() => {
    const fetchGroupStreams = async () => {
      try {
        const groupDoc = await getDoc(doc(db, 'groups', groupId));
        if (!groupDoc.exists()) return;

        const groupData = groupDoc.data();
        const groupStreams = groupData.streams || [];

        // Fetch user info for each stream
        const streamsWithUserInfo = await Promise.all(
          groupStreams.map(async (stream: any) => {
            // Get user info
            const userDoc = await getDoc(doc(db, 'users', stream.userId));
            const userData = userDoc.data();
            
            // Get stream info from streams collection
            const streamDoc = await getDoc(doc(db, 'streams', stream.streamKey));
            const streamData = streamDoc.exists() ? streamDoc.data() : null;
            
            return {
              userId: stream.userId,
              userName: userData?.displayName || 'Unknown User',
              streamKey: stream.streamKey,
              addedAt: stream.addedAt?.toDate() || new Date(),
            };
          })
        );

        // Remove any duplicate streams based on streamKey
        const uniqueStreams = streamsWithUserInfo.filter(
          (stream, index, self) =>
            index === self.findIndex((s) => s.streamKey === stream.streamKey)
        );

        setStreams(uniqueStreams);
      } catch (error) {
        console.error('Error fetching group streams:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchGroupStreams();
  }, [groupId]);

  const handleStreamClick = (streamKey: string) => {
    setActiveStreamKey(streamKey);
  };

  const handleClosePlayer = () => {
    setActiveStreamKey(null);
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (streams.length === 0) {
    return (
      <div className="bg-gradient-to-br from-gray-800/30 to-gray-900/30 rounded-xl border border-gray-700/50 backdrop-blur-sm p-6">
        <div className="flex items-center space-x-2 mb-4">
          <Play className="h-5 w-5 text-gray-400" />
          <h2 className="text-xl font-light tracking-wide text-white/90">Group Streams</h2>
        </div>
        <p className="text-gray-400 font-light">No streams available for this group yet.</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold text-white">Streams</h2>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {streams.map((stream) => (
          <div key={stream.streamKey} className="group relative">
            <div
              onClick={() => handleStreamClick(stream.streamKey)}
              className="cursor-pointer relative rounded-lg overflow-hidden hover:ring-2 hover:ring-blue-500 transition-all"
            >
              <StreamThumbnail 
                streamKey={stream.streamKey} 
                userId={stream.userId} 
                userName={stream.userName} 
              />
              <div className="absolute inset-0 bg-black bg-opacity-40 opacity-0 group-hover:opacity-100 transition-opacity flex items-center justify-center">
                <Play className="w-12 h-12 text-white" />
              </div>
            </div>
            <div className="mt-2">
              <p className="text-sm font-medium text-gray-200">{stream.userName}</p>
              <p className="text-xs text-gray-400">
                Added {new Date(stream.addedAt).toLocaleDateString()}
              </p>
            </div>
          </div>
        ))}
      </div>
      
      {activeStreamKey && (
        <MiniPlayer
          streamKey={activeStreamKey}
          onClose={handleClosePlayer}
        />
      )}
    </div>
  );
}