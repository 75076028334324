import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGroupStore } from '../store/group';
import { GroupHeader } from '../components/group/GroupHeader';
import { GroupMembers } from '../components/group/GroupMembers';
import { GroupStreams } from '../components/group/GroupStreams';
import { GroupSettings } from '../components/group/GroupSettings';
import { InviteMember } from '../components/group/InviteMember';
import { useAuthStore } from '../store/auth';
import { GroupChat } from '../components/GroupChat';
import GroupResources from '../components/GroupResources';

export function GroupDetailPage() {
  const { groupId } = useParams<{ groupId: string }>();
  const { currentGroup, fetchGroup, isLoading, error } = useGroupStore();
  const { user } = useAuthStore();
  const [loadError, setLoadError] = useState<string | null>(null);

  useEffect(() => {
    async function loadGroup() {
      if (!groupId) return;
      try {
        setLoadError(null);
        await fetchGroup(groupId);
      } catch (error: any) {
        setLoadError(error.message || 'Failed to load group');
      }
    }
    loadGroup();
  }, [groupId, fetchGroup]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (loadError || error) {
    return (
      <div className="text-center py-12 bg-red-50/10 rounded-xl border border-red-200/20">
        <h2 className="text-2xl font-light text-red-400">Error Loading Group</h2>
        <p className="text-red-300/70 mt-2">{loadError || error}</p>
      </div>
    );
  }

  if (!currentGroup) {
    return (
      <div className="text-center py-12">
        <h2 className="text-2xl font-light text-white">Group not found</h2>
        <p className="text-gray-400 mt-2">The group you're looking for doesn't exist.</p>
      </div>
    );
  }

  const isOwner = user?.id === currentGroup.ownerId;
  const isAdmin = currentGroup.members?.some(
    (member) => member.userId === user?.id && ['owner', 'admin'].includes(member.role)
  ) ?? false;

  return (
    <div className="container mx-auto px-4 py-8 space-y-8">
      <GroupHeader group={currentGroup} />
      
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        <div className="lg:col-span-2 space-y-8">
          {/* Streams Section */}
          <GroupStreams groupId={currentGroup.id} />

          {/* Chat Section */}
          <div className="bg-gray-800/50 rounded-xl p-4">
            <h2 className="text-xl font-semibold text-white mb-4">Group Chat</h2>
            <GroupChat groupId={currentGroup.id} />
          </div>

          {/* Resources Section */}
          <GroupResources 
            groupId={currentGroup.id} 
            isAdmin={isAdmin} 
          />
        </div>

        <div className="space-y-8">
          {/* Members Section */}
          <GroupMembers group={currentGroup} isAdmin={isAdmin} />

          {/* Settings and Invite Section */}
          {user && (currentGroup.ownerId === user.id || currentGroup.members?.some(m => m.userId === user.id && m.role === 'admin')) && (
            <>
              <GroupSettings group={currentGroup} />
              <InviteMember groupId={currentGroup.id} />
            </>
          )}
        </div>
      </div>
    </div>
  );
}