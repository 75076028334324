import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../store/auth';
import { Button } from '../ui/Button';
import { Input } from '../ui/Input';
import { resendVerificationEmail, sendPasswordResetEmail } from '../../services/auth';

const loginSchema = z.object({
  email: z.string().email('Invalid email address'),
  password: z.string().min(8, 'Password must be at least 8 characters'),
});

type LoginFormData = z.infer<typeof loginSchema>;

export function LoginForm() {
  const login = useAuthStore((state) => state.login);
  const isLoading = useAuthStore((state) => state.isLoading);
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<LoginFormData>({
    resolver: zodResolver(loginSchema),
  });

  const onSubmit = async (data: LoginFormData) => {
    try {
      setError(null);
      setSuccessMessage(null);
      await login(data.email, data.password);
      navigate('/');
    } catch (error: any) {
      setError(error.message || 'Failed to sign in');
      console.error('Login failed:', error);
    }
  };

  const handleResendVerification = async () => {
    try {
      const { email, password } = getValues();
      if (!email || !password) {
        setError('Please enter your email and password to resend verification');
        return;
      }
      setError(null);
      await resendVerificationEmail(email, password);
      setSuccessMessage('Verification email sent! Please check your inbox.');
    } catch (error: any) {
      setError(error.message || 'Failed to resend verification email');
    }
  };

  const handleForgotPassword = async () => {
    try {
      const email = getValues('email');
      if (!email) {
        setError('Please enter your email address to reset password');
        return;
      }
      setError(null);
      await sendPasswordResetEmail(email);
      setSuccessMessage('Password reset email sent! Please check your inbox.');
    } catch (error: any) {
      setError(error.message || 'Failed to send password reset email');
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
      {error && (
        <div className="p-3 rounded-md bg-red-50 border border-red-200">
          <p className="text-sm text-red-600">{error}</p>
        </div>
      )}

      {successMessage && (
        <div className="p-3 rounded-md bg-green-50 border border-green-200">
          <p className="text-sm text-green-600">{successMessage}</p>
        </div>
      )}

      <Input
        {...register('email')}
        type="email"
        label="Email Address"
        error={errors.email?.message}
      />

      <Input
        {...register('password')}
        type="password"
        label="Password"
        error={errors.password?.message}
      />

      <div className="flex justify-between text-sm">
        <button
          type="button"
          onClick={handleResendVerification}
          className="text-blue-600 hover:text-blue-800"
        >
          Resend verification email
        </button>
        <button
          type="button"
          onClick={handleForgotPassword}
          className="text-blue-600 hover:text-blue-800"
        >
          Forgot password?
        </button>
      </div>

      <Button type="submit" isLoading={isLoading} className="w-full">
        Sign In
      </Button>
    </form>
  );
}