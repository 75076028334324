import React, { useState, useEffect } from 'react';
import { RoleGuard } from '../../components/auth/RoleGuard';
import { PERMISSIONS } from '../../config/roles';
import { AuditLogEntry } from '../../types/admin';
import { getAuditLogs } from '../../services/admin';
import { Clock, User, Activity, FileText, Filter } from 'lucide-react';
import { format } from 'date-fns';

export function AuditLogsPage() {
  const [logs, setLogs] = useState<AuditLogEntry[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [filters, setFilters] = useState<{
    action?: string;
    resourceType?: string;
  }>({});

  useEffect(() => {
    loadLogs();
  }, [filters]);

  const loadLogs = async () => {
    try {
      const fetchedLogs = await getAuditLogs(filters, 100);
      setLogs(fetchedLogs);
    } catch (error) {
      setError('Failed to load audit logs');
      console.error('Error loading audit logs:', error);
    } finally {
      setLoading(false);
    }
  };

  const getActionColor = (action: string) => {
    switch (action) {
      case 'create':
        return 'text-green-500';
      case 'update':
        return 'text-blue-500';
      case 'delete':
        return 'text-red-500';
      case 'moderate':
        return 'text-yellow-500';
      default:
        return 'text-gray-500';
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <RoleGuard
      requiredPermission={PERMISSIONS.VIEW_AUDIT_LOGS.id}
      fallback={
        <div className="text-center py-12">
          <h2 className="text-2xl font-semibold text-white">Access Denied</h2>
          <p className="text-gray-400 mt-2">
            You don't have permission to view audit logs.
          </p>
        </div>
      }
    >
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold text-white">Audit Logs</h1>
          <div className="flex space-x-4">
            <select
              value={filters.action || ''}
              onChange={(e) => setFilters({ ...filters, action: e.target.value || undefined })}
              className="bg-gray-800 text-white border border-gray-700 rounded-md px-3 py-2"
            >
              <option value="">All Actions</option>
              <option value="create">Create</option>
              <option value="update">Update</option>
              <option value="delete">Delete</option>
              <option value="moderate">Moderate</option>
            </select>
            <select
              value={filters.resourceType || ''}
              onChange={(e) => setFilters({ ...filters, resourceType: e.target.value || undefined })}
              className="bg-gray-800 text-white border border-gray-700 rounded-md px-3 py-2"
            >
              <option value="">All Resources</option>
              <option value="user">Users</option>
              <option value="group">Groups</option>
              <option value="stream">Streams</option>
              <option value="content">Content</option>
            </select>
          </div>
        </div>

        {error && (
          <div className="mb-4 p-4 bg-red-500/10 border border-red-500 rounded text-red-500">
            {error}
          </div>
        )}

        <div className="bg-gray-800 rounded-lg overflow-hidden">
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="bg-gray-900">
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Timestamp
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                    User
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Action
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Resource
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                    Details
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-700">
                {logs.map((log) => (
                  <tr key={log.id} className="hover:bg-gray-700/50">
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <div className="flex items-center text-gray-300">
                        <Clock className="w-4 h-4 mr-2" />
                        {format(new Date(log.timestamp), 'MMM d, yyyy HH:mm:ss')}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <div className="flex items-center text-gray-300">
                        <User className="w-4 h-4 mr-2" />
                        {log.userId}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      <span className={`inline-flex items-center ${getActionColor(log.action)}`}>
                        <Activity className="w-4 h-4 mr-2" />
                        {log.action}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                      <div className="flex items-center">
                        <FileText className="w-4 h-4 mr-2" />
                        {log.resourceType}/{log.resourceId}
                      </div>
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-400">
                      <pre className="whitespace-pre-wrap font-mono text-xs">
                        {JSON.stringify(log.details, null, 2)}
                      </pre>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </RoleGuard>
  );
}
