  import { useEffect, useState } from 'react';
import { invitationManager, type EmailInvitation } from '../lib/invitations/index';
import { useAuthStore } from '../store/auth';
import { formatDistanceToNow } from 'date-fns';
import { Button } from './ui/Button';
import { Timestamp } from 'firebase/firestore';

// Helper function to safely format dates
const formatTimestamp = (timestamp: Timestamp | Date | undefined) => {
  if (!timestamp) return 'Unknown date';
  const date = timestamp instanceof Timestamp ? timestamp.toDate() : timestamp;
  return formatDistanceToNow(date, { addSuffix: true });
};

export default function InvitationsList() {
  const { user, isAuthenticated, isLoading: authLoading } = useAuthStore();
  const [receivedInvites, setReceivedInvites] = useState<EmailInvitation[]>([]);
  const [sentInvites, setSentInvites] = useState<EmailInvitation[]>([]);
  const [activeTab, setActiveTab] = useState<'received' | 'sent'>('received');
  const [loading, setLoading] = useState(true);
  const [responding, setResponding] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const loadInvitations = async () => {
    try {
      if (!isAuthenticated || !user?.email) {
        setError('Please sign in to access invitations');
        return;
      }

      setLoading(true);
      setError(null);
      
      const [received, sent] = await Promise.all([
        invitationManager.getInvitationsByEmail(user.email),
        invitationManager.getInvitationsBySender(user.id)
      ]);
      
      setReceivedInvites(received);
      setSentInvites(sent);
    } catch (error) {
      console.error('Error loading invitations:', error);
      setError('Failed to load invitations');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // If auth hasn't initialized yet, keep loading
    if (authLoading) {
      setLoading(true);
      return;
    }

    // If not authenticated, show error
    if (!isAuthenticated || !user?.email) {
      setLoading(false);
      setError('Please sign in to access invitations');
      return;
    }

    // Load invitations
    loadInvitations();
  }, [isAuthenticated, user?.email, authLoading]);

  const handleResponse = async (invitationId: string, response: 'accept' | 'decline') => {
    try {
      setResponding(invitationId);
      setError(null);
      
      if (response === 'accept') {
        await invitationManager.acceptInvitation(invitationId);
      } else {
        await invitationManager.declineInvitation(invitationId);
      }
      
      await loadInvitations(); // Reload invitations after response
    } catch (error) {
      console.error('Error responding to invitation:', error);
      setError('Failed to respond to invitation');
    } finally {
      setResponding(null);
    }
  };

  // Show loading state while auth is initializing or invitations are loading
  if (loading || authLoading) {
    return <div className="text-center py-4">Loading invitations...</div>;
  }

  // Show error if present
  if (error) {
    return <div className="text-red-500 text-center py-4">{error}</div>;
  }

  // Show auth required message if not authenticated
  if (!isAuthenticated) {
    return <div className="text-center py-4">Please sign in to view invitations</div>;
  }

  return (
    <div className="space-y-4">
      <div className="flex space-x-4 mb-6">
        <button
          onClick={() => setActiveTab('received')}
          className={`px-4 py-2 rounded-md ${
            activeTab === 'received'
              ? 'bg-blue-600 text-white'
              : 'bg-gray-700 text-gray-300'
          }`}
        >
          Received ({receivedInvites.length})
        </button>
        <button
          onClick={() => setActiveTab('sent')}
          className={`px-4 py-2 rounded-md ${
            activeTab === 'sent'
              ? 'bg-blue-600 text-white'
              : 'bg-gray-700 text-gray-300'
          }`}
        >
          Sent ({sentInvites.length})
        </button>
      </div>

      {error && (
        <div className="bg-red-500/10 border border-red-500/20 rounded-md p-4 mb-4">
          <p className="text-red-500">{error}</p>
        </div>
      )}

      {activeTab === 'received' ? (
        receivedInvites.length === 0 ? (
          <div className="text-center py-8 text-gray-400">No received invitations</div>
        ) : (
          receivedInvites.map((invitation) => (
            <div key={invitation.id} className="p-4 bg-gray-800 rounded-lg shadow-md">
              <div className="flex justify-between items-start">
                <div className="space-y-2">
                  <h3 className="text-lg font-medium text-white">
                    <span className="text-blue-400">
                      {invitation.invitedByName || 'Someone'}
                    </span>{' '}
                    invited you to join{' '}
                    <span className="text-green-400">
                      {invitation.groupName || 'a group'}
                    </span>
                  </h3>
                  <p className="text-sm text-gray-400">
                    {formatTimestamp(invitation.createdAt)}
                  </p>
                </div>
                
                {invitation.status === 'pending' && (
                  <div className="flex space-x-2">
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => handleResponse(invitation.id, 'accept')}
                      isLoading={responding === invitation.id}
                      disabled={responding !== null}
                    >
                      Accept
                    </Button>
                    <Button
                      variant="destructive"
                      size="sm"
                      onClick={() => handleResponse(invitation.id, 'decline')}
                      isLoading={responding === invitation.id}
                      disabled={responding !== null}
                    >
                      Decline
                    </Button>
                  </div>
                )}
                {invitation.status !== 'pending' && (
                  <span className={`text-sm ${
                    invitation.status === 'accepted' ? 'text-green-400' : 'text-red-400'
                  } capitalize`}>
                    {invitation.status}
                  </span>
                )}
              </div>
            </div>
          ))
        )
      ) : (
        sentInvites.length === 0 ? (
          <div className="text-center py-8 text-gray-400">No sent invitations</div>
        ) : (
          sentInvites.map((invitation) => (
            <div key={invitation.id} className="p-4 bg-gray-800 rounded-lg shadow-md">
              <div className="flex justify-between items-start">
                <div className="space-y-2">
                  <h3 className="text-lg font-medium text-white">
                    You invited{' '}
                    <span className="text-blue-400">{invitation.invitedEmail}</span>{' '}
                    to join{' '}
                    <span className="text-green-400">
                      {invitation.groupName || 'a group'}
                    </span>
                  </h3>
                  <p className="text-sm text-gray-400">
                    {formatTimestamp(invitation.createdAt)}
                  </p>
                  {invitation.status !== 'pending' && (
                    <p className="text-sm text-gray-400">
                      Status: <span className="capitalize">{invitation.status}</span>
                    </p>
                  )}
                </div>
              </div>
            </div>
          ))
        )
      )}
    </div>
  );
}
