import React, { useEffect, useRef, useState } from 'react';
import { Send } from 'lucide-react';
import { useChatStore } from '../../store/chat';
import { useAuthStore } from '../../store/auth';
import { Button } from '../ui/Button';

interface ChatPanelProps {
  streamId: string;
}

export function ChatPanel({ streamId }: ChatPanelProps) {
  const [message, setMessage] = useState('');
  const { messages, sendMessage, fetchMessages } = useChatStore();
  const { isAuthenticated } = useAuthStore();
  const chatContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fetchMessages(streamId);
  }, [streamId, fetchMessages]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!message.trim() || !isAuthenticated) return;

    await sendMessage(streamId, message);
    setMessage('');
  };

  return (
    <div className="flex flex-col h-full bg-white rounded-lg shadow-sm">
      <div className="p-4 border-b">
        <h3 className="font-semibold">Live Chat</h3>
      </div>

      <div
        ref={chatContainerRef}
        className="flex-1 overflow-y-auto p-4 space-y-4"
      >
        {messages.map((msg) => (
          <div key={msg.id} className="flex flex-col">
            <div className="flex items-center space-x-2">
              <span className="font-medium text-sm text-blue-600">
                {msg.userName}
              </span>
              <span className="text-xs text-gray-500">
                {new Date(msg.timestamp).toLocaleTimeString()}
              </span>
            </div>
            <p className="text-sm text-gray-800">{msg.content}</p>
          </div>
        ))}
      </div>

      {isAuthenticated ? (
        <form onSubmit={handleSubmit} className="p-4 border-t">
          <div className="flex space-x-2">
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type your message..."
              className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
            <Button type="submit" size="sm">
              <Send className="h-4 w-4" />
            </Button>
          </div>
        </form>
      ) : (
        <div className="p-4 border-t text-center text-sm text-gray-600">
          Please sign in to participate in chat
        </div>
      )}
    </div>
  );
}