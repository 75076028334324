import { create } from 'zustand';
import { 
  collection, 
  doc, 
  getDoc, 
  getDocs, 
  query, 
  where, 
  serverTimestamp, 
  updateDoc,
  Timestamp,
  runTransaction
} from 'firebase/firestore';
import { auth } from '../lib/firebase';
import type { StreamState, Stream, StreamKey } from '../types/stream';
import { useAuthStore } from './auth';
import { generateSecureToken } from '../utils/security';
import { db } from '../lib/firebase';

const RTMP_SERVER_URL = import.meta.env.VITE_RTMP_SERVER_URL || 'rtmp://stream.sviz.live/live';

const convertTimestampToString = (timestamp: Timestamp | { seconds: number, nanoseconds: number } | string | null | undefined) => {
  if (!timestamp) return new Date().toISOString();
  if (typeof timestamp === 'string') return timestamp;
  if ('toDate' in timestamp) return timestamp.toDate().toISOString();
  if ('seconds' in timestamp) return new Date(timestamp.seconds * 1000).toISOString();
  return new Date().toISOString();
};

const convertToStream = (id: string, data: any): Stream => ({
  id,
  title: data.title || '',
  description: data.description || '',
  thumbnailUrl: data.thumbnailUrl || '',
  creatorId: data.creatorId || '',
  creatorName: data.creatorName || '',
  isLive: data.isLive || false,
  streamStatus: data.streamStatus || 'created',
  visibility: data.visibility || 'public',
  allowedGroups: data.allowedGroups || [],
  viewerCount: data.viewerCount || 0,
  createdAt: convertTimestampToString(data.createdAt),
  updatedAt: convertTimestampToString(data.updatedAt),
  startedAt: data.startedAt ? convertTimestampToString(data.startedAt) : undefined,
  endedAt: data.endedAt ? convertTimestampToString(data.endedAt) : undefined,
  streamKey: data.streamKey ? {
    key: data.streamKey,
    token: data.streamToken,
    expiresAt: data.keyExpiresAt,
  } : undefined,
  rtmpUrl: data.rtmpUrl,
  playbackUrl: data.playbackUrl,
});

export const useStreamStore = create<StreamState>((set, get) => ({
  streams: [],
  currentStream: null,
  isLoading: false,
  error: null,

  createStream: async (streamData: Partial<Stream>) => {
    set({ isLoading: true, error: null });
    try {
      const firebaseUser = auth.currentUser;
      if (!firebaseUser) throw new Error('User not authenticated');

      const userDoc = await getDoc(doc(db, 'users', firebaseUser.uid));
      if (!userDoc.exists()) throw new Error('User profile not found');
      
      const userData = userDoc.data();
      const now = serverTimestamp();

      // Generate stream key and token
      const streamKey = generateSecureToken(16);
      const streamToken = generateSecureToken(32);
      const keyExpiresAt = new Date(Date.now() + 24 * 60 * 60 * 1000); // 24 hours from now

      // Create stream document in a transaction to ensure consistency
      const newStream = await runTransaction(db, async (transaction) => {
        // Create stream document
        const streamRef = doc(collection(db, 'streams'));
        const newStreamData = {
          title: streamData.title || '',
          description: streamData.description || '',
          thumbnailUrl: streamData.thumbnailUrl || '',
          creatorId: firebaseUser.uid,
          creatorName: userData.name || firebaseUser.displayName || '',
          isLive: false,
          streamStatus: 'created' as const,
          visibility: streamData.visibility || 'public',
          allowedGroups: streamData.allowedGroups || [],
          viewerCount: 0,
          createdAt: now,
          updatedAt: now,
          streamKey,
          streamToken,
          keyExpiresAt: keyExpiresAt.toISOString(),
        };
        
        transaction.set(streamRef, newStreamData);

        // Create user's stream document
        const userStreamRef = doc(db, 'users', firebaseUser.uid, 'streams', streamRef.id);
        transaction.set(userStreamRef, {
          streamId: streamRef.id,
          createdAt: now,
        });

        // Return the stream with current timestamp since server timestamp isn't resolved yet
        return convertToStream(streamRef.id, {
          ...newStreamData,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        });
      });

      // Set the stream in state
      set((state) => ({ 
        streams: [...state.streams, newStream],
        currentStream: newStream,
        error: null 
      }));
      
      // Wait a bit for the transaction to be fully committed
      await new Promise(resolve => setTimeout(resolve, 500));
      
      return newStream;
    } catch (error) {
      const errorMsg = error instanceof Error ? error.message : 'Failed to create stream';
      set({ error: errorMsg });
      throw new Error(errorMsg);
    } finally {
      set({ isLoading: false });
    }
  },

  setupStream: async (streamId: string): Promise<Stream> => {
    set({ isLoading: true, error: null });
    try {
      const firebaseUser = auth.currentUser;
      if (!firebaseUser) throw new Error('User not authenticated');

      const streamRef = doc(db, 'streams', streamId);
      const streamDoc = await getDoc(streamRef);
      
      if (!streamDoc.exists()) throw new Error('Stream not found');
      const stream = convertToStream(streamDoc.id, streamDoc.data());
      
      if (stream.creatorId !== firebaseUser.uid) throw new Error('Not authorized to setup this stream');
      if (stream.streamStatus !== 'created') throw new Error('Stream is not in created state');

      // Generate stream key and RTMP URL
      const streamKey = await get().generateStreamKey(streamId);
      const rtmpUrl = `${RTMP_SERVER_URL}/${streamKey.key}?token=${streamKey.token}`;

      const now = serverTimestamp();

      const updateData = {
        streamKey: streamKey.key,
        streamToken: streamKey.token,
        keyExpiresAt: streamKey.expiresAt,
        rtmpUrl,
        streamStatus: 'setup' as const,
        updatedAt: now,
      };

      // Update stream document with streaming details
      await updateDoc(streamRef, updateData);

      // Re-fetch to get the updated document with new timestamps
      const updatedDoc = await getDoc(streamRef);
      if (!updatedDoc.exists()) throw new Error('Stream not found after update');
      
      const updatedStream = convertToStream(updatedDoc.id, {
        ...updatedDoc.data(),
        streamKey: {
          key: updatedDoc.data().streamKey,
          token: updatedDoc.data().streamToken,
          expiresAt: updatedDoc.data().keyExpiresAt,
        },
        rtmpUrl,
      });

      set((state) => ({
        streams: state.streams.map(s => s.id === streamId ? updatedStream : s),
        currentStream: state.currentStream?.id === streamId ? updatedStream : state.currentStream,
        error: null
      }));

      return updatedStream;
    } catch (error) {
      const errorMsg = error instanceof Error ? error.message : 'Failed to setup stream';
      set({ error: errorMsg });
      throw new Error(errorMsg);
    } finally {
      set({ isLoading: false });
    }
  },

  startStream: async (streamId: string): Promise<Stream> => {
    set({ isLoading: true, error: null });
    try {
      const firebaseUser = auth.currentUser;
      if (!firebaseUser) throw new Error('User not authenticated');

      const streamRef = doc(db, 'streams', streamId);
      const streamDoc = await getDoc(streamRef);
      
      if (!streamDoc.exists()) throw new Error('Stream not found');
      const stream = convertToStream(streamDoc.id, streamDoc.data());
      
      if (stream.creatorId !== firebaseUser.uid) throw new Error('Not authorized to start this stream');
      if (stream.streamStatus !== 'setup') throw new Error('Stream is not ready to start');
      if (!stream.streamKey) throw new Error('Stream key not generated');

      const now = serverTimestamp();

      const updateData = {
        isLive: true,
        streamStatus: 'live' as const,
        startedAt: now,
        updatedAt: now,
      };

      // Update stream document
      await updateDoc(streamRef, updateData);

      // Re-fetch to get the updated document with new timestamps
      const updatedDoc = await getDoc(streamRef);
      if (!updatedDoc.exists()) throw new Error('Stream not found after update');
      
      const updatedStream = convertToStream(updatedDoc.id, updatedDoc.data());

      set((state) => ({
        streams: state.streams.map(s => s.id === streamId ? updatedStream : s),
        currentStream: state.currentStream?.id === streamId ? updatedStream : state.currentStream,
        error: null
      }));

      return updatedStream;
    } catch (error) {
      const errorMsg = error instanceof Error ? error.message : 'Failed to start stream';
      set({ error: errorMsg });
      throw new Error(errorMsg);
    } finally {
      set({ isLoading: false });
    }
  },

  endStream: async (streamId: string): Promise<Stream> => {
    set({ isLoading: true, error: null });
    try {
      const firebaseUser = auth.currentUser;
      if (!firebaseUser) throw new Error('User not authenticated');

      const streamRef = doc(db, 'streams', streamId);
      const streamDoc = await getDoc(streamRef);
      
      if (!streamDoc.exists()) throw new Error('Stream not found');
      const stream = convertToStream(streamDoc.id, streamDoc.data());
      
      if (stream.creatorId !== firebaseUser.uid) throw new Error('Not authorized to end this stream');
      if (stream.streamStatus !== 'live') throw new Error('Stream is not live');

      const now = serverTimestamp();

      const updateData = {
        isLive: false,
        streamStatus: 'ended' as const,
        endedAt: now,
        updatedAt: now,
        streamKey: null,
        streamToken: null,
        keyExpiresAt: null,
        rtmpUrl: null
      };

      // Update stream document
      await updateDoc(streamRef, updateData);

      // Re-fetch to get the updated document with new timestamps
      const updatedDoc = await getDoc(streamRef);
      if (!updatedDoc.exists()) throw new Error('Stream not found after update');
      
      const updatedStream = convertToStream(updatedDoc.id, {
        ...updatedDoc.data(),
        streamKey: undefined,
        rtmpUrl: undefined
      });

      set((state) => ({
        streams: state.streams.map(s => s.id === streamId ? updatedStream : s),
        currentStream: state.currentStream?.id === streamId ? updatedStream : state.currentStream,
        error: null
      }));

      return updatedStream;
    } catch (error) {
      const errorMsg = error instanceof Error ? error.message : 'Failed to end stream';
      set({ error: errorMsg });
      throw new Error(errorMsg);
    } finally {
      set({ isLoading: false });
    }
  },

  joinStream: async (streamId: string): Promise<void> => {
    set({ isLoading: true, error: null });
    try {
      // Add retries for joining stream
      let retries = 3;
      let streamDoc = null;
      
      while (retries > 0) {
        const streamRef = doc(db, 'streams', streamId);
        streamDoc = await getDoc(streamRef);
        
        if (streamDoc.exists()) break;
        
        retries--;
        if (retries > 0) await new Promise(resolve => setTimeout(resolve, 500));
      }

      if (!streamDoc || !streamDoc.exists()) {
        set({ error: 'Stream not found' });
        throw new Error('Stream not found');
      }

      const streamDocData = streamDoc.data();
      const stream = convertToStream(streamDoc.id, streamDocData);

      // Update viewer count
      await updateDoc(doc(db, 'streams', streamId), {
        viewerCount: (streamDocData.viewerCount || 0) + 1
      });
      
      set({ currentStream: stream });
    } catch (error) {
      const errorMsg = error instanceof Error ? error.message : 'Failed to join stream';
      set({ error: errorMsg });
      throw new Error(errorMsg);
    } finally {
      set({ isLoading: false });
    }
  },

  leaveStream: async (): Promise<void> => {
    set({ currentStream: null });
  },

  fetchUserStreams: async (userId: string) => {
    set({ isLoading: true });
    try {
      const streamsQuery = query(
        collection(db, 'streams'),
        where('creatorId', '==', userId)
      );
      
      const snapshot = await getDocs(streamsQuery);
      const streams = snapshot.docs.map(doc => {
        const data = doc.data();
        // Handle server timestamps that haven't been resolved yet
        if (data.createdAt === null) data.createdAt = new Date().toISOString();
        if (data.updatedAt === null) data.updatedAt = new Date().toISOString();
        return convertToStream(doc.id, data);
      }) as Stream[];

      set({ streams });
    } catch (error) {
      set({ error: 'Failed to fetch user streams' });
      throw error;
    } finally {
      set({ isLoading: false });
    }
  },

  fetchStreams: async () => {
    set({ isLoading: true });
    try {
      const user = auth.currentUser;
      const userDoc = user ? await getDoc(doc(db, 'users', user.uid)) : null;
      const userGroups = userDoc?.data()?.groups || [];

      // Create queries for different stream types
      const publicStreamsQuery = query(
        collection(db, 'streams'),
        where('visibility', '==', 'public')
      );
      
      const groupStreamsQuery = userGroups.length > 0 ? query(
        collection(db, 'streams'),
        where('visibility', '==', 'group'),
        where('allowedGroups', 'array-contains-any', userGroups)
      ) : null;
      
      const privateStreamsQuery = user ? query(
        collection(db, 'streams'),
        where('visibility', '==', 'private'),
        where('creatorId', '==', user.uid)
      ) : null;

      // Execute queries
      const [publicStreams, groupStreams, privateStreams] = await Promise.all([
        getDocs(publicStreamsQuery),
        groupStreamsQuery ? getDocs(groupStreamsQuery) : Promise.resolve(null),
        privateStreamsQuery ? getDocs(privateStreamsQuery) : Promise.resolve(null)
      ]);

      // Combine and process results
      const allStreams = new Map();
      
      const processSnapshot = (snapshot: any) => {
        if (!snapshot) return;
        snapshot.docs.forEach((doc: any) => {
          const data = doc.data();
          // Handle server timestamps that haven't been resolved yet
          if (data.createdAt === null) data.createdAt = new Date().toISOString();
          if (data.updatedAt === null) data.updatedAt = new Date().toISOString();
          if (data.startedAt === null) data.startedAt = new Date().toISOString();
          if (data.endedAt === null) data.endedAt = undefined;
          allStreams.set(doc.id, convertToStream(doc.id, data));
        });
      };

      processSnapshot(publicStreams);
      processSnapshot(groupStreams);
      processSnapshot(privateStreams);

      set({ streams: Array.from(allStreams.values()) });
    } catch (error) {
      set({ error: 'Failed to fetch streams' });
      console.error('Error fetching streams:', error);
    } finally {
      set({ isLoading: false });
    }
  },

  generateStreamKey: async (streamId: string): Promise<StreamKey> => {
    try {
      const streamKey = generateSecureToken(16);
      const streamToken = generateSecureToken(32);
      const expiresAt = new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString(); // 24 hours from now
      return { key: streamKey, token: streamToken, expiresAt };
    } catch (error) {
      set(() => ({ error: 'Failed to generate stream key' }));
      throw error;
    }
  },
}));