import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateGroupForm } from '../components/group/CreateGroupForm';

export function CreateGroupPage() {
  const navigate = useNavigate();

  const handleSuccess = () => {
    navigate('/groups');
  };

  return (
    <div className="max-w-2xl mx-auto">
      <div className="mb-8">
        <h1 className="text-3xl font-light tracking-wide text-white mb-2">Create New Group</h1>
        <p className="text-gray-400 font-light">
          Create a group to collaborate with other medical professionals
        </p>
      </div>

      <div className="bg-gradient-to-br from-gray-800/50 to-gray-900/50 rounded-xl border border-gray-700/50 backdrop-blur-sm p-6">
        <CreateGroupForm onSuccess={handleSuccess} />
      </div>
    </div>
  );
}