import type { Stream } from '../../types/stream';
import { Card } from '../ui/Card';
import { Button } from '../ui/Button';
import { Eye, Clock, Calendar, Play, Settings } from 'lucide-react';

interface StreamHistoryProps {
  streams: Stream[];
  detailed?: boolean;
}

export function StreamHistory({ streams, detailed = false }: StreamHistoryProps) {
  const formatDuration = (startDate: string, endDate: string) => {
    const duration = new Date(endDate).getTime() - new Date(startDate).getTime();
    const hours = Math.floor(duration / (1000 * 60 * 60));
    const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
    return `${hours}h ${minutes}m`;
  };

  const formatDate = (date: string) => {
    return new Date(date).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <h3 className="text-xl font-semibold text-white">Stream History</h3>
        {!detailed && streams.length > 5 && (
          <Button variant="secondary" size="sm">
            View All
          </Button>
        )}
      </div>

      <div className="space-y-4">
        {streams.length === 0 ? (
          <Card className="p-6 text-center bg-gray-800/50">
            <p className="text-gray-400">No streams yet</p>
          </Card>
        ) : (
          streams.map((stream) => (
            <Card key={stream.id} className="p-4 bg-gray-800/50 hover:bg-gray-800/70 transition-all">
              <div className="flex items-start justify-between">
                <div className="flex-1">
                  <h4 className="font-medium text-white">{stream.title}</h4>
                  {detailed && (
                    <p className="mt-1 text-sm text-gray-400">{stream.description}</p>
                  )}
                  
                  <div className="mt-2 flex flex-wrap gap-3 text-sm text-gray-400">
                    <div className="flex items-center">
                      <Calendar className="mr-1 h-4 w-4" />
                      {formatDate(stream.createdAt)}
                    </div>
                    {stream.startedAt && stream.endedAt && (
                      <div className="flex items-center">
                        <Clock className="mr-1 h-4 w-4" />
                        {formatDuration(stream.startedAt, stream.endedAt)}
                      </div>
                    )}
                    <div className="flex items-center">
                      <Eye className="mr-1 h-4 w-4" />
                      {stream.viewerCount || 0} viewers
                    </div>
                  </div>
                </div>

                <div className="flex space-x-2">
                  {stream.playbackUrl && (
                    <Button size="sm" variant="outline">
                      <Play className="h-4 w-4" />
                    </Button>
                  )}
                  {detailed && (
                    <Button size="sm" variant="outline">
                      <Settings className="h-4 w-4" />
                    </Button>
                  )}
                </div>
              </div>

              {detailed && stream.thumbnailUrl && (
                <div className="mt-4">
                  <img
                    src={stream.thumbnailUrl}
                    alt={stream.title}
                    className="w-full h-48 object-cover rounded-lg"
                  />
                </div>
              )}
            </Card>
          ))
        )}
      </div>
    </div>
  );
}
