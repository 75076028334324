import React, { useMemo } from 'react';
import { Check, X } from 'lucide-react';

interface PasswordRequirement {
  label: string;
  regex: RegExp;
}

const requirements: PasswordRequirement[] = [
  { label: 'At least 8 characters', regex: /.{8,}/ },
  { label: 'Contains uppercase letter', regex: /[A-Z]/ },
  { label: 'Contains lowercase letter', regex: /[a-z]/ },
  { label: 'Contains number', regex: /[0-9]/ },
  { label: 'Contains special character', regex: /[^A-Za-z0-9]/ },
];

interface Props {
  password: string;
}

export function PasswordStrengthMeter({ password }: Props) {
  const strength = useMemo(() => {
    return requirements.reduce((score, req) => {
      return score + (req.regex.test(password) ? 1 : 0);
    }, 0);
  }, [password]);

  const strengthColor = useMemo(() => {
    if (strength <= 2) return 'bg-red-500';
    if (strength <= 3) return 'bg-yellow-500';
    if (strength <= 4) return 'bg-blue-500';
    return 'bg-green-500';
  }, [strength]);

  return (
    <div className="space-y-3">
      <div className="h-2 w-full bg-gray-700 rounded-full overflow-hidden">
        <div 
          className={`h-full transition-all duration-300 ${strengthColor}`}
          style={{ width: `${(strength / requirements.length) * 100}%` }}
        />
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
        {requirements.map((req, index) => (
          <div 
            key={index}
            className="flex items-center space-x-2 text-sm"
          >
            {req.regex.test(password) ? (
              <Check className="h-4 w-4 text-green-400" />
            ) : (
              <X className="h-4 w-4 text-gray-400" />
            )}
            <span className={req.regex.test(password) ? 'text-green-400' : 'text-gray-400'}>
              {req.label}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}