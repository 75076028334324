import { useEffect } from 'react';
import { collection, query, where, orderBy, onSnapshot, getDocs } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuthStore } from '../store/auth';
import { useNotificationStore } from '../store/notification';
import { logger } from '../lib/utils/logger';

export function useNotifications() {
  const { user } = useAuthStore();
  const { addNotification, clearAll } = useNotificationStore();

  useEffect(() => {
    if (!user?.id) return;

    logger.info('Setting up notifications listener for user:', user.id);

    // Clear existing notifications when user changes
    clearAll();

    // Query notifications for the current user
    const notificationsRef = collection(db, 'notifications');
    const q = query(
      notificationsRef,
      where('userId', '==', user.id),
      orderBy('createdAt', 'desc')
    );

    // First, fetch existing notifications
    getDocs(q).then((snapshot) => {
      snapshot.docs.forEach((doc) => {
        const notification = doc.data();
        addNotification({
          id: doc.id, // Include the document ID
          type: notification.type,
          title: notification.title,
          message: notification.message,
          read: notification.read || false,
          createdAt: notification.createdAt?.toDate() || new Date(),
          data: notification.data,
        });
      });
    }).catch((error) => {
      logger.error('Error fetching existing notifications:', error);
    });

    // Then, set up real-time listener for new notifications
    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const notification = change.doc.data();
          if (change.type === 'added') {
            // Only add if it's a new notification (not from initial load)
            if (change.doc.metadata.hasPendingWrites) {
              addNotification({
                id: change.doc.id,
                type: notification.type,
                title: notification.title,
                message: notification.message,
                read: notification.read || false,
                createdAt: notification.createdAt?.toDate() || new Date(),
                data: notification.data,
              });
            }
          }
        });
      },
      (error) => {
        logger.error('Error in notifications subscription:', error);
      }
    );

    return () => {
      unsubscribe();
      clearAll();
    };
  }, [user?.id, addNotification, clearAll]);
}
