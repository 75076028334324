import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Users, Eye, Globe, Lock, MessageSquare, StopCircle } from 'lucide-react';
import { useStreamStore } from '../store/stream';
import { useAuthStore } from '../store/auth';
import { ChatPanel } from '../components/chat/ChatPanel';
import { Button } from '../components/ui/Button';

export function StreamPage() {
  const { streamId } = useParams<{ streamId: string }>();
  const { currentStream, joinStream, leaveStream, endStream, isLoading } = useStreamStore();
  const { user } = useAuthStore();

  useEffect(() => {
    if (streamId) {
      joinStream(streamId);
    }
    return () => {
      if (streamId) {
        leaveStream(streamId);
      }
    };
  }, [streamId, joinStream, leaveStream]);

  const handleEndStream = async () => {
    if (streamId) {
      try {
        await endStream(streamId);
      } catch (error) {
        console.error('Failed to end stream:', error);
      }
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (!currentStream) {
    return (
      <div className="text-center py-12">
        <h2 className="text-2xl font-light text-white">Stream not found</h2>
        <p className="text-gray-400 mt-2">The stream you're looking for doesn't exist or has ended.</p>
      </div>
    );
  }

  const isCreator = user?.id === currentStream.creatorId;

  return (
    <div className="max-w-7xl mx-auto">
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
        <div className="lg:col-span-3 space-y-6">
          {/* Stream Player */}
          <div className="relative aspect-video bg-gradient-to-br from-gray-900 to-gray-800 rounded-xl overflow-hidden border border-gray-700/50">
            {currentStream.playbackUrl ? (
              <video
                className="w-full h-full object-cover"
                src={currentStream.playbackUrl}
                controls
                autoPlay
              />
            ) : (
              <div className="absolute inset-0 flex items-center justify-center">
                <p className="text-gray-400 font-light">Stream starting soon...</p>
              </div>
            )}
            <div className="absolute top-4 left-4 flex items-center space-x-2">
              <span className="px-2 py-1 text-xs font-medium rounded-full bg-red-500/90 text-white">
                LIVE
              </span>
              <span className="px-2 py-1 text-xs font-medium rounded-full bg-gray-800/90 text-white backdrop-blur-sm">
                {currentStream.viewerCount} viewers
              </span>
            </div>
          </div>

          {/* Stream Info */}
          <div className="bg-gradient-to-br from-gray-800/50 to-gray-900/50 rounded-xl border border-gray-700/50 backdrop-blur-sm p-6">
            <div className="flex items-center justify-between mb-4">
              <h1 className="text-2xl font-light tracking-wide text-white">{currentStream.title}</h1>
              <div className="flex items-center space-x-4">
                <div className="flex items-center space-x-2 text-gray-400">
                  <Eye className="h-5 w-5" />
                  <span className="font-light">{currentStream.viewerCount}</span>
                </div>
                <div className="flex items-center space-x-2 text-gray-400">
                  {currentStream.visibility === 'private' ? (
                    <Lock className="h-5 w-5" />
                  ) : currentStream.visibility === 'group' ? (
                    <Users className="h-5 w-5" />
                  ) : (
                    <Globe className="h-5 w-5" />
                  )}
                  <span className="font-light capitalize">{currentStream.visibility}</span>
                </div>
                {isCreator && currentStream.isLive && (
                  <Button
                    onClick={handleEndStream}
                    variant="destructive"
                    className="flex items-center space-x-2"
                  >
                    <StopCircle className="h-5 w-5" />
                    <span>End Stream</span>
                  </Button>
                )}
              </div>
            </div>

            <div className="border-t border-gray-700/50 pt-4">
              <h2 className="text-lg font-light text-white/90 mb-2">About this stream</h2>
              <p className="text-gray-400 font-light">{currentStream.description}</p>
            </div>

            <div className="border-t border-gray-700/50 mt-4 pt-4">
              <div className="flex items-center space-x-3">
                <div className="h-10 w-10 rounded-full bg-gradient-to-br from-blue-500/20 to-purple-500/20 border border-gray-700/50 flex items-center justify-center">
                  <span className="text-white/80">{currentStream.creatorName.charAt(0)}</span>
                </div>
                <div>
                  <p className="font-light text-white/90">{currentStream.creatorName}</p>
                  <p className="text-sm text-gray-400">Streamer</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Chat Panel */}
        <div className="lg:col-span-1">
          <div className="bg-gradient-to-br from-gray-800/30 to-gray-900/30 rounded-xl border border-gray-700/50 backdrop-blur-sm h-[calc(100vh-2rem)] overflow-hidden">
            <div className="flex items-center space-x-2 p-4 border-b border-gray-700/50">
              <MessageSquare className="h-5 w-5 text-gray-400" />
              <h2 className="text-lg font-light text-white/90">Live Chat</h2>
            </div>
            <ChatPanel streamId={currentStream.id} />
          </div>
        </div>
      </div>
    </div>
  );
}